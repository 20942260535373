.jGrowl {
  z-index: 9999;
  color: #000;
  position: fixed;

}
.jGrowl.top-left {
  left: 0px;
  top: 0px;
}
.jGrowl.top-right {
  right: 0px;
  top: 0px;
}
.jGrowl.bottom-left {
  left: 0px;
  bottom: 0px;
}
.jGrowl.bottom-right {
  right: 0px;
  bottom: 0px;
}
.jGrowl.center {
  top: 0px;
  width: 50%;
  left: 25%;
}
.jGrowl.center .jGrowl-notification,
.jGrowl.center .jGrowl-closer {
  margin-left: auto;
  margin-right: auto;
}
.jGrowl-notification {
  background-color: #fff;
  opacity: 0.9;
  zoom: 1;
  width: 250px;
  padding: 10px;
  margin: 10px;
  text-align: left;
  display: none;
  color: #000;
  min-height: 40px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.16),
            0 2px 5px 0 rgba(0,0,0,0.26);

}

.jGrowl-message {
    hr {
        margin: 2em 0;
    }

    .center-text {
        margin-top: 1em;
    }
}
.jGrowl-notification .ui-state-highlight,
.jGrowl-notification .ui-widget-content .ui-state-highlight,
.jGrowl-notification .ui-widget-header .ui-state-highlight {
  border: 1px solid #fff;
  background: #fff;
  color: #000;
}
.jGrowl-notification .jGrowl-header {
  margin-top: 0.5em;
  font-size: 1.2em;
}
.jGrowl-notification .jGrowl-close {
  background-color: transparent;
  color: #000 !important;
  border: none;
  z-index: 99;
  float: right;
  font-size: 2em;
  cursor: pointer;
}
.jGrowl-closer {
  background-color: #fff;
  opacity: 0.9;
  zoom: 1;
  width: 250px;
  padding: 10px;
  margin: 10px;
  text-align: left;
  display: none;

  padding-top: 4px;
  padding-bottom: 4px;
  cursor: pointer;
  font-size: .9em;
  text-align: center;
}
.jGrowl-closer .ui-state-highlight,
.jGrowl-closer .ui-widget-content .ui-state-highlight,
.jGrowl-closer .ui-widget-header .ui-state-highlight {
  border: 1px solid #fff;
  background: #fff;
  color: #000;
}
/** Hide jGrowl when printing **/
@media print {
  .jGrowl {
    display: none;
  }
}