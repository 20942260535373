//Prop 65

.prop65-link {
    display: block;
    font-weight: bold;
    color: red;
    cursor: pointer;

    &:hover {
        color: darkred;
    }

    img {
        max-width: 20px;
        width: 100%;
        height: auto;
        vertical-align: top;
    }
}

.prop65-modal {
    width: 90% !important;
    max-width: 700px !important;



    .modal-body {
        flex-wrap: wrap;
        padding: 1em 4em 1em 4em;
        height: auto !important;
        flex-direction: row;
        font-size: 14px;

        @include media($mobile) {
            padding: 1em 2em;
        }

        .warning-header {
            width: 100%;
            text-align: center;
            font-size: 2em;
            font-weight: bold;
            margin-bottom: 1em;

            img {
                max-width: 40px;
            }
        }

        p {
            width: 100%;
        }

        .warning-optin {
            width: 100%;
            text-align: center;
        }

        .warning-buttons {
            width: 100%;
            padding: 1em;
            text-align: center;

            button {
                font-size: 1em;
                color: #FFF;
                background-color: $brand-color-alpha;
                padding: 1em;
                margin: 0;
                transition: all .2s ease-out;
                border: 0;
                text-transform: uppercase;
                font-weight: 600;

            }
        }
    }
}

#prop65-confirm-modal {
    width: 99% !important;
    position: absolute !important;

    @include media($mobile){
        top: 2px !important;
    }

    .modal-header {
        @include media($mobile){
            padding: 0 !important;
        }
    }

    .modal-body {
        @include media($mobile){
            padding: 1em !important;
        }
    }

    .affected-items {
        text-align: center;
        font-weight: bold;
    }

    .warning-text {
        padding: 1em;
        margin: 1em 0;
        background-color: #f8f8f8;
    }

    .warning-items {
        width: 100%;
        padding: 1em 0;
        margin: 0 0 2em 0;
        max-height: 150px;
        overflow-y: auto;

        p {
            text-align: center;
        }

        .warning-item {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content:space-around;

            &:nth-of-type(even){
                background-color: #f8f8f8;
            }

        }
    }



    .warning-buttons {
        display: flex;
        justify-content:space-between;
        padding: 2em 0 1em 0;

        #prop65-return-to-cart {
            background-color: #ffffff;
            color: $brand-color-alpha;
        }
    }
}