.image-carousel {
    @include span-columns(11 of 12);
    @include omega;
    @include media($tablet) {
      display: none;
    }
    @include media($mobile) {
      display: none;
    }
    @include media($mobile) {
      display: none;
    }
}

.jcarousel-skin-tango .jcarousel-container {
    position: fixed;
    top: 190px;
    left: 55px;
}

.jcarousel-skin-tango .jcarousel-direction-rtl {
	direction: rtl;
}

.jcarousel-skin-tango .jcarousel-container-horizontal {
    width: 910px;
    padding: 0;
}

.jcarousel-skin-tango .jcarousel-clip {
    overflow: hidden;
}

.jcarousel-skin-tango .jcarousel-clip-horizontal {
    width:  838px;
    height: 135px;
}

.jcarousel-skin-tango .jcarousel-item {
    width: 276px;
    height: 135px;
}

.jcarousel-skin-tango .jcarousel-item-horizontal {
	margin-left: 0;
    margin-right: 5px;
}

.jcarousel-skin-tango .jcarousel-direction-rtl .jcarousel-item-horizontal {
	margin-left: 5px;
    margin-right: 0;
}

.jcarousel-skin-tango .jcarousel-item-placeholder {
    background: #fff;
    color: #000;
}

/**
 *  Horizontal Buttons
 */
.jcarousel-skin-tango .jcarousel-next-horizontal {
    position: absolute;
    top: -5px;
    right: 36px;
    width: 35px;
    height: 145px;
    cursor: pointer;
    background: transparent url(#{$image-url-path}/jcarousel-arrows.png) no-repeat -106px 0;
}

.jcarousel-skin-tango .jcarousel-direction-rtl .jcarousel-next-horizontal {
    left: 5px;
    right: auto;
    background-image: url(#{$image-url-path}/jcarousel-arrows.png);
}

.jcarousel-skin-tango .jcarousel-next-horizontal:hover,
.jcarousel-skin-tango .jcarousel-next-horizontal:focus {
    background-position: -70px 0;
}

.jcarousel-skin-tango .jcarousel-next-horizontal:active {
    background-position: -70px 0;
}

.jcarousel-skin-tango .jcarousel-prev-horizontal {
    position: absolute;
    top: -5px;
    left: -35px;
    width: 35px;
    height: 145px;
    cursor: pointer;
    background: transparent url(#{$image-url-path}/jcarousel-arrows.png) no-repeat 0 0;
}

.jcarousel-skin-tango .jcarousel-direction-rtl .jcarousel-prev-horizontal {
    left: auto;
    right: 5px;
    background-image: url(#{$image-url-path}/jcarousel-arrows.png);
}

.jcarousel-skin-tango .jcarousel-prev-horizontal:hover,
.jcarousel-skin-tango .jcarousel-prev-horizontal:focus {
    background-position: -36px 0;
}

.jcarousel-skin-tango .jcarousel-prev-horizontal:active {
    background-position: -36px 0;
}