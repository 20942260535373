﻿header {

top: 0;
width: 100%;
margin-bottom: 1em;

z-index: 5;



@include media($mobile){
    #search {
        width: 100% !important;
        margin: 0;
    }
}



  .main-nav-wrapper {
      position: relative;
      width: 100%;


      @include media($mobile){
          height: 80px;
      }

  .top-nav {
    background-color: $brand-color-alpha;
    color: $white;
    height: auto;
    padding: 0.5em 1.5em;

    @include media($mobile) {


      height: 80px;
      padding: 0.5em;
    }
    @include media($tablet) {


     padding: 1em 1.5em;
    }



    .main-nav {
      position: relative;
      margin: 0 auto;
      vertical-align: middle;
      display: flex;
      flex-wrap: wrap;

      @include media($tablet){
          text-align: center;
      }

      @include media($mobile){
          text-align: center;
      }

      width: 100%;
      max-width: 1200px;

      #mobile-nav {
        display: none;
        @include media($mobile) {

          z-index: 10;
          color: #fff;
          display: table-cell;
          position: absolute;
          line-height: 40px;
          vertical-align: middle;
          left: 20px;
          top: 10px;
          cursor: pointer;
          .hamburger {
            display: inline-block;
            list-style: outside none none;
            padding: 0;
            position: relative;
            transition: all 233ms cubic-bezier(.5,0,0,1) 0;
            width: 0.75em;
            li {
              background: #FFF;

              float: left;
              height: 3px;
              margin: 3px 0 0;
              padding: 0;
              transform: translate3d(0px,0px,0px);
              transition: all 333ms cubic-bezier(0.5,0,0,1) 0;
              width: 1.5em;
              &.checked li {
                &.first {
                  transform: rotate(-135deg) translateY(-4.5px);
                }
                &.second {
                  opacity: 0;
                }
                &.third {
                  transform: rotate(135deg) translateY(4.5px);
                }
              }
            }
          }
        }
        @include media($tablet) {

          z-index: 10;
          color: #fff;
          display: table-cell;
          position: absolute;
          line-height: 40px;
          vertical-align: middle;
          left: 20px;
          top: 10px;
          cursor: pointer;
          .hamburger {
            display: inline-block;
            list-style: outside none none;
            padding: 0;
            position: relative;
            transition: all 233ms cubic-bezier(.5,0,0,1) 0;
            width: 0.75em;
            li {
              background: #FFF;

              float: left;
              height: 3px;
              margin: 3px 0 0;
              padding: 0;
              transform: translate3d(0px,0px,0px);
              transition: all 333ms cubic-bezier(0.5,0,0,1) 0;
              width: 1.5em;
              &.checked li {
                &.first {
                  transform: rotate(-135deg) translateY(-4.5px);
                }
                &.second {
                  opacity: 0;
                }
                &.third {
                  transform: rotate(135deg) translateY(4.5px);
                }
              }
            }
          }
        }
      }
      #mobile-cart {
        display: none;
        @include media($mobile) {
          z-index: 1;
          color: #FFF;
          display: table-cell;
          position: absolute;
          line-height: 40px;
          vertical-align: middle;
          top: 1em;
          right: 20px;
        a {
            color: $white;

            &:hover {
                color: shade($white, 10%);
            }

            

            span {
                margin-left: 5px;
                display: inline-block;
                vertical-align: middle;
                i {
                    display: inline-block;
                    vertical-align: text-top;
                }
            }

            .fa-shopping-cart {
                position: relative;
                font-size: 2em;
            }

            .number-of-items {
                position: absolute;
                top: -15px;
                left: 15px;
                font-size: 0.5em;
                width: 1.5em;
                height: 1.5em;
                font-family: $baseFontFamily;
                background-color: $brand-color-delta;
                color: $white;
                border-radius: 100%;
                text-align: center;
                vertical-align:middle;
                padding-top: 4px;
            }
        }
        }
        @include media($tablet) {
          z-index: 1;
          color: #FFF;
          display: table-cell;
          position: absolute;
          line-height: 40px;
          vertical-align: middle;
          top: 1em;
          right: 20px;
        a {
            color: $white;

            &:hover {
                color: shade($white, 10%);
            }

            

            span {
                margin-left: 5px;
                display: inline-block;
                vertical-align: middle;
                i {
                    display: inline-block;
                    vertical-align: text-top;
                }
            }

            .fa-shopping-cart {
                position: relative;
                font-size: 2em;
            }

            .number-of-items {
                position: absolute;
                top: -15px;
                left: 15px;
                font-size: 0.5em;
                width: 1.5em;
                height: 1.5em;
                font-family: $baseFontFamily;
                background-color: $brand-color-delta;
                color: $white;
                border-radius: 100%;
                text-align: center;
                vertical-align:middle;
                padding-top: 4px;
            }
        }
        }
      }

      .logo {
          width: 25%;
          /*display: inline-block;*/
          left: 0;
     
         

          &:after {
              content: "";
              display: table;
              clear: both;
          }

          h1 {
              color: transparent;
              margin: 0;
              padding: 0.25em;
              
          }
          img {
              width: 200px;

              @include media($mobile){
                  width: 150px;
              }

          }
          }
    
          
      
       @include media($mobile) {


            .logo {
                position:static;   
                text-align: center;
                margin: 0 auto;
            } 
        }

       @include media($tablet) {


            .logo {
                position:static;   
                text-align: center;
                margin: 0 auto;
            } 
        }

        form.search-bar {
            @include media($mobile) {
                width: 100%;
            }

            @include media($tablet){
                width: auto;
            }
          ///////////////////////////////////////////////////////////////////////////////////
          $base-spacing: 1.5em !default;
          $base-border-color: gainsboro !default;
          $base-line-height: 1.5em !default;
          //////////////////////////////////////////////////////////////////////////////////

          $search-bar-border-color: $base-border-color;
          $search-bar-border: 1px solid $search-bar-border-color;
          $search-bar-background: lighten($search-bar-border-color, 10%);

          position: relative;
          /*display: inline-block;*/
          vertical-align: middle;
          margin: 1em auto 0 auto;
          width: 30%;
          input[type=search] {
            background-color: white;
            height: 42px;
            border: 1px solid $base-border-color;
            box-sizing: border-box;
            display: block;
            font-size: 1em;
            font-style: italic;
            margin: 0;
            padding: ($base-spacing / 3) ($base-spacing / 3);
            position: relative;
            transition: border-color;
            width: 100%;
          }

          button[type=submit] {
            @include position(absolute, 0 0 0 null);
            outline: none;
            padding: 5px 10px;
            height: 42px;

            img {
              height: 12px;
              opacity: 0.7;
            }
          }
        }


      ul {
        list-style: none;
        margin: 0;
        display: inline-block;

        #my-account-button {
            position: relative;

            #new-hire-star {
                font-size: 1.1em;
                color: gold;
                position: absolute;
                top: 3px;
                right: 3px;
            }
        }

        li {
          line-height: 40px;
          display: inline-block;
          padding: 0 0.5em;
          vertical-align: middle;


          a {
              img {
                  width: 20px;
              }
          }
        }
      }

      .right-nav {
    
        @include omega;
        @include media($mobile) {
          display: none;
        }
        @include media($tablet) {
          display: none;
        }
        position: relative;
        top: 0;
        right: 1em;
        display: inline-block;
        margin-left: auto;
        vertical-align: text-top;

        text-align: right;
        font-family: $baseFontFamily;
        font-size: 0.75em;
        z-index: 10;

        li {
            vertical-align: text-top;
        }

        a {
            color: #FFF;
            cursor: pointer;
            

            &:hover {
                text-decoration: underline;

                span {
                    text-decoration: underline;
                }

        
            }





            .number-of-items {
                position: absolute;
                top: -5px;
                right: -15px;
                width: 1.75em;
                height: 1.75em;
                font-family: $baseFontFamily;
                background-color: $brand-color-delta;
                color: $white;
                font-size: 0.5em;
                border-radius: 100%;
                text-align: center;
                vertical-align:middle;
                padding-top: 5px;
            }
        }

             .tooltip-item {
                $base-border-color: gainsboro !default;
                $base-border-radius: 3px !default;
                $base-line-height: 1.5em !default;
                $dark-gray: #333 !default;
                $base-font-color: $black !default;
                $tooltip-background: #FFF;
                $tooltip-color: $base-font-color;
                $tooltip-max-width: 16em;
                $tooltip-arrow-width: 8px;
                $tooltip-shadow: 0 2px 2px rgba(0,0,0,0.3);
                $tooltip-distance-from-item: 3em;
                $tooltip-arrow-distance-from-box: -1.3em;

                display: inline-block;
                position: relative;
                text-align: center;
                
                &:focus,
                &:hover .tooltip {
                opacity: 1;
                visibility: visible;
                @include media($mobile){
                    visibility: hidden !important;
                }
                @include media($tablet){
                    visibility: hidden !important;
                }
                }

                .tooltip {
                position: absolute;
                top: 45px;
                left: -130px;
                background-color: $tooltip-background;
                background: #FFF;
                border-radius: $base-border-radius;
                box-shadow: $tooltip-shadow;
                color: $tooltip-color;
                font-size: 0.9em; // Make sure you use -webkit-backface-visibility: hidden; on the body element to prevent 1px nudging bugs.
                line-height: 1.5em;
                margin: 0 auto;
                width: 200px;
                opacity: 0;
                padding: 1em;
                text-align: center;
                transition: all 0.2s ease-in-out;
                visibility: hidden;
                z-index: 10;
                border: 1px solid rgba(0,0,0,0.2);

                &::after {
                    position: absolute;
                    top: -12px;
                    right: 30px;
                    //border: $tooltip-arrow-width solid transparent;
                    color: $tooltip-background;
                    content: "▼";
                    font-size: 1.4em;
                    margin-left: -$tooltip-arrow-width;
                    text-align: center;
                    text-shadow: $tooltip-shadow;
                    transform: rotate(180deg)
                }

                p {
                    font-weight: 700;
                    margin-bottom: 1em;
                }

                .cart-product-amount {
                    font-size: 2em;
                    font-weight: 700;

                }

                button {
                    span {
                        margin-left: 5px;
                    }
                }

                

                }
                }

            .fa-shopping-cart, .fa-user, .fa-search, .fa-dashboard {
            position: relative;
            font-size: 1em;
            margin: 0.25em;
            }

      }
    }
  }
    

  .product-navigation {
    width: 100%;
    text-align: center;
    position: relative;
    margin-top: 0.5em !important;
    margin-left: auto;
    background-color: $brand-color-alpha;





   

 
      #back-to-brand-selector {
        position: absolute;
        top: 1em;
        left: 1em;
        color: $white;
        z-index: 11;
        font-family: $baseFontFamily;
        font-size: 0.75em;

        &:hover {
            text-decoration: underline;
        }
        &:active, &:focus {
            color: #FFF;
        }

        i {
            margin-right: 10px;
        }


        @include media($tablet){
            margin-right: 5%;
        }
    }


      display: inline-block;
      margin: 0;
    ul {
      li {
        padding: 0;
        text-align: left;
        float: none;
        display: block;
        ul {
          left: 100%;
          top: 0;
        }
      }
      ul:after {
        content: '';
        border: 0 none!important;
      }
    }
    span.arrow {
      margin-left: 5px;
    }
    > li {

      position: relative;
      list-style: none;
      display: inline-block;
      line-height: 47px;
      font-family: $baseFontFamily;
      font-size: 16px;
      padding: 0.9em 0.75em !important;

      &:hover {
        color: #eee;
      }

      a {
 

        
        white-space: nowrap;
        @media only screen and (max-width:1210px) {
          letter-spacing: 0;
          margin-right: .875em;
        }
        &:hover {


            color: #FFF;

        }
      }
    }
  
  p {
    text-align: center;
    padding: 15px 0;
  }
  li {
    a {
      color: #FFF;
      font-weight: 300;
      letter-spacing: 1px;

      &:hover {
          text-decoration: underline;
      }
    }

    &.menu-item-has-children {
      ul > li {
        a:hover {

          color: $white;
        }
        &.current-menu-item a {

          color: $white;
        }
      }
      &:hover > a {
        color: $grayLight;
      }
    }
    > ul.sub-menu {
      list-style: none;
      display: none;
      margin: 0;
      padding: 0;
      position: absolute;
      width: 210px;
      z-index: 111;


    }
    ul.sub-menu a {
      background: $brand-color-beta;
      color: $white;
      display: block;
      font-size: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
      line-height: 15px;
      padding: 1em;
      position: relative;
      width: 215px;
      &:hover {
        background: $grayDarker;
        color: $white;
      }
    }
    &:hover > ul.sub-menu {
      display: block;

    }
  }

  .mobile-close {
      font-size: 1em;
      text-align: right;
      border-bottom: 0 !important;
      height: auto;
      @include media($desktop){
          display: none !important;
      }

      
  }

  @include media($mobile){
      position: fixed;
      top: 0px;
      left: -150px;
      height: 100%;
      background-color: $black;
      display: block !important;
      width: 100%;
      margin: 0 !important;
      text-align: left;
      z-index: 10;
      width: 0;
      padding: 1em;
      transition: all .2s ease-out;

      li {
          display: block !important;
          padding: 0.5em !important;
          border-bottom: 1px solid rgba(255,255,255,0.2);
          height: auto !important;
      }
  }

  @include media($tablet){
      position: fixed;
      top: 0px;
      left: -150px;
      height: 100%;
      background-color: $black;
      display: block !important;
      width: 100%;
      margin: 0 !important;
      text-align: left;
      z-index: 10;
      width: 0;
      padding: 1em;
      transition: all .2s ease-out;

      li {
          display: block !important;
          padding: 0.5em !important;
          border-bottom: 1px solid rgba(255,255,255,0.2);
      }
  }
}

  }
}

.menu-open {
    left: 0 !important;
    width: 300px !important;
}


//Full Screen Search Bar

.search-full-screen {
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    position: fixed;
    z-index: -100;
    opacity: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all .3s ease-in-out;
    text-align: center;

    .search-form {
        width: 50%;
        margin: 20% auto 0 auto;
        h1 {
            text-align: center;
            color: #FFF;
        }


        button {
            background-color: transparent;
            vertical-align: text-bottom;
            border-bottom-right-radius: 5px;
            border-top-right-radius: 5px;
            border-left: 0;
            border: 1px solid #FFF;
            height: 3.6em;
            width: 4em;
            margin-top: 15px;
            position: relative;
            left: -6px;

            i {
                color: rgba(255,255,255,1);
                font-size: 1.5em;
            }

            &:hover {
                background-color: rgba(255,255,255,0.3);

               
            }
        }

    }

    .close-button {
        position: absolute;
        right: 2em;
        top: 2em;

        i {
            color: rgba(255,255,255,0.6);
            font-size: 5em;

            &:hover {
                color: rgba(255,255,255,1);
                cursor: pointer;
            }
        }
    }


    input[type="search"] {
        background-color: transparent !important;
        border: 1px solid #FFF;
        box-shadow: none;
        transition: all .2s ease-in-out;
        vertical-align: bottom;
        width: 30em;
        height: 3em;
        color: #FFF;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        margin-bottom: 0;
        font-weight: 200;
        vertical-align: text-bottom;
    }
}

.show-search-screen {
    z-index: 100;
    opacity: 1;
    transition: all .2s ease-in-out;
}

.show-mobile {

    input[type=search]{
        border-radius: 5px;
        background-color: transparent !important;
        color: $white !important;
    }

    @include media($desktop){
        display: none !important;
    }

    @include media($mobile){
        display: block !important;
    }

}








@media only screen and (max-width: 320px) {
  #theme-wrapper.side-menu {
    left: 300px;
  }
  header#main.side-menu {
    left: 300px;
  }
}
