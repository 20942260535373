.hero {
width: 100%;
display: block;
top: 0;
z-index: 0;
margin: 0 0 1em 0;
padding: 0;

@include media($mobile){

    position: relative;


    .item {
        &:nth-of-type(1){
            img {
                position: relative;
                left: 3em;
            }
        }
        &:nth-of-type(3){
            img {
                position: relative;
                left: 3em;
            }
        }
        &:nth-of-type(2){
            img {
                position: relative;
                right: 15em;
            }
        }

        img {
            max-width: 150% !important;

        }

        .carousel-caption {
            position: static;
            width: 100%;

            li {
                width: auto !important;

                &:nth-of-type(3){
                    text-align: center;
                }
            }
        }
    }

}

@include media($tablet){
  



    .carousel-caption {
        width: 20em !important;
        top: 20% !important;

        h2 {
            font-size: 26px;
        }
        p {
            font-size: 14px !important;
        }
        
    }
}

&:after {
    content: "";
    display: table;
    clear: both;  
}

/*background-image: url(#{$image-url-path}/clouds.jpg);
background-size: cover;
background-position: center;*/

.hero-wrapper {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    a {
        display:block;
        width: 100%;
    }
}

  .hero-banner {
        width: 100%;
        height: 300px;
        position: relative;
        top: 0;
        left: 0;
        margin-bottom: 2em;
        padding-top: 3em;
        background-image: url(#{$image-url-path}/background-tile.png);
        background-repeat: repeat;
        background-size: 150px;
        

        @include media($mobile){
            margin-bottom: 1em;
        }

        ul {
            width: 100%;
            list-style: none;
            text-align: center;

            margin: 0;

            li {
                line-height: 1.2;
                &:nth-of-type(1) {
                    font-weight: 400;
                    font-size: 3em;

                    span {
                        color: $brand-color-alpha;
                    }
                }

                &:nth-of-type(2) {
                    font-weight: 800;
                    font-style: oblique;
                    font-size: 4em;
                    text-transform: uppercase;

                }
            }
        }
  }

  .hero-special-milestone {
      max-width: 75em;
      margin: 0 auto 10em auto;
      position: relative;
      display: flex;
      

     .milestone-program, .special-order {       
        width: 47%;
        display: inline-flex;
         background-color: #f5f5f5;
         border-radius: 0.5em;
         padding: 2em;
         position: relative;
         z-index: 0;
         margin: 1em;
        flex-direction: column;
         text-align: center;
         vertical-align:text-top;

         i {
             
             font-size: 5em;
             margin: 1em;
             color: $brand-color-delta;
    
         }

         p {
            
            margin-bottom: auto;
             text-align: left;
           flex-grow: 1;
          &:nth-of-type(2){
              padding-bottom: 3em;
          }
            
         }

         button {
            margin-top: auto;
           
           
         }
     }

     .special-order {

     }

      

      
  }
}

  .hero-images {

      margin: 0 auto;
      text-align: center;


      ul {
          margin: 0 auto 3em auto;
          width: 100%;
         

          li {
              width: 24em;
              display: inline-block;
              background-color: #eee;
              text-align: center;
              padding-top: 1em;
              margin: 1em;
              position: relative;
              box-shadow: 5px 5px 5px rgba(0,0,0,0.5);
              
              img {
                  text-align: center;
                  display: block;
                 margin: 0 auto;
                 margin-bottom: 3em;
                 width: 90%;
                  border: 1px solid #ccc;
              } 

              h3 {
                  font-style:normal;
                  color: $brand-color-gamma;
                  text-transform: none;
                  margin-bottom: 1em;
              }
              
              button {
                  background-color: $brand-color-gamma;
                  width: 100%;
                  min-height: 2em;
                  position: relative;
                  bottom: 0;
                  margin: 0;
                  color: $white;

                  &:hover {
                      background-color: shade($brand-color-gamma, 20%);
                      border-color: shade($brand-color-gamma, 20%);
                  }

                  .inner-hero-button {

                      &:after {
                          font-family: FontAwesome;
                          content: "\f101";
                          margin-left: 0.5em;
                      }
                  }
              } 
          }
      }
  }

#owl-home {
  position: relative;
  .opacity {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(255,255,255,0.8);
    opacity: 0;
    transition-property: opacity;
    transition-timing-function: ease-out;
    transition-duration: 250ms;
    -webkit-transition-property: opacity;
    -webkit-transition-timing-function: ease-out;
    -webkit-transition-duration: 250ms;
    -moz-transition-property: opacity;
    -moz-transition-timing-function: ease-out;
    -moz-transition-duration: 250ms;
    -ms-transition-property: opacity;
    -ms-transition-timing-function: ease-out;
    -ms-transition-duration: 250ms;
    -o-transition-property: opacity;
    -o-transition-timing-function: ease-out;
    -o-transition-duration: 250ms;
    &:hover {
      opacity: 1;
    }
    &:before {
      content: '';
      height: 100%;
      display: inline-block;
      width: 0;
      vertical-align: middle;
    }
    .swiper-product-informations {
      display: inline-block;
      vertical-align: middle;
      width: 97%;
      text-align: center;
      .product-title {
        font-size: 18px;
        text-transform: uppercase;
        font-weight: bold;
        a {
          text-decoration: none;
        }
      }
      .amount {
        display: block;
      }
    }
  }
}


/* ============================================================
  PRIMARY STRUCTURE
============================================================ */
.container {

  margin: 0 auto;
}
/* ============================================================
  SECTIONS
============================================================ */
section.module:last-child {
  margin-bottom: 0;
}

section.module p:last-child {
  margin-bottom: 0;
}
section.module.p-content {
  padding: 40px 0;
}
section.module.parallax {
  min-height: 700px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

section.module.parallax-1 {


}


@media all and (min-width: 600px) {
  section.module h2 {
    font-size: 42px;
  }
  section.module p {
    font-size: 20px;
  }
  section.module.parallax h1 {
    font-size: 96px;
  }
}
@media all and (min-width: 960px) {
  section.module.parallax h1 {
    font-size: 160px;
  }
}

.category-icons {
    width: 100%;
    background: $brand-color-alpha;
    color: $white;
    padding: 2em 10em 2em 10em;
    position: relative;
    z-index: 1;
    text-align: center;

    @include media($mobile){
        margin-top: 0 !important;
        padding-top: 30em;
    }

    @include media($tablet){
        margin-top: 50vh;

        ul {
            li {
                width: 19% !important;
            }
        }
    }

        h3 {
            color: $white;
            margin-bottom: 3em;
            font-weight:600;
      
        }
    

    ul {
        list-style: none;
        text-align: center;
        margin: 0;

        li {
            display: inline-block;
            width: 30%;
            margin: 3em 0 5em 0;
            padding-bottom: 3em;
            border-bottom: 2px solid rgba(64,180,229,0.3);
            cursor: pointer;

            @include media($mobile){
                margin: 1em;
                width: 35%;
            }

            a {
                color: rgba(255,255,255,0.6);
                width: 100%;
                height: 100%;
            }

            img {
                width: 60px;
                margin-bottom: 2em;
                opacity: 0.6;
            }

            &:hover {

                border-bottom: 3px solid rgba(64,180,229,1);

                a {
                    color: rgba(255,255,255,1);
                }

                img {
                    opacity: 1;
                }
            }
        }
    }

    @include media($mobile){
        padding: 40em 1em 8em 1em;

        ul {

            li {

                img {
                    width: 60px;
                    opacity: 1;
                }

                a {
                    color: rgba(255,255,255,1);
                }
            }
        }
    }

    @include media($tablet){
        padding: 40em 1em 8em 1em;

        ul {

            li {

                img {
                    width: 60px;
                    opacity: 1;
                }

                a {
                    color: rgba(255,255,255,1);
                }
            }
        }
    }
}