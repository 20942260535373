*,
*:before,
*:after {
  box-sizing: border-box;
}
@include sticky-footer(80px);


body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: $baseFontFamily !important;
  font-weight: 200;
  line-height: 1.6em;
  color: $text-color-dark;
  margin: 0;
}

html, body, #theme-wrapper, #contain, footer {
  width: 100%;
}

.section-stage {
  background: #202020;
  width: 100%;
  height: 500px;
}

.main {
  transition: left 0.25s ease-in-out;
  position: relative;
  &.open {
    left: 280px;
    @include media($desktop) {
      left: 0;
    }
  }
}

.container {
  clear: both;
  margin: 0 auto;
  padding: 0;
  position: relative;
  width: 100%;
 // max-width: 1224px;
  @include media($mobile) {
    padding: 0 10px;
  }
  ul {
    margin: 0;
  }
}

.twelve-columns {
  @include span-columns(12);
  @include omega;
}

img {
  -ms-interpolation-mode: bicubic;
}