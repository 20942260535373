.page_wrapper {
  @include span-columns(12);
  overflow: hidden;

  .right_content {
    @include span-columns(10 of 12);
    @include omega;
    @include media($mobile) {
      @include span-columns(12 of 12);
      @include omega;
    }
    @include media($mobile) {
      @include span-columns(12 of 12);
      @include omega;
    }
    .shipping {
      @include span-columns(5 of 10);
    }
    .ship-table {
      @include span-columns(5 of 10);
      @include omega;
      table {
        border-bottom: 1px solid $footerBackground;
        thead { background-color: $footerBackground; }
      }
    }
  }
}
