/* Sticky footer styles
-------------------------------------------------- */

body { background: #fff;
}


.content {
	@include outer-container;
}
.main-bg {
  @include span-columns(12);
  background: $white;
  @include box-shadow(0 0 5px rgba(0,0,0,.18), 0 0 -5px rgba(0,0,0,.18));
}
input, textarea { @include border-radius(none); }

a { color: $brand-color-beta;
    &:hover {
        color: $brand-color-alpha;
    }

}

// Hiding IE7's shame
.ie7 #oggchat { display: none; }

.standout {
    text-decoration: underline;
  color: #434242;
  padding: 2px;
  border: 1px solid transparent;
  &:hover {
      color: #000;
        text-decoration: none;
  }
}