//Order confirmation

.order-confirmation {
    background-color: $grayLight;
    width: 100%;

    .order-confirmation-wrapper {
        max-width: 800px;
        width: 100%;
        padding: 2rem 2rem;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        background-color: $white;
    }

    .order-confirmation-header {
        width: 100%;
        text-align: center;
        padding: 2rem;
        margin-bottom: 2rem;

        h3 {
            font-weight: bold;
        }

        .order-num {
            font-size: 18px;
            color: $black;
        }
    }

    .order-confirm-entry {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        line-height: 1.5;

        .left {
            width: 50%;
            font-weight: bold;
            margin-bottom: 2rem;
        }

        .right {
            width: 50%;
            margin-bottom: 2rem;
        }

        .left, .right {
            @media (max-width:500px) {
                width: 100%;
                margin-bottom: 1rem;
            }
        }

        hr {
            border-top: 0;
            border: 1px dashed $grayMedium;
            width: 100%;
            margin: 1rem 0;
        }
    }

    .shipping-address {

        .order-comments {
            width: 100%;
            margin-bottom: 2rem;

            div {
                width: 100%;

                &:first-of-type {
                    margin-bottom: 1rem;
                }
            }
        }

        .item-summary, .item-summary-header, .item-summary-products {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
        }

        .item-summary-header, .item-summary-products {
            width: 100%;
            padding: 0.5rem 0;
            font-size: 0.9rem;

            div {
                width: 25%;
            }
        }

        .item-summary-header {
            width: 100%;
            font-weight: bold;
            margin-top: 2rem;

            div {
                padding-right: 0.5rem;

                &:last-of-type {
                    text-align: right;
                    padding-right: 0;
                }
            }

            @media (max-width: 500px) {
                display: none;
            }
        }

        .item-summary-products {

            .item-summary-products-entry {
                width: 100%;
                padding: 0.5rem 0;
                display: flex;
                flex-wrap: wrap;

                @media (max-width:500px) {
                    padding-bottom: 0.5rem;
                    margin-bottom: 0.5rem;
                    border-bottom: 1px dashed $grayMedium;

                    &:last-of-type {
                        border-bottom: 0;
                    }

                    span {
                        padding: 0.25rem;

                        &:first-of-type {
                            padding-left: 0;
                        }

                        &:last-of-type {
                            margin-left: auto;
                            padding-right: 0;
                        }
                    }
                }

                div {
                    padding-right: 0.5rem;

                    &:last-of-type {
                        text-align: right;
                        padding-right: 0;
                    }

                    .mobile-header {
                        display: none;
                    }

                    @media (max-width: 500px) {
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;
                        padding-right: 0;

                        .mobile-header {
                            display: flex;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }

    .order-summary {
        max-width: 400px;
        margin-left: auto;

        .right {
            text-align: right;
        }

        .right, .left {
            @media (max-width: 500px) {
                width: 50% !important;
            }
        }
    }
}


