//
// Hero unit
// --------------------------------------------------


.hero-unit {
  padding: 60px;
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 200;
  line-height: $baseLineHeight * 1.5;
  color: $heroUnitLeadColor;
  background-color: $heroUnitBackground;
  @include border-radius(6px);
  h1 {
    margin-bottom: 0;
    font-size: 60px;
    line-height: 1;
    color: $heroUnitHeadingColor;
    letter-spacing: -1px;
  }
  li {
    line-height: $baseLineHeight * 1.5; // Reset since we specify in type.scss
  }
}
