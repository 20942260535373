//
// Utility classes
// --------------------------------------------------


// Quick floats
.pull-right {
  float: right;
    @include media($mobile) {
      float: left;
    }
}
.pull-left {
  float: left;
}

// Toggling content
.hide {
  display: none;
}
.show {
  display: block;
}

// Visibility
.invisible {
  visibility: hidden;
}

// For Affix plugin
.affix {
  position: fixed;
}

// Clearing floats
.clearfix {
  @include clearfix();
}

// Accessible yet invisible text
.hide-text {
  @include hide-text;
}

// Uses box-sizing mixin, so must be defined here
.input-block-level {
  @include input-block-level();
}

// Hide from desktop/tablet
.hide-mobile {
  @include media($desktop) {
    display: none !important;
  }
  @include media($tablet) {
    display: none !important;
  }
}
// Hide from mobile
.mobile-hidden {
  @include media($mobile) { display: none; }
}

.desktop-hidden {
  @include media($desktop) { display: none; }
}

// Element with no margin or padding
.nomargin {
  margin: 0;
}
.nopadding {
  padding: 0;
}

.center-text {
  text-align: center;
}