//
// Buttons
// --------------------------------------------------


// Base styles
// --------------------------------------------------

// Core
.btn {
  display: inline-block;
  @include ie7-inline-block();
  padding: $paddingSmall;
  margin-bottom: 0; // For input.btn
  font-size: $baseFontSize;
  line-height: $baseLineHeight;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  outline:none;
  *border: 0; // Remove the border to prevent IE7's black border on input:focus
  @include ie7-restore-left-whitespace(); // Give IE7 some love
  text-transform: uppercase;
  transition: all .1s linear;
  // Hover/focus state
  &:hover,
  &:focus {
    color: $grayDark;
    text-decoration: none;
    background-position: 0 -15px;

    // transition is only when going to hover/focus, otherwise the background
    // behind the gradient (there for IE<=9 fallback) gets mismatched
    @include transition(background-position .1s linear);
  }

  // Focus state for keyboard and accessibility
  &:focus {
    @include tab-focus();
  }

  // Active state
  &.active,
  &:active {
    background-image: none;
    outline: 0;
    @include box-shadow(inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05));
  }

  // Disabled state
  &.disabled,
  &[disabled] {
    cursor: default;
    background-image: none;
    @include opacity(65);
    @include box-shadow(none);
  }

}



// Button Sizes
// --------------------------------------------------

// Large
.btn-large {
  padding: $paddingLarge;
  font-size: $fontSizeLarge;
  @include border-radius($borderRadiusLarge);
}
.btn-large [class^="icon-"],
.btn-large [class*=" icon-"] {
  margin-top: 4px;
}

// Small
.btn-small {
  padding: $paddingSmall;
  font-size: $fontSizeSmall;
  @include border-radius($borderRadiusSmall);
}
.btn-small [class^="icon-"],
.btn-small [class*=" icon-"] {
  margin-top: 0;
}
.btn-mini [class^="icon-"],
.btn-mini [class*=" icon-"] {
  margin-top: -1px;
}

// Mini
.btn-mini {
  padding: $paddingMini;
  font-size: $fontSizeMini;
  @include border-radius($borderRadiusSmall);
}


// Block button
// -------------------------

.btn-block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  @include box-sizing(border-box);
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
  margin-top: 5px;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}



// Alternate buttons
// --------------------------------------------------

// Provide *some* extra contrast for those who can get it
/*.btn-primary.active,
.btn-warning.active,
.btn-danger.active,
.btn-success.active,
.btn-info.active,
.btn-inverse.active,
.btn-cart.active {
  color: rgba(255,255,255,.75);*/
/*}*/

//Buttons
button:focus {
    outline: 0;
}

.btn-alpha, #c-submit-button {
    font-family: $headingsFontFamily;
    font-size: 1em;
    color: #FFF !important;
    text-transform: uppercase;
    font-weight: 600;
    background-color: $brand-color-alpha;
    padding: 10px 20px;
    margin: 0.5em;
    height: 50px;
    transition: all .2s ease-out;
    border: 3px solid $brand-color-alpha;
    text-align: center;
    max-width: fit-content;
   
  
        &:hover {
            
            border-color: $brand-color-beta;
            text-decoration: none !important;
            transition: all .2s ease-out;
        }

        &:active, &:focus {
            color: $white;
        }

        i {
            margin-right: 0.5em;
        }
}

.btn-beta {
    font-family: $headingsFontFamily;
    color: #FFF;
    font-size: 1em;
    font-weight: 600;
    text-transform: uppercase;
    background-color: $brand-color-beta;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0.5em;
    height: 50px;
    transition: all .2s ease-out;
    border: 3px solid $brand-color-beta;

  
        &:hover {
            border-color: $brand-color-alpha;
            text-decoration: none !important;
            transition: all .2s ease-out;
        }

        &:active, &:focus {
            color: $brand-color-beta;
        }
}

.btn-gamma {
    font-family: $headingsFontFamily;
    font-size: 1em;
    color: #FFF;
    text-transform: uppercase;
    font-weight: 600;
    background-color: $brand-color-alpha;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0.5em;
    height: 50px;
    transition: all .2s ease-out;
    border: 3px solid $brand-color-alpha;
   
  
        &:hover {
            
            border-color: $brand-color-beta;
            text-decoration: none !important;
            transition: all .2s ease-out;
        }

        &:active, &:focus {
            color: $white;
        }

        i {
            margin-right: 0.5em;
        }
}

.btn-neutral {
    font-family: $baseFontFamily;
    color: #FFF;
    font-size: 1em;
    font-weight: 600;
    text-transform: uppercase;
    background-color: $grayMedium;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0.5em;
    height: 50px;
    transition: all .2s ease-out;
    border: 3px solid $grayMedium;
    vertical-align: middle;

  
        &:hover {
 
            border-color: $brand-color-beta;
            text-decoration: none !important;
            transition: all .2s ease-out;
            color: #FFF;
        }

        &:active, &:focus {
            color: $white;
        }

}

.btn-approve {
    font-family: $baseFontFamily;
    color: #FFF;
    font-size: 1em;
    text-transform: uppercase;
    background-color: green;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0.5em;
    height: 50px;
    transition: all .2s ease-out;
    border: 3px solid green;
    vertical-align: middle;
  
        &:hover {
            background-color: shade(green, 20%);
            border-color: shade(green, 20%);
            text-decoration: none !important;
            transition: all .2s ease-out;
            color: #FFF;
        }

        &:active, &:focus {
            color: $white;
        }

}

.btn-deny {
    font-family: $baseFontFamily;
    color: #FFF;
    font-size: 1em;
    text-transform: uppercase;
    background-color: red;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0.5em;
    height: 50px;
    transition: all .2s ease-out;
    border: 3px solid red;
    vertical-align: middle;
  
        &:hover {
            background-color: shade(red, 20%);
            border-color: shade(red, 20%);
            text-decoration: none !important;
            transition: all .2s ease-out;
            color: #FFF;
        }

        &:active, &:focus {
            color: $white;
        }

}

.btn-ghost {
    font-family: $headingsFontFamily;
    color: $white;
    font-size: 1em;
    font-weight: 600;
    text-transform: uppercase;
    background-color: transparent;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0.5em;
    height: 50px;
    transition: all .2s ease-in-out;
    border: 3px solid $white;

  
        &:hover {
            border-color: $brand-color-alpha;
            text-decoration: none !important;
            transition: all .2s ease-in-out;
            color: $black;
            background-color: $brand-color-alpha;
        }

        &:active, &:focus {
            color: $white;
        }
}

#view-cart-button {
    padding-top: 10px;
    padding-bottom: 10px;
        padding-left: 20px;
    padding-right: 20px;

    &:hover, &:active, &:focus {
        color: #FFF;
    }
}

#tooltip-checkout-button {
    font-size: 1.2em;
}

.btn-notify-view {
    font-family: $baseFontFamily;
    color: #FFF;
    font-size: 1em;
    font-weight: 600;
    text-transform: uppercase;
    background-color: $grayMedium;
    height: 30px;
    transition: all .2s ease-out;
    border: 3px solid $grayMedium;
    vertical-align: middle;
    margin-top: 1em;
  
        &:hover {
 
            border-color: $brand-color-beta;
            text-decoration: none !important;
            transition: all .2s ease-out;
            color: #FFF;
        }

        &:active, &:focus {
            color: $white;
        }

}

.btn-notify-checkout {
    font-family: $headingsFontFamily;
    font-size: 1em;
    color: #FFF;
    text-transform: uppercase;
    font-weight: 600;
    background-color: $brand-color-alpha;
    height: 30px;
    transition: all .2s ease-out;
    border: 3px solid $brand-color-alpha;
   margin-top: 1em;
  
        &:hover {
            
            border-color: $brand-color-beta;
            text-decoration: none !important;
            transition: all .2s ease-out;
        }

        &:active, &:focus {
            color: $white;
        }

        i {
            margin-right: 0.5em;
        }
}

.btn-cart {
    font-family: $headingsFontFamily;
    font-size: 1em;
    color: #FFF;
    text-transform: uppercase;
    font-weight: 600;
    background-color: $brand-color-alpha;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0.5em;
    height: 50px;
    transition: all .2s ease-out;
    border: 3px solid $brand-color-alpha;
   
  
        &:hover {
            
            border-color: $brand-color-beta;
            text-decoration: none !important;
            transition: all .2s ease-out;
        }

        &:active, &:focus {
            color: $white;
        }

        &::before {
            font-family: FontAwesome;
            content: "\f07a";
            margin-right: 0.5em;
        }

        i {
            margin-right: 0.5em;
        }
}

.btn-wishlist {
    font-family: $baseFontFamily;
    color: $brand-color-beta;
    text-transform: uppercase;
    font-weight: 600;
    background-color: transparent;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0.5em;
    height: 50px;
    transition: all .2s ease-out;
    border: 3px solid transparent;
    vertical-align: middle;
  
        &:hover {
            color: $brand-color-alpha !important;
            text-decoration: none !important;
            transition: all .2s ease-out;

            i {
                color: $brand-color-alpha !important;
            }
        }

        &:active, &:focus {
            color: $grayDark;
        }

        i {
            margin-right: 0.5em;
        }
}

// Cross-browser Jank
// --------------------------------------------------

button.btn,
input[type="submit"].btn {

  // Firefox 3.6 only I believe
  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }

  // IE7 has some default padding on button controls
  *padding-top: 3px;
  *padding-bottom: 3px;

  &.btn-large {
    *padding-top: 7px;
    *padding-bottom: 7px;
  }
  &.btn-small {
    *padding-top: 3px;
    *padding-bottom: 3px;
  }
  &.btn-mini {
    *padding-top: 1px;
    *padding-bottom: 1px;
  }
}


// Link buttons
// --------------------------------------------------

// Make a button look and behave like a link
.btn-link,
.btn-link:active,
.btn-link[disabled] {
  background-color: transparent;
  background-image: none;
  @include box-shadow(none);
}
.btn-link {
  border-color: transparent;
  cursor: pointer;
  @include border-radius(0);
}
.btn-link:hover,
.btn-link:focus {
  color: $linkColorHover;
  text-decoration: underline;
  background-color: transparent;
}
.btn-link[disabled]:hover,
.btn-link[disabled]:focus {
  color: $grayDark;
  text-decoration: none;
}


.search {
  padding: 4px 12px;
  @include border-radius($baseBorderRadius);
}

.mobile-btn {
  @include media($mobile) {
    @include fill-parent;
    margin: 20px 0;
    font-size: 16px;
    padding: 10px;
  }
}

.trans-btn {
  font-family: 'Oswald';
  font-size: 16px;
  text-transform: uppercase;
  line-height: 20px;
  border: 2px solid #333;
  color: #333;
  padding: 8px 46px;
  background-color: #fff;
  margin: 10px !important;
  transition: all .2s ease-out;
  outline: 0;
  &:hover {
    background: #333;
    color: #fff;
    text-decoration: none;
  transition: all .2s ease-out;
  }
}


.red-btn {
  color: #000;
  background-color: #ee1b2e;
  border: 3px solid #ee1b2e;
  border-color: transparent;
  cursor: pointer;
  text-transform: uppercase;
  line-height: 20px;
  font-size: 16px;
  margin: 10px;
  padding: 8px 46px;
  @include border-radius(0);
  text-align: center;
  transition: all .2s ease-out;

  &:hover,
  &:active {
    border: 3px solid #ee1b2e;
    background-color: rgba(238,27,46,0.2);
    text-decoration: none !important;
  transition: all .2s ease-out;
  }
}

.btn-grey-sm {
  margin: 0;
  padding: 2px 10px;
}