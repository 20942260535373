//
// Breadcrumbs
// --------------------------------------------------
.breadcrumb-search {
  @include span-columns(10 of 10);
  text-align: center;
  ul {
    text-align: center;
    margin: 0;
    list-style: none;
  }
  > li {
    display: inline-block;
    @include ie7-inline-block();
    text-shadow: 0 1px 0 $white;

    > .divider {
      padding: 0 5px;
      color: $black;
    }
  }
  .active {
    color: $grayLight;
  }
  @include media($mobile) {
    @include span-columns(12);
    margin-bottom: 10px;
  }
}

.breadcrumb {
    font-size: 0.9em;
  color: $grayDark;
  text-transform: uppercase;


  @include span-columns(10 of 10);
  @include omega;
  a {
    color: #777;
  }
  ul {
    float: left;
    padding: 15px 20px;
    margin: 0;
    list-style: none;
    @include media($mobile) {
      @include span-columns(10 of 10);
    }
  }
  > li {
    display: inline-block;
    @include ie7-inline-block();
    text-shadow: 0 1px 0 $white;

    > .divider {
      padding: 0 5px;
      color: #aaa;
    }
  }
  .active {
    color: $grayLight;
  }
  @include media($mobile) {
    @include span-columns(12);
    margin-bottom: 10px;
  }
}
