.no_nav_page_wrapper {
  @include span-columns(12);
  @include pad(20px 30px);
  form {
    .controls { margin: 10px 0 10px 180px; }
  }
  label {
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 14px 0 0 0;
    letter-spacing: .5px;
    cursor: default;
  }
  span.required {
    color: $black;
    font-weight: bold;
    font-size: 18px;
  }

  .login {
      max-width: 40em;
      text-align: center;
      border-radius: 0.5em;

     i {
         text-align: center;
         margin: 0 auto;
     }

    form {
        width: 100%;

        label {
            text-align: left;
        }
    }
    button {
        width: 100%;
        margin: 0.5em 0;
        display: block;
        margin: 1em 0 1em 0;
    }







    h4 { text-align: center; }
    font-size: 14px;

    hr { margin: 20px 0 0 0; }

    input {
        width: 100%;
        height: 3em;
    }

    input[type="text"], textarea, input[type="password"] {
        background-color: #FFF !important;
        border-radius: 5px;
        &:focus {
            background-color: #FFF;
        }
    }
    a {
        text-align: center;
        color: $brand-color-alpha;

        &:hover {
            color: $brand-color-alpha;
            text-decoration: underline;
        }
    }
    p {
      line-height: 30px;
      text-align: center;
    }
  }
  .return-login {

      padding: 2em;
      background-color: $white;
      border: 1px solid #CCC;

      img {
          width: 300px;
          margin-bottom: 2em;
      }

    h1 {
      text-align: left;
      color: #4d4d4f;
    }
  }
  .guest {
    @include span-columns(4 of 12);
    @include omega;
    padding-left: 80px;
    border-left: 1px solid $grayLighter;
    min-height: 300px;
    @include media($mobile) {
      @include span-columns(12);
      @include omega;
      border-left: 0;
      border-top: 1px solid $grayLighter;
      padding: 30px 0;
      min-height: 200px;
    }
  }

  @include media($mobile) {
    .GC {
      background-color: transparent;
      border: none;
    }
    padding: 20px 10px;
    /* Force table to not be like tables anymore */
    table, thead, tbody, th, td, tr {
      display: block;
      text-align: center;
    }
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    td {
      position: relative;
    }
    .form-btns {
      padding: 0;
      .btn {
        display: block;
        float: none;
        margin: 20px 0;
        font-size: 16px;
        padding: 10px;
        width: 100%;
      }
    }
  }
  .dealer-login {
    @include span-columns(4);
    @include shift(4);
    @include omega;
    @include media($mobile) { @include span-columns(12); @include shift(0); }
    @include media($mobile) { @include span-columns(12); @include shift(0); }
  }
}

  /*.phone_email_chat {
    @include span-columns(4 of 12);
    float: right;
    @include media($mobile) {
      @include span-columns(12 of 12);
      @include omega;
    }
    @include media($mobile) {
      @include span-columns(12 of 12);
      @include omega;
    }
  }*/
  .contact {
    label {
      font-size: 13px;
      font-weight: bold;
      text-transform: uppercase;
      padding: 14px 0 0 0;
      letter-spacing: .5px;
      cursor: default;
    }
    .input {
      padding-left: 10px;
      @include span-columns(4 of 8);
      input { width: 100%; }
    }
    .input_right { @extend .input;  @include omega; }
    .textarea {
      @include span-columns(8 of 8);
      padding-left: 10px;
      textarea { width: 100%; }
    }
  }
