.left_nav {
@include span-columns(2 of 12);
@include omega;
  @include nobullet-nospace;
  margin-top: 1em;

  z-index: 10;
  min-height: 100vh; // When using search then going through filters the left nav becomes empty
  .ie7 {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  @include media($mobile) {
    @include span-columns(12 of 12);
    min-height: 0;
    li { line-height: 32px; }
    height: auto;
    a {
      font-size: 16px !important;
    }
  }

  a {
    color: $brand-color-alpha;
    font-weight: 600;
    &:hover {
        text-decoration: underline;
    }


    

  }
  h5 {
    margin: 1em 0 1.35em 0;
    
    white-space: nowrap;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 1.5em;
  }
  ul {
    padding: 0 20px 20px 0;
    left: 0;
    li {
      padding-bottom: 10px;
      line-height: 18px;
    }
  }

  .if-mobile {

      ul {
        padding: 0 0 1em 0;
      }


      hr {
      border-bottom: 3px dashed $brand-color-beta;
        border-top: none;
      }
      }

  .product-filter {
      margin-bottom: 1em;
      min-width: 147px;
      max-width: 230px;
      position: relative;

      .clear {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 0.75em;
          text-transform: uppercase;

          &:hover {
              text-decoration: underline;
              cursor: pointer;

          }
      }

      .submenu {
          display: none;
      }

      .is-expanded {
          display: block;
      }
      .filter-header {
          margin-bottom: 0.25em;
          padding: 0;
          border-bottom: 3px solid $brand-color-alpha;
            cursor: pointer;

          .rotate-caret {
              transform: rotate(-90deg);
          }
          li {
            color: $black;
            text-transform: uppercase;
            display: inline-block;
            vertical-align: middle;
              &:nth-of-type(1){
                  font-size: 0.9em;
                  font-weight: 500;
              }

          }
      }

      .filter-list {
          padding: 0.5em;
          overflow-y: scroll;
          overflow-x:hidden;
          

          input[type="checkbox"] {
              margin-right: 0.5em;
              vertical-align: text-top;
          }

      }
  }
}