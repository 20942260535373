//
// Carousel
// --------------------------------------------------


.carousel {
  position: relative;

  line-height: 1;
}

.carousel-inner {
  overflow: hidden;
  width: 100%;
  position: relative;
}

.carousel-inner {

  > .item {
    display: none;
    position: relative;
    @include transition(.6s ease-in-out left);

  // Account for jankitude on images
    > img,
    > a > img {
      display: block;
      line-height: 1;
      margin: 0 auto;
    }
  }

  > .active,
  > .next,
  > .prev { display: block; }

  > .active {
    left: 0;
  }

  > .next,
  > .prev {
    position: absolute;
    top: 0;
    width: 100%;
  }

  > .next {
    left: 100%;
  }
  > .prev {
    left: -100%;
  }
  > .next.left,
  > .prev.right {
    left: 0;
  }

  > .active.left {
    left: -100%;
  }
  > .active.right {
    left: 100%;
  }

}

// Left/right controls for nav
// ---------------------------

.carousel-control {
  position: absolute;
  top: 40%;
  left: 15px;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  font-size: 30px;
  font-weight: 100;
  line-height: 30px;
  color: $white;
  text-align: center;
    


  @include opacity(50);

  // we can't have this transition here
  // because webkit cancels the carousel
  // animation if you trip this while
  // in the middle of another animation
  // ;_;
  // .transition(opacity .2s linear);

  // Reposition the right one
  &.right {
    left: auto;
    right: 15px;
    color: $grayMedium;

    &:hover {
        color: shade($grayMedium, 10%);
    }
    &:after {
    font-family: FontAwesome;
        content: "\f054";
    }
    
  }

  &.left {
      &:hover {
        color: shade($grayMedium, 10%);
    }
      color: $grayMedium;
      &:after {
font-family: FontAwesome;
    content: "\f053";
      }
      
  }

  // Hover/focus state
  &:hover,
  &:focus {
    color: shade($grayLight, 10%);
    text-decoration: none;
    @include opacity(90);
  }
}

// Carousel indicator pips
// -----------------------------
.carousel-indicators {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 5;
  margin: 0;
  list-style: none;

  li {
    display: block;
    float: left;
    width: 10px;
    height: 10px;
    margin-left: 5px;
    text-indent: -999px;
    background-color: #ccc;
    background-color: rgba(255,255,255,.25);
    border-radius: 5px;
  }
  .active {
    background-color: #fff;
  }
}

// Caption for text below images
// -----------------------------


.carousel {

    .item {
        .carousel-caption {
          position: absolute;
          width: 34em;
          right: 10%;
          top: 25%;
          padding: 15px;

          ul {
              margin: 0;
              list-style: none;
              color: $black;

              li {
                  width: 100%;
                  margin: 2em;

                  p {
                      color: $black;
                      line-height: 1.2;
                  }

                  button {
                      margin: 0;
                  }
              }
          }
        }

        &:nth-of-type(2){
            .carousel-caption {
                right:initial;
                left: 8%;
            }
        }
    }

}

.carousel-caption h4,
.carousel-caption p {
  color: $white;
  line-height: $baseLineHeight;
}
.carousel-caption h4 {
  margin: 0 0 5px;
}
.carousel-caption p {
  margin-bottom: 0;
}
