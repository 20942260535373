span.customSelect {
  display: inline-block;
  @include ie7-inline-block();
  padding: $paddingSmall;
  margin-bottom: 0; // For input.btn
  font-size: $baseFontSize;
  line-height: $baseLineHeight;
  vertical-align: middle;
  cursor: pointer;
  @include buttonBackground($btnBackground, $btnBackgroundHighlight, $grayDark, 0 1px 1px rgba(255,255,255,.75));
  border: 1px solid $btnBorder;
  *border: 0; // Remove the border to prevent IE7's black border on input:focus
  border-bottom-color: darken($btnBorder, 10%);
  @include border-radius($borderRadiusSmall);
  @include ie7-restore-left-whitespace(); // Give IE7 some love
  @include box-shadow(inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05));
  text-transform: uppercase;
}
span.customSelect.changed {
  background-color: #f0dea4;
}
.customSelectInner {
  background:url(#{$image-url-path}/customSelect-arrow.gif) no-repeat center right;
}

.customSelect.customSelectFocus {
    border-color: #000;
}