.verify-email {
    max-width: 1372px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    background-color: $white;
    padding: 3rem;

    @media screen and (max-width:425px) {
        padding: 2rem;
    }

    @media screen and (max-width:320px) {
        padding: 1rem;
    }

    .verify-email-wrapper {
        max-width: 600px;
        margin: 0 auto;
    }

    .verify-email-message {
        width: 100%;
        padding: 2rem;

        @media screen and (max-width:320px) {
            padding: 1rem;
        }

        form {
            width: 100%;
        }

        h5 {
            margin: 1rem 0 1rem 0;
            width: 100%;
        }

        p {
            margin-bottom: 2rem;
        }

        label {
            font-size: 0.9em;
            color: $grayDark;
            text-align: left;
        }

        input {
            margin: 0 0 1em 0;
            display: block;
            width: 100%;

            @include media(mobile) {
                width: 100%;
                max-width: initial;
            }
        }

        button {

        }

        hr {
            border: 1px solid $grayLight;
            border-top: 0;
            margin: 1rem 0;
        }
    }
}

.verify-email-confirmation {

    .email-verified {
        min-height: 100vh;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        header {
            padding: 1rem;
            text-align: center;

            img {
                max-width: 120px;
                width: 100%;
                margin: 0 auto;
            }
        }

        .wrapper {
            padding: 3rem 1rem;
            text-align: center;

            .message {
                max-width: 500px;
                width: 100%;
                text-align: center;
                margin: 0 auto;

                img {
                    margin-bottom: 2rem;
                }

                .btn-alpha {
                    margin: 0 auto;
                }
            }
        }

        footer {
            text-align: center;
            margin-top: auto;
            font-size: 0.9rem;
            font-weight: normal;

            p {
                padding: 1rem;
                font-size: 0.9rem;
                font-weight: normal;
            }
        }
    }
}

.grecaptcha-badge {
    display: none !important;
}