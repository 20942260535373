.featured {
  display: block;
    background-color: $white;
    @include box-shadow(0 0 5px rgba(0,0,0,.68), 0 0 -5px rgba(0,0,0,.68));
}

.featured-products {
    margin-top: -1em;
    margin-bottom: -1em;
    position: relative;
    left: 0;
    right: 0;
    padding: 1em;
    background-color: $white;


    .home-featured-products {
        width: 90%;
        margin: 0 auto;
        padding: 1em 0;
        #owl-home {
            /*max-width: 85%;*/
            /*margin-left: 8%;*/
            margin-top: 3em;

        }
    }
}

.featured-category-page-title {

        font-family: $sansFontFamily;
        text-align: center;

        @include media($mobile){
            margin-left: 0;
        }
        padding-left: 1em;

        line-height: 1.2;

        @include media($mobile){
            font-size: 1.5em;
        }

        a {
            color: $black;

            &:hover {
                color: $black;
            }
        }
}

.home-featured-products {
    min-height: 600px;
    @include clearfix;
}

.single-featured-product {
    width: 100%;
    margin: 0 auto 0 auto;
    position:relative;
    z-index:0;
    padding-top: 2em;
    padding-bottom: 2em;
    background: radial-gradient(lighten($black, 10%), $black);






    .featured-product-wrapper {
        display: flex;   
        position: relative;
        z-index: 0;
        justify-content: space-around;
        max-width: 1200px;
        margin: 0 auto;
        align-items: center;
        flex-wrap: wrap;

        @include media($mobile){
            width: 100%;
            flex-wrap: wrap;
        }

        @include media($tablet){
            justify-content: center;
            flex-wrap: wrap;
        }

        h1 {
           
            text-transform: uppercase;
            font-weight: 600;
            font-size: 3em;
            margin-bottom: 1em;
            line-height: 1;
            color: $brand-color-alpha;
            width: 100%;

            @include media($tablet){
                width: 100%;
            }

            @include media($mobile){
                width: 100%;
            }
        }
        
    }

    h3 {
        width: 100%;
        text-align: center;
        color: $brand-color-alpha;
    }

    .single-featured-product-background {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
    }

    .featured-product-left {
        width: 50%;
        align-self: center;
        text-align: center;
        max-width: 360px;


        @include media($mobile){
            max-width: 100%;
            width: 100%;
            margin-top: 2em;
        }

        img {
            width: 200px;
            box-shadow: 0 0 10px rgba(0,0,0,0.2);
        }
    }

    .featured-product-right {
        width: 50%;
        align-self: center;
        padding: 2em 4em;
        background-color: transparent;
        position: relative;
        text-align: center;
        
        @include media($mobile){
            width: 90%;
            margin: 0 auto;
            margin-bottom: 1em;
        }

        p {
            text-align:left;
            color: #ffffff;
            margin-bottom: 1em;
        }


        h5 {
            font-weight: 600;
            font-size: 2em;
            margin-bottom: 1em;
            color: $white;
            line-height: 1;
        }

    }

    .featured-badge {
        position: absolute;
        left: -14px;
        top: 1.5em;
        box-shadow: 2px 2px 5px rgba(0,0,0,.2);

        span {
            position: relative;
            z-index: 2;
            color: $white;
            background-color: $brand-color-gamma;
            font-weight: bold;
            display: block;
            padding: 1em;

        }

        &:after {
          content: "";
          display: block;
          position: absolute;
          bottom: -8px;
          left: 1px;
          width: 0; 
          height: 0; 
          border-left: 10px solid transparent;
          border-right: 10px solid transparent; 
          border-bottom: 10px solid darken($brand-color-gamma, 20%);
          transform: rotate(45deg);
        }
    }

}