footer {
    background-color: $brand-color-alpha;
   float: left;
    padding: 1em;
    height: auto !important;
    z-index: 2;
    margin-top: 2.8em;

    a{
                color: #000;
                text-decoration: underline;
                cursor: pointer;
                font-weight: 300;

                &:hover {
                    text-decoration: none;
                    color: #000;
                }
            }


    ul {
        margin: 0;
        list-style: none;

        li {
            display: inline-block;
            margin: 0 1em;
            a{
                color: #000;
                text-decoration: none;
                cursor: pointer;
                font-weight: 300;

                &:hover {
                    text-decoration: underline;
                    color: #000;
                }
            }
        }
    }

    hr {
        border-top: 0;
        border-bottom: 1px solid rgba(0,0,0,0.2);
    }

    p{
        margin: 0.5em 0;
        font-weight: 300;
        text-align: center;
        font-size: 0.9em;
    }
}