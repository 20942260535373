﻿.line_style_container {
    width: 33%;
    margin: 0 auto;

.line_style {
    margin-top: 1em;

    :first-child {
        font-weight: bold;
        font-size: larger;
    }
}


}

.line_style_white {
  position: absolute;
  color: white;
  margin-top: 15px;
  margin-left: 15px;
  line-height: 15px;
  font-size: 14px;

  :first-child {
    font-weight: bold;
    font-size: larger;
  }
}



.CalendarBox {
  line-height: 12px;
  position: relative;
  .style_1_gold,
  .style_2_gold,
  .style_3_gold {
    position: absolute;
    z-index: 101;
    color: #a39a4f;
    font-size: 11px;
    left: 15px;
    top: 200px;

    .address {
      position: absolute;
      left: 0px;
      top: 45px;

      span {
        display: block;
        width: 250px;
      }
    }

    p {
      margin: 0;
    }

    .name {
      font-weight: bold;
    }
  }

  .style_2_gold {
    bottom: 25px;
  }

  .style_3_gold {
    .address {
      top: auto;

      span {
        display: block;
      }
    }

    .left {
      position: absolute;
      top: -55px;
      width: 250px;
    }

    .right {
      position: absolute;
      top: 35px;
      width: 250px;
    }
  }

  .style_1_specialblack,
  .style_2_specialblack {
    position: absolute;
    z-index: 101;
    color: #000;
    font-size: 11px;
    top: 25px;
    left: 30px;

    p {
      margin: 0;
    }

    .name {
      font-weight: bold;
    }

    .address {
      position: absolute;
      top: 0;

      p {
        width: 250px;
      }
    }
  }

  .style_1_specialblack {
    .address {
      position: absolute;
      top: 35px;

      span {
        display: block;
        width: 200px;
      }
    }
  }

  .style_2_specialblack {
    .address {
      position: absolute;
      top: 45px;

      span {
        display: block;
        width: 200px;
      }
    }
  }

  .style_1_walletblack,
  .style_2_walletblack {
    height: 330px;
    width: 630px;
    position: absolute;
    z-index: 101;
    color: #000;
    left: 10px;
    bottom:80px;

    p {
      font-family: News Gothic MT, sans-serif;
      margin: 0;
    }

    .name {
      font-family: News Gothic MT Bold, sans-serif;
    }

    .address {

      p {
      }
    }
  }

  .style_1_walletblack {

    .name {
      padding-top:30px;
      font-size: 50px;
      line-height: 50px;
    }

    p {
      padding-top:5px;
      font-size: 29px;
      line-height: 29px;
    }

    .address {
      padding-top:40px;
    }
  }

  .style_2_walletblack {

    .name {
      font-size: 45px;
      line-height: 45px; 
    }

    p {
      padding-top:5px;
      font-size: 27px;
      line-height: 27px;
    }

    .address {
      padding-top:40px;
    }
  }

  .style_1_,
  .style_2_,
  .style_3_ {
    position: absolute;
    z-index: 101;
    font-size: 11px;
    line-height: 10px;
    left: 5px;
    text-align: left;
  }

  .style_1_ {
    bottom: 80px;

    p {
      margin: 0;
    }

    .name {
      font-weight: bold;
    }

    .address {
      position: absolute;
      left: 310px;
      top: 0;

      p {
        width: 250px;
      }
    }
  }

  .style_2_ {
    bottom: 70px;

    p {
      margin: 0;
    }

    .name {
      font-weight: bold;
    }

    .address {
      position: absolute;
      left: 310px;
      top: 0;

      p {
        width: 250px;
      }
    }
  }

  .style_3_ {
    bottom: 80px;
    font-size: 12px;
    section {
      p {
        margin: 0;
      }

      .name {
        font-weight: bold;
      }

      .address {
        position: absolute;

        p {
          width: 250px;
        }
      }
    }

    .address span {
      display: block;
    }

    .left {
      position: absolute;
      top: -25px;
      width: 200px;

    }

    .right {
      position: absolute;
      top: -25px;
      left: 300px;
      width: 200px;

    }
  }
}

.approval {
    width: 50%;
    margin: 0 auto;
    margin-top: 5em;
    text-align: center;

  @include media($mobile) {
    @include span-columns(12);
  }

}

.custom-entry {
    margin-bottom: 5em;

    h1 {
        font-weight: 700;
    }

}

.customization-container {
  margin: 2em 0;
  text-align: left;
  padding: 0;

    .custom-wrapper {
        width: 50%;
    }

  @include media($mobile) {
    padding: 0 10px;
  }
  @include media($tablet) {
    padding: 0 20px;
  }
}