.promo-banner {
    position: relative;
    @import url('https://fonts.googleapis.com/css?family=Oswald:400,700');
    font-family: "Oswald" !important;

    img {
        width: 100% !important;
        max-width: none !important;
    }

    .promo-banner-info {
            padding: 1em;
            opacity: 0;
            transition: all .2s ease-in-out;
            background-color: rgba(255,255,255,0.95);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            flex-wrap: wrap;

            @include media($mobile){
                position: relative;
                opacity: 1;
            }

            .column {
                width: 50%;
                text-align: center;
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                ul {
                    margin: 0;
                    list-style: none;
                    padding: 0;
                    width: 100%;
                    text-align: left;
                    max-width: 300px;
                    margin: 0 auto;

                    a {
                        color: red !important;
                        text-decoration: none;

                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    li {
                        &:nth-of-type(1){
                            margin-bottom: 0.5em;
                        }
                        &:nth-of-type(2){
                            font-weight: bold;
                        }
                    }
                }

                .btn-promo {
                    font-family: 'Oswald' !important;
                    width: 200px;
                    margin: 0 auto;
                    height: 42px;
                    background-color: transparent;
                    color: red;
                    border: 3px solid red;
                    text-transform: uppercase;
                    line-height: 37px;
                    transition: all .1s ease-in-out;
                    cursor: pointer;

                    &:hover {
                        background-color: red;
                        transition: all .1s ease-in-out;
                        color: white;
                    }
                }
            }
    }



    &:hover {
        .promo-banner-info {
            opacity: 1;
            transition: all .2s ease-in-out;
        }
    }
}