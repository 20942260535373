//
// Variables
// --------------------------------------------------




// Global values
// --------------------------------------------------
$base: #777;
$firm: #333333;
$body: #e7e7e7;

$text-color-dark:   #222;
$text-color-bright: #eee;
$sig-green: #183028;

// Grays
// -------------------------
$black: rgba(19,12,14,1) !default;
$off-black: rgba(51,51,51,1) !default;

$white: rgba(255,255,255,1) !default;
$off-white: rgba(248,248,248,1) !default;

$grayLighter: #aaa !default;
$grayLight: #f0f0f0 !default;
$gray: rgba(204,204,204,1) !default;
$grayMedium: #616161; //Medium Gray
$grayDark: rgba(153,153,153,1) !default;
$grayDarker: #777 !default;

$gold: rgba(247,211,0,1) !default;

$red: rgba(252,79,65,1) !default; //Bright Salmon


// Accent colors
// -------------------------
$blue:                  #049cdb !default;
$blueDark:              #0064cd !default;
$green:                 #46a546 !default;
//$red:                   #9d261d !default;
$yellow:                #ffcd00 !default;
$orange:                #f89406 !default;
$pink:                  #c3325f !default;
$purple:                #7a43b6 !default;


// Scaffolding
// -------------------------
$bodyBackground:        #dadada !default;
$textColor:             $grayDark !default;
$footerBackground:      #dadada !default;

// Links
// -------------------------
$linkColor:             $base !default;
$linkColorHover:        darken($linkColor, 15%) !default;


// Typography
// -------------------------
$sansFontFamily:        "JCBEuro", sans-serif !default;
$serifFontFamily:       Georgia, "Times New Roman", Times, serif !default;
$monoFontFamily:        Monaco, Menlo, Consolas, "Courier New", monospace !default;
$fontAwesome:			FontAwesome !default;

$baseFontSize:          16px !default;
$baseFontFamily:        $sansFontFamily !default;
$baseLineHeight:        20px !default;
$altFontFamily:         $serifFontFamily !default;



$headingsFontFamily:    'JCBEuro' !default; // empty to use BS default, $baseFontFamily
$headingsFontWeight:    300 !default;    // instead of browser default, bold
$headingsColor:         inherit !default; // empty to use BS default, $textColor


// Component sizing
// -------------------------
// Based on 14px font-size and 20px line-height

$fontSizeLarge:         $baseFontSize * 1.25; // ~18px
$fontSizeSmall:         $baseFontSize * 0.85; // ~12px
$fontSizeMini:          $baseFontSize * 0.75; // ~11px

$paddingLarge:          11px 19px !default; // 44px
$paddingSmall:          2px 10px !default;  // 26px
$paddingMini:           0px 6px !default;   // 22px

$baseBorderRadius:      4px !default;
$borderRadiusLarge:     6px !default;
$borderRadiusSmall:     3px !default;


// Tables
// -------------------------
$tableBackground:                   transparent !default; // overall background-color
$tableBackgroundAccent:             #f9f9f9 !default; // for striping
$tableBackgroundHover:              #f5f5f5 !default; // for hover
$tableBorder:                       #ddd !default; // table and cell border

// Buttons
// -------------------------
$btnBackground:                     $white !default;
$btnBackgroundHighlight:            darken($white, 10%) !default;
$btnBorder:                         #ccc !default;

$btnPrimaryBackground:              $linkColor !default;
$btnPrimaryBackgroundHighlight:     adjust-hue($btnPrimaryBackground, 20%) !default;

$btnInfoBackground:                 #5bc0de !default;
$btnInfoBackgroundHighlight:        #2f96b4 !default;

$btnSuccessBackground:              #62c462 !default;
$btnSuccessBackgroundHighlight:     #51a351 !default;

$btnWarningBackground:              lighten($orange, 15%) !default;
$btnWarningBackgroundHighlight:     $orange !default;

$btnDangerBackground:               #ee5f5b !default;
$btnDangerBackgroundHighlight:      #bd362f !default;

$btnInverseBackground:              #444 !default;
$btnInverseBackgroundHighlight:     $grayDarker !default;


// Forms
// -------------------------
$inputBackground:               $white !default;
$inputBorder:                   #ccc !default;
$inputBorderRadius:             $baseBorderRadius !default;
$inputDisabledBackground:       $grayLighter !default;
$formActionsBackground:         #f5f5f5 !default;
$inputHeight:                   $baseLineHeight + 10px; // base line-height + 8px vertical padding + 2px top/bottom border

// Dropdowns
// -------------------------
$dropdownBackground:            $white !default;
$dropdownBorder:                rgba(0,0,0,.2) !default;
$dropdownDividerTop:            #e5e5e5 !default;
$dropdownDividerBottom:         $white !default;

$dropdownLinkColor:             $grayDark !default;
$dropdownLinkColorHover:        $white !default;
$dropdownLinkColorActive:       $white !default;

$dropdownLinkBackgroundActive:  $linkColor !default;
$dropdownLinkBackgroundHover:   $dropdownLinkBackgroundActive !default;



// COMPONENT VARIABLES
// --------------------------------------------------


// Z-index master list
// -------------------------
// Used for a bird's eye view of components dependent on the z-axis
// Try to avoid customizing these :)
$zindexDropdown:          1000 !default;
$zindexPopover:           1010 !default;
$zindexTooltip:           1030 !default;
$zindexFixedNavbar:       1030 !default;
$zindexModalBackdrop:     1040 !default;
$zindexModal:             1050 !default;


// Sprite icons path
// -------------------------
$iconSpritePath:          "../img/glyphicons-halflings.png" !default;
$iconWhiteSpritePath:     "../img/glyphicons-halflings-white.png" !default;


// Input placeholder text color
// -------------------------
$placeholderText:         $grayLight !default;


// Hr border color
// -------------------------
$hrBorder:                $grayLighter !default;


// Horizontal forms & lists
// -------------------------
$horizontalComponentOffset:       180px !default;


// Wells
// -------------------------
$wellBackground:                  #f5f5f5 !default;


// Navbar
// -------------------------
$navbarCollapseWidth:             979px !default;
$navbarCollapseDesktopWidth:      $navbarCollapseWidth + 1;

$navbarHeight:                    40px !default;
$navbarBackgroundHighlight:       #ffffff !default;
$navbarBackground:                darken($navbarBackgroundHighlight, 5%) !default;
$navbarBorder:                    darken($navbarBackground, 12%) !default;

$navbarText:                      #777 !default;
$navbarLinkColor:                 #777 !default;
$navbarLinkColorHover:            $grayDark !default;
$navbarLinkColorActive:           $gray !default;
$navbarLinkBackgroundHover:       transparent !default;
$navbarLinkBackgroundActive:      darken($navbarBackground, 5%) !default;

$navbarBrandColor:                $navbarLinkColor !default;

// Inverted navbar
$navbarInverseBackground:                #111111 !default;
$navbarInverseBackgroundHighlight:       #222222 !default;
$navbarInverseBorder:                    #252525 !default;

$navbarInverseText:                      $grayLight !default;
$navbarInverseLinkColor:                 $grayLight !default;
$navbarInverseLinkColorHover:            $white !default;
$navbarInverseLinkColorActive:           $navbarInverseLinkColorHover !default;
$navbarInverseLinkBackgroundHover:       transparent !default;
$navbarInverseLinkBackgroundActive:      $navbarInverseBackground !default;

$navbarInverseSearchBackground:          lighten($navbarInverseBackground, 25%) !default;
$navbarInverseSearchBackgroundFocus:     $white !default;
$navbarInverseSearchBorder:              $navbarInverseBackground !default;
$navbarInverseSearchPlaceholderColor:    #ccc !default;

$navbarInverseBrandColor:                $navbarInverseLinkColor !default;


// Pagination
// -------------------------
$paginationBackground:                #fff !default;
$paginationBorder:                    #ddd !default;
$paginationActiveBackground:          #f5f5f5 !default;


// Hero unit
// -------------------------
$heroUnitBackground:              $grayLighter !default;
$heroUnitHeadingColor:            inherit !default;
$heroUnitLeadColor:               inherit !default;


// Form states and alerts
// -------------------------
$warningText:             #c09853 !default;
$warningBackground:       #fcf8e3 !default;
$warningBorder:           darken(adjust-hue($warningBackground, -10), 3%) !default;

$errorText:               #b94a48 !default;
$errorBackground:         #f2dede !default;
$errorBorder:             darken(adjust-hue($errorBackground, -10), 3%) !default;

$successText:             #468847 !default;
$successBackground:       #dff0d8 !default;
$successBorder:           darken(adjust-hue($successBackground, -10), 5%) !default;

$infoText:                #3a87ad !default;
$infoBackground:          #d9edf7 !default;
$infoBorder:              darken(adjust-hue($infoBackground, -10), 7%) !default;


// Tooltips and popovers
// -------------------------
$tooltipColor:            #fff !default;
$tooltipBackground:       #000 !default;
$tooltipArrowWidth:       5px !default;
$tooltipArrowColor:       $tooltipBackground !default;

$popoverBackground:       #fff !default;
$popoverArrowWidth:       10px !default;
$popoverArrowColor:       #fff !default;
$popoverTitleBackground:  darken($popoverBackground, 3%) !default;

// Special enhancement for popovers
$popoverArrowOuterWidth:  $popoverArrowWidth + 1 !default;
$popoverArrowOuterColor:  rgba(0,0,0,.25) !default;