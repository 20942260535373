.pagination {

  text-align: center;
  margin-bottom: 5em;

  @include media($mobile) {
    text-align: center;
    margin-top: 20px;
  }
}

.page {
    font-family:$baseFontFamily;
  font-size: 18px;
  display: inline-block;
  padding: 2px 15px;
  margin-right: 4px;
  background: #fff;
  border-radius: 2px;
  -webkit-transition: .2s ease-out;
  transition: .2s ease-out;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  overflow: hidden;
  -webkit-transition: box-shadow .2s ease-out;
  transition: box-shadow .2s ease-out;
  color: $brand-color-alpha;
  text-decoration: none;
  text-align: center;
  &:hover {
    text-decoration: none !important;
    color: #FFF;
    background-color: $brand-color-alpha;
  }
  &.active,
  &:active, &.focus, &:focus {
    box-shadow: none;
    background-color: $brand-color-alpha;
    color: #FFF;
    text-decoration: none !important;
  }
  &.disabled {
    display: none;
  }


}