#chat_tab {
  position: fixed;
  left: 0;
  bottom:50%;
  z-index:5000;
  a {
    background: url('#{$image-url-path}/livehelp-tab-icon.png') no-repeat 0 -1px;
    display: block;
    text-indent: -9999px;
    width: 38px;
    height: 119px;
    &:hover {
      background-position: -38px -1px;
    }
  }
}

