.category_products {
	background-color: $white;
    padding: 0 2em;
    width: 100%;
    position: relative;


    @include media($tablet){
        padding: 0 1em;
    }
	.right_content {
        position: relative;
        padding-top: 2em;
        width: 85%;
        display: inline-block;
        text-align: center;
		//@include media($mobile) { @include span-columns(12); }

        /*div {

            &:nth-of-type(3){
                display: none;
            }

            &:nth-of-type(6){
                display: none;
            }

        }*/

        /*padding-left: 3em;*/

		h1 {
			text-align: center;   
            font-size: 44px !important;          
            margin-bottom: 1em;
            color: $black;
		}

        h3 {
            text-align: center;
        }

		.search_title {
			h4 { display: inline; }
		}
		.displayby {
            float: left;

        a {
            padding-top: 10px;
            padding-bottom: 10px;

            &:hover {
                color: #FFF;
            }
        }
			
      @include media($mobile) {
        text-align: center;
        @include span-columns(12);
      }
      li {
        @include inline-block;
        @include pad(0 5px 0 0);
      }
    }



    .category-product-list {

            ul {
                list-style: none;
                margin: 0;

                li {
                    display: inline-block;
                    margin: 0.5em;
                }
            }

            .category-page-title {
                font-weight: 700;
            }

            .featured-products {
                margin-top: 3em;

                h2 {

                    margin-bottom: 1em;
                }
            }
        }

    @include media($mobile){

            padding-left: 0;
            width: 100%;

    }

  }

    .search-results {
        @include span-columns(10 of 12);
    }


  .sortby {
    position:absolute;
    top: 2em;
    right: 0;
   form {
    margin: 0;
   }
   @include media($mobile) {
    @include span-columns(12);
    text-align: center;
    margin: 20px 0;
  }
  .input-medium {

    text-transform: uppercase;
    text-align: left;
    height: auto;
  }
}

}
.ie7 {
  @include span-columns(9 of 12);
  @include omega;
}

