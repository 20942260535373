//
// Close icons
// --------------------------------------------------


.close {
  font-size: 18px;
  padding: 10px;
  line-height: $baseLineHeight;
  background-color: $brand-color-gamma;
  position: absolute;
  top: -1em;
  right: -1em;
  border-radius: 500px;

  i{
      color: $white;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    cursor: pointer;
    background-color: $brand-color-gamma;

    i {
        color: #FFF;
    }
  }
  a {
    color: #FFF;
    width: 100%;
    height: 100%;
    padding: 0.25em;
    &:hover {
      text-decoration: none;
    }
  }
}

// Additional properties for button version
// iOS requires the button element instead of an anchor tag.
// If you want the anchor version, it requires `href="#"`.
button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
