.cart-container {
    max-width: 75em;
    margin: 0 auto;
    padding: 2em 0 10em 0;
    width: 100%;
    position: relative;
    margin-bottom: 5em;
   text-align: center;


    @include media($tablet){
        max-width: 45em;
        padding-bottom: 5em;
    }
    @include media($mobile){
        padding-bottom: 1em;
        max-width: 22em;

    }

    #start-shopping {
        margin: 0 auto;
    }

    .cart-header {
        width: 100%;
        position: relative;
        margin-bottom: 2em;
        text-align: left;

        @include media($mobile){
            text-align: center !important;
            margin-bottom: 0.5em;
            border-bottom: 1px solid #e8e8e8;
            padding-top: 1em;
            padding-bottom: 2em;
            background-color: $white;

            p {
                margin-bottom: 2em;
            }
        }

        h1 {
            text-align: left;

            @include media($mobile){
                text-align: center !important;
            }
        }

        button {
            position: absolute;
            right: 0;
            bottom: 0;

            @include media($mobile){
                position: static;
                width: 100%;
                padding: 0.75em 5em;
                margin: 0;
            }
        }
    }

    .item-header {
        width: 100%;
        margin: 0 0 2em 0;
        padding-bottom: 1em;
        border-bottom: 1px solid #e8e8e8;
        text-align: left;

        @include media($mobile){
            display: none;
        }

        ul {
            width: 100%;
            margin: 0;
            list-style: none;

            li {
                display: inline-block;

                &:nth-of-type(1){
                    width: 59%;
                }
                &:nth-of-type(2){
                    width: 33%;
                }
                &:nth-of-type(3){
                    width: 5%;
                }
            }
        }
    }

    .cart-item {
        width: 100%;
        margin-bottom: 2em;
        padding-bottom: 1em;
        border-bottom: 1px solid #e8e8e8;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        text-align: left;

        @include media($mobile){
            background-color: $white;
            padding: 1em 0.25em;
            margin-bottom: 0.5em;
        }

        .cart-item-image, .cart-item-info, .cart-item-qty, .cart-item-price {
            display: inline-block;
            vertical-align: text-top;
        }

        .cart-item-image {
            width: 10%;

            @include media($mobile){
                width: 25%;
                order: 1;
            }

            img {
                max-width: 100px;
            }
        }

        .cart-item-info {
            width: 49%;
            padding-left: 2em;

            @include media($mobile){
                width: 74%;
                order: 2;
            }

            ul {
                margin: 0;
                list-style: none;
            }
        }

        .cart-item-qty {
            width: 29%;

            @include media($mobile){
                width: 100%;
                margin-top: 1em;
                order: 4;
            }

            ul {
                margin: 0;
                list-style: none;

                @include media($mobile){
                    text-align: center;
                }

                li {
                    margin-bottom: 0.75em;
                    display: inline-block;
                    margin-right: 1em;
                    i {
                            width: 20px;
                            cursor: pointer;
                            color: $grayDark;

                            &:hover {
                                cursor:pointer;
                                color: $brand-color-beta;
                            }
                        }

                    a {
                        cursor: pointer;
                        color: #696969;
                        text-decoration: none;
                        

                        &:hover {
                            text-decoration: underline;
                            color: #696969;
                        }

                        
                    }

                    input {
                        width: 50px;
                        padding: 0.25em;
                        border-radius: 3px;
                        border: 1px solid rgba(0,0,0,0.3);
                    }

                    @include media($mobile){
                        &:nth-of-type(1){
                            margin-left: -1em;
                            margin-bottom: 2em;
                        }

                        &:nth-of-type(2){
                            display: inline-block;
                            margin: 0 0.5em;
                        }

                        &:nth-of-type(3){
                            display: inline-block;
                            margin: 0 0.5em;
                        }
                    }
                }
            }
        }

        .cart-item-price {
            width: 10%;
            text-align: right;

            @include media($mobile){
                order: 3;
                margin-left: 33.5%;
                font-weight: 600;
                font-size: 1.2em;
            }
        }
    }

    .cart-footer {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        width: 100%;

        @include media($mobile){
            background-color: $white;
            padding: 1em 0.5em;
        }

        .apply-promo {
            flex-grow: 2;
            vertical-align: text-top;
            text-align: left;
            margin-right: auto;
            @include media($mobile){
                order: 3;
                margin-top: 5em;
                text-align: center;
            }

            p {
                font-size: 1.1em;
                font-weight: 400;
                margin-bottom: 1em;
                color: #696969;

                @include media($mobile){
                        font-size: 1em;
                    }

                span {
                    font-weight: 600;
                    font-size: 1.2em;
                    @include media($mobile){
                        font-size: 1em;
                    }
                }
                &:nth-of-type(1){
                    &:before {
                        font-family: FontAwesome;
                        content: "\f02b";
                        margin-right: 1em;
                    }
                }

                &:nth-of-type(2){
                    &:before {
                        font-family: FontAwesome;
                        content: "\f095";
                        margin-right: 1em;
                    }
                }
            }         
        }

        .cart-total {
            flex-grow: 1;
            vertical-align: text-top;
            text-align: left;
            margin-left: auto;

            h3 {
                text-align: center;
                margin-bottom: 2em;
                display: none;

                @include media($mobile){
                    display: block;
                }
            }

            ul {
                margin: 0;
                list-style: none;
                margin-bottom: 1em;

                li {
                    width: 48.5%;
                    display: inline-block;  

                    &:nth-of-type(2){
                        text-align: right;
                    }
                }
            }

           .cart-order-details {
               border-bottom: 1px dashed #e8e8e8;
               padding-bottom: 1em;
           } 

           .cart-order-total {
               li {
                   font-weight: 700;
               }
           }


        }

        .cart-checkout-buttons {
            width: 100%;
            text-align: right;
            margin-top: 2em;
            display: block;

            button, a {
                display: inline-block;
            }

            @include media($mobile) {
                text-align: center;
                margin-top: 0;

                button {
                    order: 2;
                    
                }

                a {
                    order: 1;
                    position: static;
                    width: 100%;
                    padding: 0.75em 3em;
                    height: 50px;
                    margin: 0;
                }                
            }
        }
    }
}