.product_details {

    form {
        margin: 0;
    }

    .fit-images {
        @include span-columns(4);
    }

    .chart {
        @include span-columns(8);
        @include omega;
    }

    .product_image {
        @include span-columns(6 of 12);
        padding: 10px 0 10px 5px;

        @include media($mobile) {
            padding: 0;
        }

        .tags {
            padding: 0.25rem 0;
            width: 100%;
            text-align: left;
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            align-items: center;

            span {
                align-self: center;
                padding-right: 0.25rem;

                img {
                    width: 30px;
                }
            }

            .product-tag-limitedsupply {
                color: $brand-color-alpha;
                font-weight: 500;
            }

            .product-tag-new {
                color: green;
                font-weight: 500;
            }
        }

        @include media($mobile) {
            @include span-columns(12 of 12);
            @include omega;
            text-align: center;
        }

        @include media($tablet) {
            @include span-columns(12 of 12);
            @include omega;
            text-align: center;
        }

        #multiple_product_images {
            margin-top: 20px;
            @include nobullet-nospace;
            overflow: hidden;

            #gallery-controls {
                margin: 0 auto;
                width: 206px;
                position: relative;
                top: -50px;
            }

            #prev, #next {
                display: block;
                line-height: 70px;
                padding: 5px;

                &:hover, &:focus {
                    text-decoration: none;
                    background-color: lighten($grayLight, 30%);
                }

                &:active {
                    background-color: lighten($grayLight, 20%);
                }
            }

            #prev {
                float: left;
            }

            #next {
                float: right;
            }

            #multiple_inner {
                overflow: hidden;
                width: 166px;
                position: relative;
                margin: 0 auto;

                #gallery {
                    position: relative;
                    left: 0;
                    top: 0;
                    margin: 0;

                    li {
                        float: left;
                        margin-right: 5px;

                        img {
                            height: 80px;
                            width: 80px;
                            max-width: none;
                        }

                        .ie8 img {
                            height: auto;
                            width: auto;
                        }
                    }
                }
            }
        }
    }

    .product_info {
        @include span-columns(6 of 12);
        @include omega;
        *width: 40%;
        padding-right: 5em;

        @include media($mobile) {
            @include span-columns(12 of 12);
            @include omega;
            margin-left: 0;
            padding: 1em;
        }

        @include media($tablet) {
            @include span-columns(12 of 12);
            @include omega;
            margin-left: 0;
            padding: 1em;
        }

        .product-details {
            list-style: none;
            margin: 0 0 2em 0;

            li {
                margin-bottom: 1em;

                #product-price, .saleprice {
                    display: inline-block;
                    margin-right: 0.5em;
                }



                .rating_stars {
                    margin: 0;
                }

                &:nth-of-type(1) {
                    h3 {
                        font-weight: 700;
                    }
                }

                &:nth-of-type(2) {
                    color: $grayDark;
                    margin-bottom: 2em;
                }

                &:nth-of-type(3) {
                    font-size: 3em;
                    margin-bottom: 0.75em;
                }

                &:nth-of-type(4) {
                }

                &:nth-of-type(5) {
                }

                &:nth-of-type(6) {
                }

                ul {
                    margin: 0;
                    list-style: none;

                    li {
                        color: $black !important;
                        font-size: 1em !important;
                        margin-bottom: 1em !important;


                        &:before {
                            font-family: FontAwesome;
                            content: "\f00c";
                            margin-right: 0.25em;
                        }
                    }
                }
            }

            .highlight-stock {
                font-style: italic;
                color: $brand-color-beta;
            }
        }

        hr {
            border-top: none;
            border-bottom: 1px solid $grayLight;
        }

        #size-chart-details {
            position: absolute;
            bottom: -2.5em;
            right: 0;
            color: $black;
            vertical-align: middle;

            img {
                height: 15px;
                width: auto;
                vertical-align: middle;
                margin-right: 0.25em;
            }
        }

        #fit-type {
            position: absolute;
            bottom: -2.5em;
            left: 0;
            font-style: italic;
            color: $red;
        }


        .product-options {
            list-style: none;
            position: relative;
            margin: 0;

            input {
                width: 50px;
            }

            .stock-container {
                margin: 1em;
            }

            ul {
                list-style: none;
                margin: 0;
                display: inline-block;

                li {
                    margin: 1em;
                }

                .size-style-dropdown {
                    select {
                        width: initial;
                    }
                }

                .color-style-dropdown {
                    select {
                        width: auto;
                    }
                }

                .jcb-dealer-style-dropdown {
                    display: block;

                    select {
                        width: initial;
                    }

                    .dealer-notice, .dealer-notice a {
                        display: block;
                        padding: 0.5em 0;
                        color: red;
                        font-weight: 500;
                    }
                }

                .dealer-notice {
                    display: none;
                }
            }

            .size-chart-guide {
                i {
                    margin-right: 0.5em;
                }
            }
        }

        .add-to-action {
            padding: 1em 1em 1em 0;

            @include media($mobile) {
                padding: 0;
                width: 100%;

                button, input {
                    width: 100%;
                    margin: 0 0 1em 0;
                }
            }

            @include media($tablet) {
                button, input {
                    float: none;
                    width: 100%;
                    text-align: center !important;
                    margin: 0 0 1em 0;
                }
            }

            .notify-stock {
                padding: 2rem;
                background-color: #f8f8f8;
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 1rem;

                .stock-close {
                    display: none;
                }

                p {
                    width: 100%;
                    margin-bottom: 1.5rem;
                }

                label {
                    width: 100%;
                    margin-top: 1rem;
                    display: flex;

                    span {
                        align-self: center;
                        line-height: 1.2;
                    }
                }

                input[type=checkbox] {
                    width: 22px;
                    height: 22px;
                }

                #notify-stock-email {
                    width: 100%;
                    margin-bottom: 1rem;
                    text-align: left;
                }

                #notify-stock-qty {
                    margin: 0 0 0.5rem 0;
                    max-width: 4rem;
                    width: 100%;
                }

                button {
                    flex-grow: 1;
                }

                .input-hidden {
                    display: none;
                }

                .input-hidden, select {
                    width: 100%;
                    max-width: 100px;
                    height: 56px;
                }

                #user-email {
                    font-weight: bold;
                }
                //Styled product
            }

            #notify-stock-success, #notify-stock-fail {
                display: none;
            }
        }

        #order {
            @include media($mobile) {
                overflow-x: scroll;
            }
        }

        .price, #price, #modal-price {
            margin: 10px 0;
            font-size: 24px;
            padding-bottom: 10px;

            a {
                color: #000;
            }

            .saleprice {
                display: block;
                text-decoration: line-through;
                color: $black;
            }
        }

        label,
        .custom-prompt {
            font-size: 13px;
            font-weight: bold;
            text-transform: uppercase;
            padding: 0;
            letter-spacing: .5px;
            cursor: default;
        }

        .custom-wrapper {
            margin-top: 20px;
        }

        .custom-line {
            display: block;
            width: 100% !important;
        }

        .qty {
            padding-left: 50px;
        }

        #priceTip {
            border-bottom: 1px dotted $green;
        }

        a:link {
            text-decoration: none;
        }

        .limited-supply {
            margin-left: -20px;
        }

        hr {
            background: darken($body, 5%);
        }

        .add_field {
            .btn {
                font-size: 14px;
                line-height: 18px;
            }

            .btn-add {
                margin: 0 1px 10px 0;
                font-size: 14px;
            }

            label {
                display: inline-block;
                font-weight: 700;
            }

            .size {
                margin: 0 1px 10px 0;
            }

            input[type="text"] {
                height: 28px;
            }
        }

        .custom {
            @include inline-block();
        }

        .table-head {
            font-weight: bold;
            padding: 10px;
            background: #f2f2f2;
        }

        table.multiple {
            width: 100%;
            margin-bottom: 15px;

            td {
                padding: 5px 10px;
            }
        }
    }
}

#dealer-logo-link {
    font-weight: bold;
    margin-bottom: 2em;

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: underline;
        }
    }
}

.virtualholder {
  @include span-columns(12);
  @include omega;

  label {
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 8px 0 0 0;
    letter-spacing: .5px;
    cursor: default;
  }
  span.required {
    color: $black;
    font-weight: bold;
    font-size: 18px;
  }
  .items {
    .itemholder {
      margin: 30px 0;
      border: 1px solid #eee;
      padding: 40px 0;
      img {
        &:hover,
        &:active {
          text-decoration: none;
        }
      }
      .showBtn {
        margin: 10px 20px 20px 0;
        background-color: #eee;
        display: block;
        width: 140px;
      }
    }

  }
  .customHolder {
    @include span-columns(4);
    margin-top: 10px;
    @include media($mobile) {
      @include span-columns(12);
    }
  }
  .designContainer {
    @include span-columns(4);
    @include media($mobile) {
      @include span-columns(12);
    }

    table {
      text-align: left;
      margin: 0 auto;
    }
  }
  .product_shop {
    input {
      margin: 10px;
    }
    @include span-columns(4);
    @include omega;

    text-align: center;
    @include media($mobile) {
      @include span-columns(12 of 12);
      @include omega;
    }
  }

}

.detail-reg {
  label {
    font-weight: bold;
    text-transform: uppercase;
    padding: 0;
    letter-spacing: .5px;
    cursor: default;
  }
  #ApproveImprint {
    display: inline-block;
  }
}


#modal-quantity-info {
    ul {

        li {
            margin: 1em;
        }
    }

}
.detail-style, #modal-quantity-info {
  padding: 0px;

  input {
        width: 50px;
    }

  label {
    font-weight: bold;
    text-transform: uppercase;
    padding: 0;
    letter-spacing: .5px;
    cursor: default;
  }
  .Size-style-dropdown,
  .Color-style-dropdown {
    display: inline-block;
    margin-bottom: 10px;
  }
  .Size-style-dropdown {
    display: block;
    .standout {
      margin-left: 10px;
    }
  }
  .qty-contain { display: inline-block; }
  .Size-style-dropdown,
  .Color-style-dropdown {
    width: auto;
    margin-right: 2px;
    select, .selectricWrapper { width:auto; }
  }
  .inventory {
    color: $green;
    font-weight: bold;
  }
  .stock-container {
    margin-bottom: 10px;
  }

  .size-guide-type, .size-guide {
      width: 49%;
      display: inline-block !important;
      margin-bottom: 1em !important;
  }


  .size-guide-type {
    display: inline-block;
    margin-left: 10px;
    color: $red;
    font-style: italic;
  }

  .add-to-action {
    display: block;
  }
  td {
    padding-left: 0;
    padding-right: 0;
    vertical-align: middle;
    @include media($mobile) {
      text-align: center;
    }
  }
  th {
    border: 0;
    line-height: $fontSizeSmall;
    @include media($mobile) {
      text-align: center;
    }
  }
}
.modal-qty-box {
  display: inline-block;
}

#modal-price {
    #product-price {
        display: inline-block;
    }

  .saleprice, .oldprice {
    text-decoration: line-through;
    color: red;
    display: inline-block;
    margin-left: 0.5em;
  }
}


#order-history-table {
    margin: 0 auto;
}

#customization-approval-form {
    width: 75%;
    margin: 0 auto;
    .detail-reg {
        .btn-cart {
            margin: 10px;
            vertical-align: bottom;
        }
    }
}

.customer-reviews {
    clear: both;
    padding: 3em 5em;
    @include media($mobile){
        padding: 1em 0.5em;
    }

    @include media($tablet){
        padding: 1em;
    }

    hr {
        border-top: none;
        border-bottom: 1px solid $grayLight;

    }

    h3 {
        text-align: center;
        font-weight: 700;
        margin: 2em 0;
    }
//Customer Review Accordion
.customer-review-accordion {
  $base-border-color: gainsboro !default;
  $base-line-height: 1.5em !default;
  $base-spacing: 1.5em !default;
  $dark-gray: #333 !default;
  $base-font-color: $dark-gray !default;
  $accordion-menu-border-color: $base-border-color;
  $accordion-menu-color: $base-font-color;
  $accordion-menu-border: 1px solid $accordion-menu-border-color;
  $accordion-menu-background: lighten($accordion-menu-border-color, 10%);
  $accordion-menu-hover: lighten($accordion-menu-background, 2%);
  $accordion-menu-sub-background: darken($accordion-menu-background, 5%);
  $accordion-menu-sub-inset: darken($accordion-menu-sub-background, 6%);
  $accordion-menu-sub-hover: lighten($accordion-menu-sub-background, 2%);
  $accordion-menu-list-padding: ($base-spacing / 2) $gutter;

  background-color: $accordion-menu-background;
  border: $accordion-menu-border;
  margin: 0 0 4em 0;
  padding: 2em;
  position: relative;
  z-index: 1;

  &:hover {
      #expand-review-icon {
          color:$grayDark;
      }
  }

  a {
      display: inline-block;
      width: 100%;
      height: 50px;
      color: $brand-color-gamma;

      &:hover, &:active, &:focus {
          color: $brand-color-gamma;
      }

      @include media($mobile){
        width: 100%;
        text-align: center !important;

      }

    .expand-reviews {
      position: absolute;
      bottom: 1em;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 100%;
      text-align: center;

      @include media($mobile){
          bottom: 0.5em;
      }
      
      i {
          color: $grayMedium;
          margin: 0 auto;
          text-align: center;
      }

      &:hover {

          i {
              color: $grayDark;
          }
      }


  }

  }

  p {
      
      text-align: left;
      margin: 0 0 1% 1%;
      width: 50%;
      font-weight: 500;
      text-transform: none;
      vertical-align: middle;
      font-size: 1.1em;

      @include media($mobile){
        width: 100%;
        text-align: center !important;
        margin-bottom: 1em;
      }
  }

  #review-header {
      text-align: center;
      font-size: 1.2em;
      font-weight: bold;
      text-transform: uppercase;
  }



  button {
      display: inline-block;
      position: absolute;
      top: 1.75em;
      right: 1em;
      float: right;
 
        z-index: 2;

      @include media($mobile){
          display: block;
        float: none;
        margin: 1em auto 0 auto;
        position: static;
        text-align: center;
      }

      &:after {
        content: "";
        display: table;
        clear: both;
      }
  }

  ul {
    margin: 0;
    padding: 0;
    position: relative;
  }

  li {

    list-style: none;

    &:nth-of-type(2){

    }
    > a {
      color: $accordion-menu-color;

      padding: $accordion-menu-list-padding;
      text-decoration: none;
    }

    &:last-child {
      border: 0;
    }

    .product_rating {
        width: auto;

        .customer-review-stars {
            display: inline-block;
            margin-left: 0.5em;
            vertical-align: middle;
            i {
                font-size: 1.5em;
            }
        }
    }
  }

  &:after {
   content: " ";
   display: block; 
   height: 0; 
   clear: both;
  }

  ul.submenu {
      @include media($desktop){
            display: block;
      }



      @include media($mobile){
              display: none;
      }
    clear: both;



    .customer-review-inner {
        background-color: $white;
        border-top: 1px solid gainsboro;
        border-left: 1px solid gainsboro;
        border-bottom: 1px solid gainsboro;
        border-right: 0;

        @include media($mobile){
            border-right: 1px solid gainsboro;
        }
        padding: 1em;
        margin: 2em 1em 1em 1em;
        min-height: 200px;
        overflow-y: scroll;

        .customer-info {
            background-color: #f5f5f5;
            width: 100%;
            margin-top: 1em;
            height: auto;
            padding: 1em;
            position: relative;

            &:first-child {
                margin-top: 0;
            }

            ul {
                @include span-columns(4 of 12);
                display: inline-block;
                vertical-align: top;

                li {
                    padding: 0.5em;
                    font-weight: 600;
                }

            }

            .review {
                @include span-columns(8 of 12);
                display: inline-block;

                padding: 1em;
                background-color: $white;

                h5 {
                    font-weight: bold;
                }
         
            }

            &:after {
                content: " ";
                display: block; 
                height: 0; 
                clear: both;
            }
        }

        @include media($mobile) {
            margin: 1em 0 0 0;


            .customer-info {
                background-color: transparent;
                border-bottom: 1px solid $grayLight;

                ul {
                    @include span-columns(12 of 12);
                    margin-bottom: 1em;
                }
                .review {
                    @include span-columns(12 of 12);
                }
            }
        }
    }
  }

  @inlclude media($mobile){
        .is-expanded {
    display: block;
    padding-bottom: 0;
  }

  }

}
}

//Write Review Modal

#write-review-modal {
    padding: 1em;
    top: 5em;
    
    @include media($mobile){
        width: 100% !important;
        left: 0 !important;
        top: 0 !important;
        position: absolute !important;
    }

        @include media($tablet){
        width: 95% !important;
        margin: 1em !important;
        left: 0 !important;
        top: 0 !important;
        position: absolute !important;
    }

    h3 {
        text-align: center;
        font-weight: 300;
    }

    p {
        text-align: center;

        &:first-child {
            font-weight: bold;

        }
    }

    ul {
        margin: 0;
        list-style: none;

        li {
            margin: 0.25em;
        }
        

    }

#write-review-step-1, #write-review-step-2, #write-review-step-3, #write-review-step-4, #write-review-step-5 {
    margin-bottom: 1em;
    vertical-align: text-top;
    @include media($mobile){
        clear: both;
        display: block !important;
        width: 100% !important;
    }

        @include media($tablet){
        clear: both;
        display: block !important;
        width: 100% !important;
    }
}
#write-review-step-1 {
    display: inline-block;
    width: 48%;

    li:first-child {
        font-size: 1.2em;
        color:$grayDark;
    }
}

#write-review-step-3 {
    display: block;

    li:first-child {
        font-size: 1.2em;
        color:$grayDark;
    }
}

#write-review-step-2 {
    display: inline-block;
    width: 48%;
    margin-bottom: 0;
    ul {
        li {
            display: block;
            &:first-child {
                font-size: 1.2em;
                color: $grayDark;
            }

            ul {
                li {
                    display:inline-block;

                    #state {
                        width: 3em;
                    }

                    &:first-child {
                        font-size: 1em;
                        color: #222;

                        @include media($mobile){

                            input {
                                width: 8em;
                            }
                            
                        }
                    }
                }
            }
        }
    }
}

#write-review-step-4 {
    display: block;
    width: 100%;
    input {
        width: 100%;
    }

    li:first-child {
        font-size: 1.2em;
        color:$grayDark;
    }
}

#write-review-step-5 {
    display: block;
    width: 100%;
    margin-bottom: 2em;
    textarea {
        width: 100%;
    }
    li:first-child {
        font-size: 1.2em;
        color:$grayDark;
    }
}

#write-review-buttons {
    text-align: center;
    display: block;
    width: 100%;

    button {
        display: inline-block;

    }
}

}

#submit-review {
    position: fixed;
    margin: 0 auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background-color: transparent;

    div {
        position: relative;
        top: 5em;

        width: 40em;
        background-color: #FFF;
        margin: 0 auto;
        box-shadow: 0 3px 7px rgba(0,0,0,0.3);
        text-align: center;
        padding: 2em;
    }
}

.flip {
    transform:rotate(180deg);
}

/*
This is the visible area of you carousel.
Set a width here to define how much items are visible.
The width can be either fixed in px or flexible in %.
Position must be relative!
*/
.jcarousel {
    position: relative;
    overflow: hidden;
}

/*
This is the container of the carousel items.
You must ensure that the position is relative or absolute and
that the width is big enough to contain all items.
*/
.jcarousel ul {
    width: 20000em;
    position: relative;

    /* Optional, required in this case since it's a <ul> element */
    list-style: none;
    margin: 0;
    padding: 0;
}

/*
These are the item elements. jCarousel works best, if the items
have a fixed width and height (but it's not required).
*/
.jcarousel li {
    /* Required only for block elements like <li>'s */
    float: left;
}


//Virtual Gift Card

.virtual-card-left {
    width: 48%;
    display: inline-block;
    padding-top: 2em;
    vertical-align: text-top;
}

.virtual-card-right {
    width: 50%;
    display: inline-block;
    padding-top: 2em;
    padding-left: 2em;
    text-align: left;
    vertical-align: text-top;

    .btn-neutral {
        min-width: 152px;
    }

    .item-title {
        font-weight: 700;
        text-align: left;
        font-size: 28px;
        margin-bottom: 1em;
        text-transform: uppercase;
    }

    #item-number {
        color: #84888B;

    }

    hr {
        background:#dadada;
        border-top: none;
        border-bottom: 1px solid #f6f6f6;
    }
            

}

@include media($mobile){
    .virtual-card-right, .virtual-card-left {
        width: 100%;
        padding: 0;
    }
}


.approval-paragraph {
    text-align: center !important;
}