.cross-sells-container {
    width: 100%;
    position: relative;
    background-color: $white;
    border-bottom: 8px solid #FFF;
    border-top: 8px solid #FFF;

    z-index: 1;
    float: left;
    overflow: hidden;

  .cross-sell-header {
        color: $black;
        
        background-color: $white;
        width: 100%;
        padding: 2em 0 2em 5em;
        margin-bottom: 5em;

        position: relative;

        h3 {
            border-left: 5px solid $brand-color-beta;
            padding-left: 1em;
        }
  }

  .cross-sell-items {
      text-align: center;
    display: block;
    margin-top: 35px;
    @include span-columns(12);

    @include media($mobile){

          margin-bottom: 3em;
      }

    ul {
        text-align: center;
    }

    li {

      padding: 0 15px;
      width: 25%;
      display: inline-block;
      margin: 0 auto 3em auto;
      @include media($mobile){
          display: block;
          width: 75%;

      }
      vertical-align: text-top;
      

      @include media($mobile) {
        padding: 0;
      }
      a:hover {
        text-decoration: none;
      }

      ul {
          width: 100%;

          li {
              display: block;
              margin: 0.75em;
              width: 100%;

              &:nth-of-type(2){
                  text-align: left;

                  a {
                    text-align: left;
                    color: $brand-color-gamma;
                    font-size: 1em;
                    font-weight: 700;
                  }


              }

              &:nth-of-type(3){
                  text-align: left;
              }

              &:nth-of-type(4){
                  text-align: left;
              }
    
              img {
                 

                  @include media($mobile){
                      width: 100%;
                  }
              }
          }
      }



    }
  }
}



