//Workday

$fonts-url-path: "../../fonts" !default;
$image-url-path: "../../images" !default;

$brand-color-alpha: #fcb026; //Yellow
$brand-color-beta: #000000; //Black


$brand-color-gamma: #40b4e5; //Light Blue
$brand-color-delta: #95CA55; //Green
$brand-color-epsilon: #004D7F; //Dark Blue


$grayLight: #f0f0f0; //Light Gray
$grayMedium: #616161; //Medium Gray
$grayDark: #84888B; //Dark Gray
$black: #262626; //Black

@import "../import";