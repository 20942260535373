.orders-pending-approval {
    margin-top: 3em !important;
    margin-bottom: 3em !important;

    h2 {
        text-align: center;
    }

    ul {
        width: 100%;
        margin: 0;
        list-style: none;
      

        li {
            a {
                color: #4d4d4f;

                &:hover {
                    color: #FFF;
                }
            }

            .pending-orders-header {
                background-color: #FFF !important;
                &:hover {
                    background-color: #FFF !important;
                }
            }
            
            ul {
               background-color: #eee;
               margin-bottom: 1em;

               &:hover {
                   background-color: #4d4d4f; 
               }

               

                li {
                    display: inline-block;
                    width: 24%;
                    padding: 1em;
                }
            }
        }
    }



}

.orders-pending-approval-details {
    margin: 2em auto !important;
    padding: 1em;

    h2 {
        text-align: center;
        margin-bottom: 1em;
    }

    ul {
        margin: 0;
        list-style: none;
        margin-bottom: 1em;
    }

    .date-phone-email {    

        li {
            span {
                font-weight: bold;
                
            }
        }
    }

    .buyer-info, .shipping-info, .payment-info {

        li {
            &:first-child {
                font-weight: bold;
            }
        }
    }

    .approval-comment-info {
        margin-top: 2em;
        width: 40%;
        float: right;

        @include media($mobile){
            float: none;
            width: 100%;
        }

        &:after {
            content: "";
            display: table;
            clear: both;
        }

        form {
            text-align: center;

            button {
                width: 44%;
            }
        }
    }

    textarea {
        clear: both;
        width: 100%;
        height: 100px;
    }

    .btn-approve, .btn-deny {
        display: inline-block;

        i {
            margin-right: 0.5em;
        }
    }

    hr {
        margin-top: 5em;
        margin-bottom: 3em;
        border-top: none;
        border-bottom: 1px dashed #4d4d4f;

        @include media($mobile){
            margin-top: 3em;
        }
    }

    .items-contain {
        width: 100%;

        .order-approval-details-header, .order-approval-details {

            li {
                width: 20%;
                display: inline-block;
                vertical-align: text-top;
            }

            .product {
                width: 32%;
            }
        }

        .order-approval-details-header {
            @include media($mobile){
                display: none;
            }
        }

        .order-approval-details {
            li {

                &:nth-of-type(1){
                    width: auto;
                }

                &:nth-of-type(2){
                    width: 26%;
                    padding-left: 0.5em;
                }
                span {
                    display: none;
                }

                @include media($mobile){
                    span {
                        font-weight:bold;
                        display: inline-block;
                    }

                    &:nth-of-type(2){
                        width: 70%;
                    }
                    &:nth-of-type(3){
                        display: block;
                        width: 100%;
                        margin-top: 1em;
                    }
                    &:nth-of-type(4){
                        display: block;
                        width: 100%;
                    }
                    &:nth-of-type(5){
                        display: block;
                        width: 100%;
                        padding-bottom: 1em;
                        border-bottom: 1px dashed #4d4d4f;
                    }
                }
            }
        }

    }

    .basket-total {
        float: right;
    }

    
}

.approval-response {
    margin-top: 3em !important;
    text-align: center;

    h2 {
        text-align: center;
    }


}