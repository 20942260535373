.faqs {


    h2 {
        text-align: center;
        margin: 1em 0 1em 0;
    }

    .faq-nav {
        margin: 0;
        list-style: none;
        display: inline-block;
        vertical-align: text-top;




        li {
                     
            cursor: pointer;
            font-weight: 600;
            border-bottom: 2px solid #FFF;
            color: $grayMedium;
            text-align: left;
            width: 100% !important;
            display: block;
            padding: 2em 1em 1em 1em;


            &:hover {
                color: $brand-color-gamma;
                border-bottom: 2px solid $brand-color-beta;


                ul {
                    color: $brand-color-gamma;
                    li {
                        color: $brand-color-gamma;

                        &:hover {
                            border-bottom: 0;
                        }

                    }
                }
            }
        }
    }

    .shopping-online, .orders, .shipping-returns {
        display: inline-block;
        margin: 0 auto;
        vertical-align: text-top;
      width: 100%;
        padding: 2em 3em 3em 5em;
        
        min-height: 489px;

        @include media($mobile){
            width: 100%;
            padding: 2em 1em;
        }
   

        ul {
            width: 100%;

            p {
                text-align: left;
            }
        }
    }



    .orders, .shipping-returns {
        display: none;

    }
}

.faq-nav-active {
    color: $black !important;
    border-bottom: 2px solid $brand-color-beta !important;
}

.calendar-ship-dates {
    width: 100%;
    border: 1px solid $grayDark;
    padding: 0.25em;
    margin: 0.75em 0 !important;
    li {
        display: inline-block;
        width: 49.8%;

        ul {
            display: block;
            

            li {
                display: block;
                padding: 0.5em;
                border-bottom: 1px solid $grayDark;
                width: 100%;

                &:nth-of-type(1){
                    font-weight: 700;
                    border-bottom: 2px solid $grayDark;
                }
                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
}

.expander {
  $base-font-size: 1em !default;
  $base-line-height: 1.5em !default;
  $base-spacing: 1.5em !default;
  $action-color: $brand-color-gamma !default;
  $dark-gray: #333 !default;
  $light-gray: #DDD !default;
  $base-font-color: $dark-gray !default;
  $expander-arrow-width: 0.7em;
  $expander-toggle-size: 1em;
  $expander-toggle-arrow-size: $expander-toggle-size;
  $expander-toggle-margin: 1em;

  width: 60%;
  list-style: none;
  text-align: left;
  transition: all .2s ease-in-out;

  a {
      color: $black;
      font-weight: 600;
  }

  .expander-trigger {

    color: $black;
    cursor: pointer;
    display: block;
    font-size: $expander-toggle-size;
    margin-bottom: $expander-toggle-size;
    padding-bottom: $expander-toggle-size / 4;
    text-decoration: none;
    user-select: none;
    transition: all .2s ease-in-out;

    &::before {
      content: "\25BC";
      font-size: $expander-arrow-width;
      margin-right: 0.5em;
    }
  }

  .expander-content {
      list-style: none;
      transition: all .2s ease-in-out;
      margin: 0 0 1em 1em;
  }
  .expander-content p {
    color: $base-font-color;
    line-height: $base-line-height;
  }

  .expander-hidden {
      transition: all .2s ease-in-out;
    &::before {
      content: "\25BA";
      font-size: $expander-arrow-width;
    }
  }

  .expander-hidden + li.expander-content {
    display: none;
  }
}
