#maintenanceMessage {
    position: fixed;
    width: 500px;
    right: 0;
    top: 120px;
    padding: 2em;
    background-color: red;
    color: $white;
    z-index: 1049;
    text-align: left;
    animation-name: maint;
    animation-duration: .5s;
    animation-timing-function: ease-in-out;
    transition: all .2s ease-in-out;
    color: #FFF;
    font-weight: 400;
    box-shadow: 2px 2px 2px rgba(0,0,0,0.5);
    letter-spacing: 1px;

    @include media($mobile){
        width: 300px;
    }
}

#close-maint-message {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #FFF;
    cursor: pointer;
}

#modal-important-notice {
    color: red;
    font-weight: bold;
}

#important-notice {
    color: red;
    font-weight: bold;
}
.maint-dismiss {
    right: -1000px !important;
    transition: all .2s ease-in-out !important;
}

