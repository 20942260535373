.category {
    @include span-columns(12);
    overflow: hidden;

    .bg-image {
        @include span-columns(10 of 12);
        @include omega;

        img {
            max-width: none;
        }

        @include media($mobile) {
            display: none;
        }

        @include media($mobile) {
            display: none;
        }

        @include media($tablet) {
            background-position: center top;
        }
    }
}

.category-image-links {
    .sub-category {
        float: left;
        width: 24%;
        a{
            img{
                height: 195px;
                width: 195px;
            }
        }
    }
}
