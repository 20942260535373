
.spc {
    max-width: 90em;
    min-height: 100vh;
    margin-bottom: 5em !important;

    @include media($mobile){
        max-width: 100%;
        padding: 4em 0 !important;
    }

   .checkout-progress {
       text-align: center;

       h1 {
           margin-bottom: 1em;
       }

        i {
            text-align: center;
            margin: 0 auto;
        }

        h3 {
            text-align: center;
            margin: 0 auto 1em auto;

        }

        hr {
            width: 50%;
            margin: 2em auto 2em auto;
        }

        ul {
            list-style: none;
            margin: 0 auto 1em auto;
            text-align: center;
             @include media($mobile){
                display: none;
            }

            li {
                display: inline-block;
                font-size: 2em;
                vertical-align: middle;
                }

                #check1 {
                    display: none;
                    color: $brand-color-alpha;
                }

                #check2 {
                    display: none;
                    color: $brand-color-alpha;
                }

                #check3 {
                    display: none;
                    color: $brand-color-alpha;
                }

                #step1 {
                    background-color: #eee;
                    color: $white;
                    border: 5px solid $white;
                    border-radius: 50%;
                    padding: 0.6em;
                    height: 2.1em;
                    width: 2.1em;
                }

                #step2 {
                    background-color: #eee;
                    color: $white;
                    border: 5px solid $white;
                    border-radius: 50%;
                    padding: 0.6em;
                    height: 2.1em;
                    width: 2.1em;
                }

                #step3 {
                    background-color: #eee;
                    color: $white;
                    border: 5px solid $white;
                    border-radius: 50%;
                    padding: 0.6em;
                    height: 2.1em;
                    width: 2.1em;
                }

                #step4 {
                    background-color: #eee;
                    color: $white;
                    border: 5px solid $white;
                    border-radius: 50%;
                    padding: 0.6em;
                    height: 2.1em;
                    width: 2.1em;
                }

                #line1 {
                    background-color: #eee;
                    width: 5em;
                    height: 0.25em;
                }

                #line2 {
                    background-color: #eee;
                    width: 5em;
                    height: 0.25em;
                }

                #line3 {
                    background-color: #eee;
                    width: 5em;
                    height: 0.25em;
                }
                                @include media($tablet){
                #line1, #line2, #line3 {
                width: 3em;
                }
                }

            }

           


        }
    }

.credit-card-statement {
    margin-left: 1em;
}

.line-color-success {
    background-color: $brand-color-alpha !important;
}

.step-selected {
    background-color: $brand-color-alpha !important;


}

.checkout-step {
  text-align: center;
  padding-top: 10px;
  @include media($tablet) {
    display: none;
  }

  @include media($mobile) {
    display: none;
  }

  @include nobullet-nospace;
  list-style-type: none;

  li {
    display: inline-block;
    margin-left: -27px\9;

    &:not(:first-child) {
      margin-left: -27px;
    }
  }
}

.checkout {

    width: 75%;
    margin: 0 auto;
    padding-bottom: 5em;
    @include media($tablet) {
        padding-bottom: 0;
    }

    @include media($mobile) {
        padding-bottom: 0;
    }

    .table {
        width: 100%;
    }

    .Address-Information-Control, .Shipping-Options-Control, .Payment-Method-Control, .Order-Recap {
        a {
            font-size: 0.75em;
            color: $black;

            &:hover {
                color: #000;
            }
        }
    }

    .shipping-address-info {

        .btn-neutral {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        a {
            &:hover {
                color: #FFF !important;
            }
        }
    }



  .checkout-form {
    @include span-columns(6);
    @include shift(3);
    @include media($mobile) {
      @include span-columns(12);
      @include shift(0);
    }
  }

  @include media($mobile) {
    margin-left: 0;
    margin-right: 10px;

    .form-btns {
      padding: 0;

      .btn {
        display: block;
        float: none;
        margin: 20px 0;
        font-size: 16px;
        padding: 10px;
        width: 100%;
      }
    }
  }

  .confirm-order {
    text-align: center;
    margin: 10px 0;
    border: 2px dashed $brand-color-beta;
    padding: 20px;

    h3 {
        font-weight: bold;
    }
  }

  .order-num {
    font-size: 18px;
    color: $black;
  }

  .btn-inverse {
    margin: 15px 0;
    display: block;
    float: right;
  }




  .inline-btn {
    margin: 0;
  }

  .blue_link {
    color: $blue;
  }

  .shipto {
      table {
          &:nth-of-type(1) {
              margin-bottom: 2em;
          }
      }
  }
}

// Single Page checkout
.right-column {
  float: right;
}

.shipping-address-info, #shipping-options-form, #payment-method-form, #order-recap-form, .gift-message-input {
  display: none;

}

.shipping-address-info {
    float: left;
    clear: both;
}


.spc {
  .checkout {
      margin-bottom: 5%;
    @include span-columns(8);
    @include media($tablet) {
      @include span-columns(12);
    }
    @include media($mobile) {
      @include span-columns(12);
    }
  }

  .Address-Information-Control {


    /*@include media($mobile) {
      .continue_next_step {
        display: block;
        float: none;
      }
    }*/

    

  }

  .right-column-wrapper {
      &:after {
            content: "";
            display: table;
            clear: both;
      }
  }

  .Single-Page-Checkout-Summary {
    @include span-columns(4);
    @include omega;
    @include media($tablet) {
      @include span-columns(12);
    }
    @include media($mobile) {
      @include span-columns(12);
    }

    background-color: #FFF;

    ul {list-style:none;}



    .order-summary {
        padding: 1em 2em;
        border: 1px solid #DDD;
        border-radius: 0.3em;
        margin-bottom: 1em;

        h2 {
            text-align: center;
            text-transform: none;
            font-weight: 300;
        }

        hr {
            width: 10%;
            margin: 0 auto 1em auto;
        }

        ul {
            list-style: none;
            margin-bottom: 2em;

            li {
                display: inline-block;
                padding: 0.5em;

                &:nth-of-type(odd){
                    width: 65%;

                }

                &:nth-of-type(even){
                    width: 20%;
                    float: right;

                }

                &:last-child {
                    font-weight: 700;
                    color: $red;
                }

                &:nth-last-child(2){
                    font-weight: 700;
                    color: $red;
                }
            }
        }
    }

  }

  .edit-step {
    float: right;
    margin: 0;
    cursor: pointer;
  }

  .step-1, .step-2, .step-3 {
    display: none;
  }

}

      input[type="text"], input[type="email"],input[type="password"], select{
          color: $black;
          background-color: #FFF;
          font-weight: 200;
          border-radius: 5px;
          width: 100%;
      }

      input[type="checkbox"] {
            -webkit-appearance: none;
            background-color: #fafafa;
            border: 1px solid #cacece;
            margin-right: 1em;
            padding: 15px;
            border-radius: 3px;
            display: inline-block;
            position: relative;
            vertical-align: middle;
            width: initial !important;

            &:checked {
                background-color: #e9ecee;
                border: 1px solid #adb8c0;
                box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
                color: #99a1a7;

                &:after {
                    content: '\2714';
                    font-size: 1.75em;
                    position: absolute;
                    top: -3px;
                    left: 3px;
                    color: #99a1a7;

                }
            }

            
      }


.address-info-wrapper,
.shipping-method-wrapper,
.payment-method-wrapper,
.order-recap-wrapper {
  @include span-columns(12);
  border: 1px solid #ddd;
  padding: 2.1em;
  background-color: #eee;



  form {
      padding-top: 90px;
      flex-wrap: wrap;

      @include media($mobile){
          padding-top: 60px;
          padding-bottom: 50px;
      }
  }
  h3 {
      color: #333;
      margin: 0;
      padding: 1%;

      @include media($mobile){
          font-size: 1em;
      }
      
      .trans-btn {
          margin: 0 !important;
}
  }
  .theLeft {
    @include span-columns(6);
    @include media($mobile) { @include span-columns(12); }
  }
  .theRight {
    @include span-columns(6);
    @include omega;
    @include media($mobile) {
      @include span-columns(12);
      label.checkbox { margin-left: 20px; }
    }
  }
}

.selected-step-wrapper {
    background-color: $white;


    @include media($mobile){
        padding: 1em;
        text-align: center;
    }

    h3 {
        color: $white;
        border-bottom: 1px solid #CCC;
        background-color: $brand-color-alpha;
        font-weight: 700;
        
    }
}

.address-info-wrapper {
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
    position: relative;
    
    h3 {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0.5em 1em;       
        width: 100%;

        @include media($mobile){
            font-size: 1em;
        }
        
    }

    label {
        display: inline-block;
        float: left;
        clear: left;
    }

    input,select {
        display: inline-block;
        width: 75%;
        float: left;
        clear: right;
    }

    form {

        button {
       
          clear: left;
      }

        text-align: center;
        
        ul {
            list-style: none;
            margin: 0;

      label {
          text-align: left;
          cursor: default;
          width: 10em;
          font-size: 0.9em;
          margin: 0.75em;
      }

      select {
          margin-bottom: 10px;
      }

      #name1 {
        width: 31%;

        @include media($mobile){
            width: 37%;
        }
      }

      #name2 {
        width: 10%;
      }

      #name3 {
          width: 34%;
      }

      #phone1, #phone5 {
 
      }

      #phone2, #phone6 {
        width: 27%;
      }

      #phone3, #phone7 {
          width: 27%;
      }

      #phone4, #phone8 {
          width: 10%;
      }

      #ship-to-this-address {
          margin: 1em 0;
      }

      .controls {
          margin: 0.75em;
      }

      li:nth-of-type(9){
          float: left; 
          clear: left;
      }

      



      .checkbox-label {
                padding-top: 8px;
                vertical-align: middle;
            }


        @include media($mobile){
            
            label {
                width: 100%;
            }

            input, select {
                width: 100%;

            }

            .input-mini, .input-ext {
                width: 43px !important;
            }
            input[type="checkbox"]{
                width: auto !important;
            }

            .required {
                text-align: center;
            }


        }
        }

            .column-header {
                float: left;
            } 

            .column-header, .ship-to-item {
                width: 100%;                
                padding: 1em 0.25em;

                li {
                    display: inline-block;
                    vertical-align: text-top;

                    &:nth-of-type(1){
                        width: 10%;
                    }
                    &:nth-of-type(2){
                        width: 40%;
                    }
                    &:nth-of-type(3){
                        width: 40%;
                    }

                    .customizations {
                        li {
                            width: 100%;
                            margin: 0;
                        }
                    }
                }

            }

        p {
            width: 100%;
           text-align: left;
        }

        .multiple-addresses {
            width: 100%;
            height: 200px;
            overflow-y: scroll;
            overflow-x:hidden;
            margin-bottom: 2em;
            border: 1px solid $grayLight;
            border-top-left-radius: 0.5em;
        border-bottom-left-radius: 0.5em;

            li {
                &:nth-of-type(3){
                    float: none;
                }
                &:nth-of-type(2){
                   
                    float: none;
                }
            }



            .ship-to-item {
                border-bottom: 1px dashed $grayLight;

            }
            
        }

        #edit-addresses, #shipping-addresses {
            margin: 2em 0 1em 0;
      
        }

        
    }

}


#checkout-step-1, #checkout-step-2, #checkout-step-3, #checkout-step-4 {
                text-align: center;
                margin: 2em 0 1em auto;

                &:before {
                    content: "";
                    display: table;
                    clear: both;

                }

                &:after {

                    content: "";
                    display: table;
                    clear: both;

                }
            }

.shipping-method-wrapper {

    position: relative;

    h3 {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0.5em 1em;

        width: 100%;
    }

    h4 {
        width: 100%;
        text-transform: none;
        margin-left: 0.25em;
        margin-bottom: 1em;
    }

    #packing-message-header {
        display: none;
    }

    .method {
        width: 100%;
        text-align: center;
        margin-bottom: 2em;
        background-color: #f5f5f5;
        border-radius: 0.5em;
        padding: 1em;

        ul {
            list-style: none;
            margin: 0;

            li {
                display: inline-block;
                text-align: left;

                #user-group {
                    margin-left: 1em;
                    margin-top: 0.25em;
                }

                p {
                    margin-left: 1em;
                    margin-top: 0.25em;
                }
                
                &:nth-of-type(1){
                    width: 48%;

                    @include media($mobile) {
                        width: 100%;
                    }
                }
                &:nth-of-type(2){
                    width: 48%;

                    @include media($mobile) {
                        width: 100%;
                    }
                }
            }

        }
    }

    .select-shipping-method {
        width: 100%;
        max-height: 200px;
        overflow-y: scroll;
        overflow-x: hidden;
        border: 1px solid $grayLight;
        margin-bottom: 3em;
        border-top-left-radius: 0.5em;
        border-bottom-left-radius: 0.5em;

        ul {
            margin: 0;
            list-style:none;
        }

        .method-entry {
            border-bottom: 1px dashed $grayLight;
            padding: 1em 0.25em;

            @include media($mobile){
                padding: 1em;
                li {
                    ul {
                    margin: 0;
                }
                }
                
            }

            &:last-child {
                border-bottom: none;
            }

            li {
                display: inline-block;               
                vertical-align: text-top;

                &:nth-of-type(1){
                    width: 50%;
                    text-align: left;

                    @include media($mobile){
                        width: 100%;
                        margin-bottom: 1em;
                    }
                }

                &:nth-of-type(2){
                    width: 30%;

                    @include media($mobile){
                        width: 100%;
                    }
                }

                .method-address {
                    width: 100%;
                    margin-left: 1em;
                    margin-right: 2em;

                    li {
                        width: 100%;
                        display: block;
                    }
                }

                .method-selection {
                    width: 100%;


                    li {
                        width: 100%;
                        display: block;

                        select {
                            margin-bottom: 0.5em;
                        }
                    }
                }
            }


        }



    }

    .gift-message-input {
        width: 100%;
        max-height: 200px;
        overflow-y: scroll;
        overflow-x: hidden;
        border: 1px solid $grayLight;
        margin-bottom: 3em;

        ul {
            list-style: none;
            margin: 0;          
        }

        .gift-message {
            padding: 1em 0.25em;
            border-bottom: 1px dashed $grayLight;

            &:last-child {
                border-bottom: none;
            }

            li {
                display: inline-block;
                vertical-align: text-top;

                &:nth-of-type(1){
                    width: 40%;
                    margin-right: 2em;
                    margin-left: 1em;
                }

                &:nth-of-type(2){
                    width: 40%;
                    
                }

                .gift-address {

                    li {
                        margin: 0;
                        display: block;
                        width: 100%;
                    }
                }

                .gift-message-text {

                    li {
                        margin: 0;
                        
                        width: 100%;

                        p {
                            display: inline-block;
                            margin-right: 0.25em;
                        }

                        input {
                            display: inline-block;
                     
                        }
                    }
                }
            }


        }
    }

}

.payment-method-wrapper {

    position: relative;

    h3 {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0.5em 1em;

        width: 100%;
    }

    ul {
        list-style: none;
        margin: 0;
    }

    .payment-type {
        text-align: center;
        width: 100%;
        

        li {
            display: inline-block;
            width: 30%;

            @include media($mobile){
                width: 40%;
            }
        }

        .pay-by-group {
            background-color: #eee;
        }
        .pay-by-group, .pay-by-card {
            background-color: rgba(245,245,245, 1);
            border-top-left-radius: 0.5em;
            border-top-right-radius: 0.5em;
            padding: 1em;

            li {
                width: 100%;
                display: block;
                vertical-align: text-top;
                
                
            }
            i {
                font-size: 2em;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }

    .pay-by-group-info {
        display: block;
        text-align: center;
        width: 100%;
        background-color: #f5f5f5;
        padding: 1em;
        border-radius: 0.5em;
        margin-bottom: 4em;

        li {
            display: inline-block;
            
            margin: 0.25em;

            @include media($mobile){
                display: block;
                width: 100%;
            }
        }
    }

    .pay-by-card-info {
  
        width: 100%;
        background-color: #f5f5f5;
        padding: 1em;
        border-radius: 0.5em;
        text-align: center;
               
        li {
            vertical-align: text-top;
            margin: 0.25em;

            label, input, select {
                text-align: left;
            }

            &:nth-of-type(1){
                float: left;
                width: 100%;
                clear: both;
                margin-bottom: 1em;
            }

            &:nth-of-type(2){
                display: inline-block;
              
                
            }

            &:nth-of-type(3){
                display: inline-block;
                input {
                    width: 200px;
                }
            }

            &:nth-of-type(4){
                display: inline-block;
                select {
                    width: 100px;
                }
            }

            &:nth-of-type(5){
                display: inline-block;
                select {
                    width: 75px;
                }
           
            }

            &:nth-of-type(6){
                display: inline-block;  
                input {
                    width: 75px;
                }            
            
            }
        }
    }

    .pay-by-giftcard {
        padding: 1em;
        margin-bottom: 2em;
        i {
            font-size: 1.5em;
            margin-right: 0.5em;
        }
    }

    #oa-shipping-agreement {
        text-align: right;

        p {
            text-align: left;
        }
    }

}

.order-recap-wrapper {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    position: relative;

    h3 {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0.5em 1em;
        width: 100%;

    }

    ul {
        list-style: none;
        margin: 0;
    }

    .Order-Recap {
        padding: 0 1em;
    }

    .billing-and-payment-recap {
        width: 100%;
        margin-bottom: 2em;

        li {
            display: inline-block;
            vertical-align: text-top;

            @include media($mobile){
                display: block !important;
                width: 100% !important;
            }

            &:nth-of-type(1){
                width: 15%;
                font-weight: bold;
                @include media($mobile){
                    margin-top: 1em;
                }
            }

            &:nth-of-type(2){
                width: 20%;
                @include media($mobile){
                    margin-bottom: 1em;
                }
            }

            &:nth-of-type(3){
                font-weight: bold;
                width: 15%;
            }

            &:nth-of-type(4){
                width: 20%;
            }
        }
    }

    .item-recap-header {
        width: 100%;
        padding: 0.5em;

        @include media($mobile){
            display: none;
        }

        li {
            display: inline-block;

            &:nth-of-type(1){
                min-width: 100px;
            }

            &:nth-of-type(2){
                width: 30%;
            }

            &:nth-of-type(3){
                width: 15%;
            }

            &:nth-of-type(4){
                width: 15%;
            }

            &:nth-of-type(5){
                width: 15%;
            }
        }
    }

    .order-items-recap {
        width: 100%;
        max-height: 400px;
        overflow-x: hidden;
        overflow-y: scroll;
        border: 1px solid $grayLight;

        #place-order-button {
            margin-left: auto;
        }

        .order-item {
            width: 100%;
            padding: 1em 0.5em;
            

            li {
                display: inline-block;
                padding: 0.25em;

                &:nth-of-type(1){
                    min-width: 100px;

                    @include media($mobile){
                        display: block;
                        width: 100%;
                    }
                }
                &:nth-of-type(2){
                    width: 30%;

                    @include media($mobile){
                        width: 100%;
                    }
                }
                &:nth-of-type(3){
                    width: 15%;

                    @include media($mobile){
                        display: block;
                        width: 100%;
                        &:before {
                            content: "QTY:";
                        }
                    }
                }
                &:nth-of-type(4){
                    width: 15%;

                    @include media($mobile){
                        display: block;
                        width: 100%;
                        &:before {
                            content: "Price:";
                        }
                    }
                }
                &:nth-of-type(5){
                    width: 15%;

                    @include media($mobile){
                        display: block;
                        width: 100%;
                        
                        &:before {
                            content: "Total:";
                        }
                    }
                }
            }
        }

        .order-ship {
            border-bottom: 1px dashed $grayLight;
            padding: 1em;
            &:last-child {
                border: none;
            }



            li {
                display: inline-block;
                vertical-align: text-top;

                @include media($mobile){
                    display: block !important;
                    width: 100% !important;
                }

                &:nth-of-type(1){
                    width: 15%;
                    font-weight: bold;
                }

                &:nth-of-type(2){
                    width: 40%;

                    @include media($mobile){
                        margin-bottom: 1em;
                    }
                }

                &:nth-of-type(3){
                    width: 15%;
                    font-weight: bold;
                }

                &:nth-of-type(4){
                    width: 20%;
                }
                &:nth-of-type(5){
                    font-weight: bold;
                    width: 15%;
                }
                &:nth-of-type(6){
                    width: 80%;
                }
            }

        }
    }

    .g-recaptcha {
        margin-bottom: 1rem;

        div {
            margin-left: auto;
        }
    }

}

#promo-code-entry-form {
    input {
        height: 40px;
        vertical-align: bottom;
    }

}

#accordion {

    div {
        &:nth-of-type(4){
            border-bottom-right-radius: 0.3em;
            border-bottom-left-radius: 0.3em;
        }
    }

    hr {
        border-top: none;
        border-bottom: 1px solid #CCC;
    }
    font-family: 'Lato';
    
    border-radius: 0.75em;

  


    h3 {
        text-transform: uppercase;
        font-weight: 600;
        padding: 10px;
        background-color: #D1CEC2;
        color: #130C0E;
        margin: 0;
        cursor: pointer;
        border-left: 2px solid #B2AFA8;
        border-right: 2px solid #B2AFA8;
        border-bottom: 2px solid #B2AFA8;
        transition: all .1s ease-out;

        &:nth-of-type(1){
            border-top: 2px solid #B2AFA8;
            border-top-left-radius: 0.3em;
            border-top-right-radius: 0.3em;

        }
        &:nth-of-type(4){
            border-bottom-left-radius: 0.3em;
            border-bottom-right-radius: 0.3em;
            transition: all .1s ease-out;

        }

        &:focus {
            outline: 0;

        }

        &:hover {
            background-color: #e6eef0;
      

        }

        i {
            display: none;

        }

    }

    .ui-accordion-header-active {
        background-color: #e6eef0;
        color: $black;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        transition: all .1s ease-out;

        span.step {
            color: $white !important;
            border-color: #FFF;
            background-color: $black !important;
            transition: all .1s ease-out;

        }
    }

    .ui-accordion-content-active {


        &:nth-of-type(5){
            border-bottom-left-radius: 0.3em;
            border-bottom-right-radius: 0.3em;

        }


    }

    > div {
       
        padding: 1em;
        border-left: 2px solid #B2AFA8;
        border-right: 2px solid #B2AFA8;
        border-bottom: 2px solid #B2AFA8;

    }

      span.step {
          background: #B2AFA8;
       
          border-radius: 0.8em;
          -moz-border-radius: 0.8em;
          -webkit-border-radius: 0.8em;
          color: #ffffff;
          display: inline-block;
          font-weight: bold;
          line-height: 1.6em;
          margin-right: 10px;
          text-align: center;
          width: 1.6em; 
    }


.billing-method {
    .billing-address-form {
        margin: 0.25em;


            ul {
                display: inline-block;

                li {
                    margin-right: 2em;
                    display: inline-block;
                }
            }

            select {
                width: 206px;
            }



            input[type="radio"] {
                margin: 0 0.25em 0 0;

            }
        }

    &:after {
        content: "";
        display: table;
        clear: both;
    }
    

}


.shipping-method {

        input[type="radio"]{
            vertical-align: middle;
            margin: 0.25em;
        }

    section {
        &:nth-of-type(1){
            
           &:after {
                content: "";
                display: table;
                clear: both;
           }                        
        }

        &:nth-of-type(5){

            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                display: inline-block;
                margin-right: 2em;

                li {
                    display: inline-block;
                    vertical-align: text-top;

                    &:nth-of-type(2){
                        margin-left: 1.2em;
                    }

                    

                    input[type="text"]{
                        width: 8em;
                    }

                }
            }

        }
    }

    .ship-to-billing-address {
        display: block;
    }

    .ship-to-different-address {
        display: none;
        
        ul {
            list-style: none;
            display: inline-block;

            li {
                display: inline-block;
                margin-right: 2em;

                select {
                    width: 206px;
                }
            }
        }
    }

    .ship-to-multiple-addresses {
        display: none;
        > div {
            width: 100%;
            height: 10em;
            overflow-y: scroll;
        }
        ul {
            list-style: none;
            margin: 0;
            width: 100%;
            border-bottom: 1px dashed #CCC;
            padding: 1em;

            li {
                display: inline-block;
                vertical-align: text-top;
                margin-right: 1em;

                &:nth-of-type(3){
                    float: right;
                }

                ul {
                    display: block;
                    border-bottom: none;
                    padding: 0;
                   
                    li {
                        display: block;
                        vertical-align: text-top;
                    }
                }
            }

        }
    }
}

.payment-method {

    input[type="radio"] {
        margin: 0 0.25em 0 0;

    }
    fieldset {
        border: 1px solid #CCC;
        border-radius: 0.25em;
        padding: 1em;
        margin-bottom: 1em;

        legend {
            font-size: 1em;
            border-bottom: none;
            width: auto;
            margin: 0;
        }

        ul {
            width: 33%;
            list-style: none;
            display: inline-block;
            text-align: center;

            li {
               

            }

            select {
                width: 206px;

            }
        }

    }

    #form-group-information, #form-credit-card, #form-gift-card {
        text-align: left;

        ul {
            width: 100%;
            text-align: left;
            li {
                display: inline-block;
                margin-right: 1em;
            }

        }
    }




    #form-gift-card, #form-credit-card {
        display: none;
    }

    .hide {
        opacity: 0;
        height: 0;
        transition: all 1s ease-out;
    }


}


.order-recap {
    ul {
        list-style: none;
        margin: 0 auto;

        li {
            display: inline-block;
            width: 48%;
            vertical-align: text-top;
            margin: 0.25em;
            

            fieldset {
                border: 1px solid #CCC;
                border-radius: 0.25em;
                padding: 1em;
                margin-bottom: 1em;
                height: 160px;

                legend {
                    
                    font-size: 1em;
                    border-bottom: none;
                    width: auto;
                    margin: 0;

                }
            }
        }
    }

    .product-recap {
        width: 100%;
        height: 400px;
        overflow-y: scroll;

        .product {
            border-bottom: 1px dashed #CCC;
            margin-bottom: 1em;
            padding: 1em;
            ul {
                &:nth-of-type(1){
                    li {
                        display: inline-block;

                        &:nth-of-type(1){
                            width: 10%;
                        }

                        &:nth-of-type(2){
                            width: 30%;
                        }

                        &:nth-of-type(3){
                            width: 10%;
                        }

                        &:nth-of-type(4){
                            width: 20%;
                        }

                        &:nth-of-type(5){
                            width: 20%;
                        }
                    }
                }

            &:nth-of-type(2){
                li {
                    display: inline-block;
                    width: 45%;
                }
            }
            }
        }
    }

}
.check-out-button {
    border: none;
    outline: none;
    float: right;
    background-color: #00A664;
    color: #FFF;
    font-family: Lato;
    margin: 1em;
    padding: 1em;
    transition: all .2s ease-out;
    &:hover {
        background-color: #00703C;
            transition: all .1s ease-out;
    }


}

.tooltip-item {
  $base-border-color: gainsboro !default;
  $base-border-radius: 3px !default;
  $base-line-height: 1.5em !default;
  $dark-gray: #333 !default;
  $base-font-color: $dark-gray !default;
  $tooltip-background: white;
  $tooltip-color: $base-font-color;
  $tooltip-max-width: 16em;
  $tooltip-arrow-width: 8px;
  $tooltip-shadow: 0 2px 2px silver;
  $tooltip-distance-from-item: 3em;
  $tooltip-arrow-distance-from-box: -1.3em;

  display: inline-block;
  padding: 0.5em 1em;
  position: relative;
  text-align: center;

  &:focus,
  &:hover .tooltip {
    opacity: 1;
    visibility: visible;
  }

  .tooltip {
    @include position(absolute, null 0 $tooltip-distance-from-item 0);
    background-color: $tooltip-background;
    background: #fff;
    border-radius: $base-border-radius;
    box-shadow: $tooltip-shadow;
    color: $tooltip-color;
    font-size: 0.9em; // Make sure you use -webkit-backface-visibility: hidden; on the body element to prevent 1px nudging bugs.
    line-height: 1.5em;
    margin: 0 auto;
    max-width: $tooltip-max-width;
    opacity: 0;
    padding: 1em;
    text-align: center;
    transition: all 0.2s ease-in-out;
    visibility: hidden;
    z-index: 10;

    p {
      color: $base-font-color;
      line-height: $base-line-height;
      margin: 0;
    }

    &::after {
      @include position(absolute, null 0);
      border: $tooltip-arrow-width solid transparent;
      bottom: $tooltip-arrow-distance-from-box;
      color: $tooltip-background;
      content: "1";
      font-size: 1.4em;
      margin-left: -$tooltip-arrow-width;
      text-align: center;
      text-shadow: $tooltip-shadow;
    }
  }
}

}

  .icons-ul {
        list-style:initial !important;
    }