
//Modal Defaults
.modal {
    display: none;
    width: 800px;
    position: fixed;
    left: 0;
    right: 0;
    top: 100px;
    margin: 0 auto;
    z-index: 1050;
    background-color: #FFF;
    border: 1px solid rgba(0,0,0,0.5);
    border-radius: 5px;
    box-shadow: 0 3px 7px rgba(0,0,0,0.3);
    animation: modal-fade-in;
    animation-duration: .2s;

    @include media($mobile){
        width: 325px;
        top: 30px;
        position: absolute;
    }

    @include media($tablet){
        width: 700px;
        top: 50px;
    }

    .modal-header {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding: 1em;    
        color: #3c4046;
        text-align: center;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        h2 {
            font-size: 2em;
            font-weight: 300;
            color: #3c4046;
        }

        .modal-close {
            position: absolute;
            top: -20px;
            right: -20px;
            padding: 0.20em;
            width: 2em;
            height: 2em;
            color: #FFF;
            background-color: rgba(0,0,0,1);
            border: 2px solid rgba(255,255,255,0.3);
            border-radius: 50%;
            font-size: 1.2em;
            text-align: center;
            cursor: pointer;

            &:hover {
                border: 2px solid rgba(255,255,255,1);
            }
        }        
    }

    .modal-body {
        display: flex;

        @include media($mobile){
            display: block;
        }

        .modal-body-left, .modal-body-right {
            flex-grow: 1;
            padding: 2em;
            padding-bottom: 3em;          
        }

        .modal-body-left {
            width: 60%;

            @include media($mobile){
                width: 100%;
            }
        }

        ul {
            margin: 0;
            list-style: none;
        }
    }



}

//Size-Guide Modal
#size-guide-modal {
    .modal-body{
        padding: 2em;
    }
    .modal-footer {
        padding: 1em;
        text-align: center;
    }
}

//Quickview Modal
.quick-view {

    form {
        margin: 0;

        .modal-header {
            text-align: left;

            #item-title {
                text-align: left;
            }

            #modal-rating {
                i {
                    font-size: 1.5em;
                }
            }

        #modal-product-url {
            float: none;
            color: $brand-color-alpha;
            text-decoration: none;
            position: absolute;
            right: 2em;
            bottom: 1em;

            &:hover {
                text-decoration: underline;
            }
        }       
    }

    .modal-body {


        .product_image {
            display: inline-block;
            width: 50%;
            vertical-align: text-top;
            padding: 1em 3em 1em 3em;
        }

        .product_info {
            display: inline-flex;
            flex-direction: column;
            width: 50%;
            vertical-align: text-top;
            padding: 1em 3em 3em 3em;
            

            .product-details {
                margin: 0;
                list-style: none;

                li {
                    margin-bottom: 0.5em;
                }

                #item-number {
                    font-size: 1.2em;
                    margin-bottom: 1em;
                }
               

                #modal-price {
                    font-size: 2em;
                    margin-bottom: 0.5em;
                    font-weight: 700;
                }
            }

            .product-options {
                margin: 0;
                list-style: none;

                ul {
                    margin: 0;
                    list-style: none;
                    position: relative;

                    li {
                        display: block !important;
                        margin: 0 0 0.5em 0 !important;
                        label, select, input, .highlight-stock {
                            display: inline-block;
                            vertical-align: middle;

                            &:nth-of-type(1) {
                                margin-bottom: 1em;
                            }
                            
                        }

                        label {
                            width: 50%;
                        }

                        select, input {
                            width: 100px !important;
                            box-shadow: none;
                        }
                    }
                }


            }

            .quick-view-buttons {
                width: 100%;
                margin-top: auto;

                button {
                    margin: 0;
                    width: 100%;
                }

                .btn-cart {
                    margin-top: 1em;
                    margin-bottom: 0.5em;
                }

            }
        }
    }
    }


}

//Modal Backdrop
.modal-backdrop {
    position: fixed;
    left: 0;
    right: 0;
    top:0;
    bottom: 0;
    background-color: rgba(0,0,0,0.7);
    z-index: 10;
    animation: modal-fade-in;
    animation-duration: .2s;
}

//Special Order Modal
#special-order-modal {

    .modal-body-left {
        padding-top: 3em;
        font-size: 0.95em;

        ul {
            margin: 1em;

            li {
                &:before {
                    font-family: FontAwesome;
                    content: "\f00c";
                    margin-right: 0.25em;
                }
            }
        }
    }

    .modal-body-right {
        text-align: center;

        ul {
            
            li {

                img {
                    border: 1px solid #313131;
                    border-radius: 50%;
                    width: 100%;
                    max-width: 225px;
                    height: auto;
                }
                                 
                hr {
                    width: 50%;
                    border-top: 0;
                    border-bottom: 2px solid $brand-color-alpha;
                    margin: 1em auto;
                }

                &:nth-of-type(4){
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 0.8em;
                    letter-spacing: 1px;
                }
            }
        }
    }
}

//Contact Us Modal
#contact-us-modal {

    .modal-body-left {
        text-align: center;

        form {
            margin: 0;

          fieldset {

            .input {
                width: 47%;
                margin: 0.25em;
                display: inline-block;

                @include media($tablet){
                    width: 100%;
                }

                @include media($mobile){
                    width: 100%;
                }

                label {
                    text-align: left;
                }
            }

            textarea {
                width: 100%;
                height: 150px;
                border-radius: 5px;
                resize: none;
                margin-bottom: 1em;
            }
        }
      }
    }

    .modal-body-right {
        max-width: 300px !important;
        margin: 0 auto !important;
    }
}

#point-history-modal {
        .modal-header {
        background-color: $brand-color-alpha;

        h2 {
            color: $white;
            font-weight: 400;
        }
    }
    .modal-body {
        padding: 1em 2em 2em 2em;
        flex-direction: column;

        ul {
            width: 100%;
            display: flex;
            padding: 0.25em;

            &:nth-of-type(1){
                border-bottom: 1px solid rgba(0,0,0,0.3);
                margin-bottom: 1em;
            }

            &:nth-child(2n+3){
                background-color: #f5f6f7;
            }

            li {
                display: inline-flex;
                padding: 0.25em;
                flex-direction: column;

                &:nth-of-type(1){
                    width: 20%;
                }

                &:nth-of-type(2){
                    width: 59%;
                }

                &:nth-of-type(3){
                    width: 20%;
                }

                p {
                    display: block;
                    width: 100%;
                    font-size: 0.9em;
                    font-style: italic;
                    margin-top: 0.5em;
                }
            }
        }
    }
}

#event-modal {

    .modal-header {
        background-color: $brand-color-alpha;

        h2 {
            color: $white;
            font-weight: 400;
        }
    }

    .modal-body-right {
        
        ul {
            li {
                line-height: 2;

                &:nth-of-type(1){
                    color: $brand-color-beta;
                    font-size: 1.5em;
                    font-weight: 600;
                }
                &:nth-of-type(2){

                }
                &:nth-of-type(3){

                }
                &:nth-of-type(4){
                    color: $brand-color-beta;
                    font-size: 1.5em;
                    font-weight: 600;
                }
                &:nth-of-type(5){

                }
            }
        }
    }
}

//Write Review Modal
#write-review-modal {

    .modal-body-left{
        width: 45%;
        text-align: center;
    }

    .modal-body-right{
        width: 55%;
        text-align: center;
        .input {
            text-align: left;

            &:nth-of-type(2){
                input {
                    width: 75%;
                }
                select {
                    width: 24%;
                }
            }

            textarea {
                resize: none;
                width: 100%;
                height: 150px;
                border-radius: 5px;
            }
        }

        #write-review-buttons {
            margin-top: 1em;

            button {
                width: 100%;
                margin: 0.5em 0;
            }
        }
        
    }
}

@keyframes modal-fade-in {
    from {opacity: 0;}
    to {opacity: 1;}
}

//Notify Modal

#notify {
    width: 25em;
    box-shadow: 3px 3px 5px rgba(0,0,0,0.5);
    border: 0.5px solid rgba(0,0,0,0.1);
    position: fixed;
    top: 1em;
    right: -30em;
    z-index: 1050;
    background-color: #FFF;
    transition: all .2s ease-in-out;
    padding: 0.25em;



        i {
            font-size: 0.9em;
            padding: 0.5em;
            cursor: pointer;
            position: absolute;
            top: 0.25em;
            right: 0.25em;

            &:hover {
                color: rgba(0,0,0,0.6);
            }
        }


    img {
        display: inline-block;
        vertical-align: text-top;
        margin-right: 0.75em;

        border: 0.5px solid rgba(0,0,0,0.1);
    }

    .notify-message {
        font-size: 0.9em;
        display: inline-block;
        vertical-align: text-top;
        text-align: left;
        width: 20em;

        ul {
            margin: 0;
            list-style: none;

        }
    }

    button {
        width: 49%;
        display: inline-block;
    }
}

.notify-view {

    right: 1em !important;
    transition: all .2s ease-in-out !important;
}

.notify-hide {

    right: -30em !important;
    transition: all .2s ease-in-out !important;
}

