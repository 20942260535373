.faq-h {
  margin-bottom: 35px;
}

.faq-c {

}

.faq-t {
  line-height: 1.5em;
  color: #aaa;
  font-family: sans-serif;
  float:left;
  font-weight: 700;
  padding-right: 0.8em;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  transition: all 200ms;
}

.faq-o {
  transform: rotate(-45deg);
  transform-origin: 50% 50%;
  -ms-transform: rotate(-45deg);
  -ms-transform-origin: 50% 50%;
  -webkit-transform: rotate(-45deg);
  -webkit-transform-origin: 50% 50%;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  transition: all 200ms;
}

.faq-q {
  font-weight: 500;
  cursor: pointer;
  margin: 0 0 10px;
  padding: 0 15px 10px 0;
  border-bottom: 2px solid #eee;
  display: block;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.faq-a {
  clear: both;
  color: #666;
  display: none;
  padding: 0 0 25px 1.5em;
  margin: 0;
  .size-chart {
      display: inline-block;
      padding: 5px;
      border: 1px solid #ccc;
      width: 100%;
  }
  .size-chart-note {
      display: none;
  }
  .fit-images {
    @include span-columns(4);
  }
  .chart {
    @include span-columns(8);
    @include omega;
  }
}
