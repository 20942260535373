//Service Lines

.logo-banner {
    background: $brand-color-alpha;
    position: relative;
    top: 0;
    left: 0;
    height: 25px;
    padding: 1em;
}

.body-brand-selection {

    background-repeat: repeat;
    background-color: #FFF;
}

.service-line-container {
    width: 100%;
    /*position: fixed;*/
    height: 100vh;
    z-index: 0;

    @include media($mobile){
        position: static;
        height: auto;
    }


    
    .service-line-hero {
        width: 100%;
        height: auto;

        @include media($mobile){
            display: none;
        }
    }

    h3 {
        color: $white;
        text-align: center;
        padding: 1em;
        display: block;
    }

    .de-brand-selection {
        margin: 0 auto;
        list-style: none;
        text-align: center;
        display: flex;
        padding: 2em 1em;
        background-color: transparent; 
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 0;




        hr {
            border-top: none;
            border-bottom: 1px solid #4d4d4f;
            width: 50%;
            margin: 0 auto;
        }
            @include media($tablet){
                display: block;
                width: 100%;
            }

            @include media($mobile){
                padding: 0;
                position: static;
                display: block;
                width: 90%;
                margin: 0 auto;
            }

        li {
            display: inline-flex;
            flex-direction: column;
            flex-grow: 1;
            background-color: #eee;
            text-align: center;
            padding: 0.5em;
            margin: 0.5em;
            position: relative;
            box-shadow: 5px 5px 5px rgba(0,0,0,0.5);
            

            @include media($tablet){
                
                width: 45%;
            }

            @include media($mobile){
                display: block;
                width: 100%;
                margin-left: 0;
                margin-right: 0;
            }

            div {
                width: 100%;
                background-color: #FFF;
                padding: 2em 1em;
                border: 1px solid #CCC;
                margin-bottom: auto;

                @include media($mobile){
                    padding: 0.65em;
                }

                img {
                    text-align: center;
                    display: block;
                    margin: 0 auto;
                    margin-bottom: 4em;
                    width: 100%;
                    
                    margin-bottom: auto;
                    max-height: 75px;
                    width: auto;
                }
            }

            

            button {
                width: 250px;
                background-color: #005677;
                border-color: #005677;
                margin: 0.5em auto 0 auto;

                @include media($mobile){
                    height: 35px !important;
                }

                &:hover {
                    background-color: shade(#005677, 20%);
                    border-color: shade(#005677, 20%);
                }
            }
        }
    }

    .top-bar-landing {
        width: 100%;
        position: relative;
        top: 0;
        left: 0;
        height: 15px;
        background-color: #F05D21;

        @include media($mobile){
            display: none;
        }
    }



}


//Sign In Page
.login-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #4d4d4f;




    .login-container {
        max-width: 40em;
        text-align: center;
        padding-top: 2em;
        margin: 0 auto;
        @include media($mobile){
                    padding-top: 4em;
                }

        .login-card{
            padding: 0.75em;
            border: 2px solid $grayLight;
            background-color: #FFF;
            text-align: center;
            animation: fade-in;
            animation: fade-in;
            animation-duration: 1s;
            animation-timing-function: ease-out;

            .border {
                padding: 2em 1em;
                border: 1px solid $grayLight;

                @include media($mobile){
                    padding: 3em 1em;
                }
            
            hr {
                border-top: none;
                border-bottom: 1px solid $grayLight;
                margin: 1em auto 2em auto;
                text-align: center;
                width: 50%;
            }

            img {

                margin: 1em auto 1em auto;
                width: 300px;
                height: auto;
            }


            h3 {
                margin-bottom: 0.75em;
                text-align: center;
                font-weight: 500;
            }

            form {
                display: inline;

                input {
                    display: inline-block;
                    height: 42px;
                    &:focus {
                        outline: 0;

                    }
                }
            }
        }
        }
    }
}

@keyframes fade-in {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes expand {
    from {
        width: 0;
        opacity: 0;

    }
    to {
        width: 100%;
        opacity: 1;

    }
}

@keyframes drop-down {
    from {
        top: -100px;
    }
    to {
        top: 15px;
    }
}
