//colors
$white: #fff;
$black: #ff0000;
$black_40: rgba(0, 0, 0, 0.4);
$color_shark_approx: #252525;
$white_100: rgba(255, 255, 255, 1);
$black: #000;
$color_celeste_approx: #ccc;
$black_50: rgba(0, 0, 0, 0.5);
$color_red_approx: #df0404;
$white_50: rgba(255, 255, 255, 0.5);
$color_forest_green_approx: rgb(18, 150, 18);
$color_viking_approx: #4dc8d3;
$color_amaranth_approx: #ea2e49;

//fonts
$font_0: FontAwesome;

//urls

.tags {
    font-size: 0.8rem;
    padding: 0.25rem 0;
    width: 100%;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    height: 22px;
    align-content: center;
    align-items: center;

    span {
        align-self: center;
        padding-right: 0.25rem;

        img {
            width: 20px;
        }
    }

    .product-tag-limitedsupply {
        color: blue;
        font-weight: 500;
    }

    .product-tag-new {
        color: green;
        font-weight: 500;
    }
}

.scrolling-category {
    .category-page-title {
        font-size: 1em;
        text-transform: uppercase;

        a {
            &:hover {
                color: shade($brand-color-beta, 10%);
            }
        }
    }
}


.product_grid {
  background-color: $white;

  img {
    max-width: 100%;
    height: auto;
  }
}

.product_item {
  display: inline-block;
  padding: 10px;
  position: relative;
  height: 500px;
  @include media($mobile) {
    height: 100%;

  }
}

.product_image {
  position: relative;
  overflow: hidden;

  a {
    display: block;
  }

  img {
    display: block;
  }

  &:hover .product_buttons {
    bottom: 0;
  }
}

.product_buttons {
  position: absolute;
  bottom: -40%;
  left: 0;
  width: 100%;
  text-align: center;
  transition: all .35s ease;
  background-color: rgba(0,0,0,0.85);

  .quick_view, .save-item {
    display: inline-block;
    padding: 0 10px;
    cursor: pointer;
    @include media($w-desktop) {
      padding-bottom: 20px;
    }

    h6 {
      margin: 6px 0 0;
      text-align: center;
      color: $white;
      padding: 8px 0;
      font-size: 1em;
      font-weight: bold;

      &:before {
        font-family: $font_0;
        padding: 0 5px;
        font-weight: normal;
        font-size: 21px;
      }

      &:hover {
          color: #FFF;
      }
    }
  }

  .quick_view {
      @include media($mobile){
          display: none;
      }

      @include media($tablet){
          display: none;
      }
  }

  .quick_view h6 {
    &:before {
      content: "\f06e";
    }
  }

  .save-item h6 {
    &:before {
      content: "\F08A";
    }
  }
}

.product_title {

  width: 100%;
  font-size: 1em;
  font-weight: 600;
  line-height: 1.2em;
  margin: 1em 0 0.5em 0;

}

.price_old {
  color: $color_amaranth_approx;
  text-decoration: line-through;
}

.product_rating {
    margin: 0.25em 0.25em 0.25em 0;
    width: 50%;
    /*display: inline-block;*/
}

span.rating_stars, span.rating_stars span {
   i {
       font-size: 1em;
       color: gold;
       margin-right: 0.125em;

       @include media($mobile){
           font-size: 1em;
       }
   }
}



@media only screen and (min-width : 320px) {
  .product_item {
    width: 100%;
    margin: 0;
    overflow: hidden;


  }




  .product_image {

    width: 100px;

    .product_buttons {
      display: none;
    }
  }

  ul.product_list {
    margin: 0;
    border: 0;
  }

  .product_price span {
    font-size: 1em;


  }

  .product_values .product_buttons {
    position: relative;
    text-align: left;
    float: left;
    margin-top: 7px;

    button {
      color: $color_shark_approx;
      background: $white_100;
      font-size: 1em;
      //Instead of the line below you could use @includeborder-radius($radius, $vertical-radius)
      border-radius: 50%;
      width: 40px;
      height: 40px;
      border: 1px solid $black;
    }
  }
}

@media only screen and (min-width : 480px) {
  .product_item {
    width: 49%;
    margin: 0;
    vertical-align: text-top;
    text-align: left;
  }

  .product_image {

    width: 100%;
    text-align: left;

    img {
      max-height: 100%;
      display: inline-block;
    }

    .product_buttons {
      display: block;
    }
  }

  /*.product_price {
    float: left;
    width: 60%;
  }*/

  .product_rating {

  }

  .product_values {
     a {
         color: $brand-color-alpha;
         display: block;
     }
  }

  ul.product_list {
    margin: 1%;
  }

  .product_values .product_buttons {
    display: none;
  }
}

@media only screen and (min-width: 678px) {
  .product_item {
    width: 49%;
  }

}

@media only screen and (min-width : 768px) {


}

@media only screen and (min-width : 992px) {
  .product_item {
    width: 24.25%;
  }


}

@media only screen and (min-width : 1200px) {

}

@media only screen and (min-width : 1400px) {
  .product_item {
    width: 19.5%;
    height: 430px;
  }
}

@media only screen and (min-width : 2000px) {
  .product_item {
    height: 530px;
  }
}
@media only screen and (max-width : 1200px) {
}

@media only screen and (max-width : 992px) {


}

@media only screen and (max-width : 768px) {


}

@media only screen and (max-width : 480px) {
  .product_values {
    float: left;
    width: calc(100% - 100px);
    padding: 0 10px;

    .product_buttons {
      position: relative;
      text-align: left;
      float: left;
      margin-top: 7px;
    }
  }

  .product_rating {

  }

  .product_image {
    float: left;
    width: 100px;

    .product_buttons {
      display: none;
    }
  }

  .product_title {
    border: none;
  }
}

@media only screen and (max-width : 318px) {
  .product_sale {
    display: none;
  }

  .product_image {
    height: auto;
    width: 100%;

    .product_buttons {
      display: block;
    }
  }

  .product_values {
    width: 100%;
    padding: 0;
    margin: 5px 0;

    .product_buttons {
      display: none;
    }
  }

  .product_rating {

  }

  .product_title {
    border-top: 0;
  }

  .product_title h5 {
    margin: auto;
    line-height: 1;
  }
}

ul.product_list {
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
  display: block;
  float: left;
  list-style-type: none;
  margin: 25px 0 25px 0;
  padding: 25px 0 0 0;
  width: 100%;
}

.product_grid a {
  text-decoration: none;
}

.product_price a {
  color: $black;
}

.quick_view {

    width: 100%;

    a {
        width: 100%;
        position: relative;
        z-index: 5;
    }
}

