.my-account-left { // new top navigation for my account section
    width: 25%;
    text-align: center;
    margin-bottom: 4em;
    display: inline-block;
    vertical-align: text-top;

    @include media($tablet){
        width: 100%;
    }
    @include media($mobile){
        width: 100%;
    }


    h4 {
        font-weight: 600;
        color: $brand-color-beta;
        text-transform: uppercase;
        font-style:normal;
    }



    .my-account-navigation {
        margin: 0;
        list-style: none;
        text-align: center;
        width: 100%;
        z-index: 1;
        

        

        li {
            
            border-left: 4px solid $grayMedium;
            opacity: 1;
            transition: all .2s ease-in-out;
            padding: 1em 0;
            cursor: pointer;
            text-transform: uppercase;
            text-align: left;

            @include media($mobile){
                display: inline-block;
                border: 0 !important;
            }

            @include media($tablet){
                display: inline-block;
                border: 0 !important;
                
            }

            a {
                color: $grayDark;
                padding: 2em;
                font-weight: 600;

                @include media($tablet){
                    padding: 1em;
                }
                @include media($mobile){
                    padding: 1em;
                }

                ul {
                    vertical-align: top;
                    margin: 2em;

                    li {
                        width: 100%;
                        display: block;
                        border-bottom: none;

                        &:nth-of-type(1){
                            margin-bottom: 1em;
                        }
                        &:nth-of-type(2){
                            font-weight:600;
                        }

                        &:hover {
                            border: 0;
                        }

                        img {
                            -webkit-filter: saturate(10%);
                            filter: saturate(10%);
                        }
                        

                    }
                }
                
                               
            }

            &:hover {
                
                    opacity: 1;
                    transition: all .2s ease-in-out;
                    

                    a {
                        color: $brand-color-alpha;
                    }

                    img {
                            -webkit-filter: saturate(100%);
                            filter: saturate(100%);
                    }
                }
        }
    }

}

.my-account-right {
    width: 70%;
    margin-bottom: 100px;
    display: inline-block;
    vertical-align: text-top;

    @include media($tablet) {
        width: 100%;
    }

    @include media($mobile) {
        width: 100%;
    }

    h1 {
        margin: 0 0 1em 0;
        font-weight: 300;
        font-size: 1.5em;
        color: $black;
    }

    p {
        text-align: left;
    }

    .list-toggle {
        width: 100%;
        text-align: right;
        padding: 1em 3em;
        font-size: 2em;

        @include media($mobile) {
            text-align: center;
        }

        @include media($tablet) {
            text-align: center;
        }

        .fa-th-large {
            display: none;
        }

        i {
            color: $grayDark;
            cursor: pointer;
            transition: all .2s ease-in-out;
            padding: 0 1em 1em 1em;

            &:hover {
                color: $brand-color-gamma;
                transition: all .2s ease-in-out;
            }
        }
    }
    /*#frmAddress {
      .controls {
          margin-bottom: 0.5em;
          display: inline-block;
          width: 100%;

          label, input, select {
              display: inline-block;
          }

          label {
              width: 15%;

          }

          input, select {
              width: 80%;
          }
      }


  }*/
    .entry {
        margin: 0 0 2em 0;
        text-align: left;

        h1 {
            font-weight: 300;
            color: $brand-color-beta;
            text-align: left;
        }

        p {
            margin: 0 !important;
            float: none;
            text-align: left;
            width: 80%;
        }

        @include media($tablet) {

            p {
                width: 100%;
            }
        }
    }



    .order-history {
        clear: both;

        a {
            color: $black;

            div {
                background-color: $white;
                border: 1px solid $grayLight;
                border-bottom: 0;
            }

            &:hover {
                text-decoration: none;

                div {
                    background-color: $grayLight;
                }
            }

            &:last-child {
                div {
                    border-bottom: 1px solid $grayLight;
                }
            }
        }

        ul {
            list-style: none;
            clear: both;


            li {
                display: inline-block;
                padding: 0.5em;

                &:nth-of-type(1) {
                    min-width: 20%;
                }

                &:nth-of-type(2) {
                    min-width: 20%;
                }

                &:nth-of-type(3) {
                    min-width: 20%;
                }

                &:nth-of-type(4) {
                    min-width: 20%;
                }

                a {
                    color: $black;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .order-labels {
                    display: none;
                }
            }
        }

        @include media($mobile) {
            > ul {
                display: none;
            }

            ul {
                margin: 1em 0;

                li {
                    display: block;
                    padding: 1em;
                    width: 100%;

                    &:first-of-type {
                        font-weight: 700;
                        background-color: shade(#eee, 10%);
                    }

                    .order-labels {
                        display: inline-block;
                    }
                }
            }
        }
    }

    .returns {
        .my-account-right {
            margin: 0 auto 100px auto;

            p {
                text-align: left;
            }

            legend {
                font-size: unset;
                border: 0;
            }

            input[type=radio] {
                margin: 0 5px 0 0;
                transform: scale(1.2);
            }

            div[data-field=Company] {
                display: none;
            }
        }
    }

    .update-account {
        text-align: center;
        max-width: 50em;
        margin: 0 auto;

        form {
            width: 100%;
            margin: 0 auto;
        }

        label {
            text-align: left;
            cursor: default;
            width: 100%;
            display: inline-block;
            width: 15%;
            margin-top: 0.5em;
        }

        .checkbox {
            width: 100%;
        }

        #name1 {
            width: 43%;
            display: inline-block;

            @include media($mobile) {
                width: 41%;
            }
        }

        #name2 {
            width: 10%;
            display: inline-block;
        }

        #name3 {
            width: 45%;
            display: inline-block;
        }

        #phone1 {
        }

        #phone2 {
            width: 28%;
        }

        #phone3 {
            width: 28%;
        }

        #phone4 {
            width: 10%;
        }

        .controls {
            margin: 0.5em;
            display: inline-block;
            width: 80%;
            font-weight: 200;
        }

        input[type="text"], select {
            color: $black;
            background-color: #FFF;
            border-color: #E5E5E4;
            font-weight: 200;
            width: 100%;
        }

        input[type="checkbox"] {
            -webkit-appearance: none;
            background-color: #fafafa;
            border: 1px solid #cacece;
            margin-right: 1em;
            padding: 15px;
            border-radius: 3px;
            display: inline-block;
            position: relative;
            vertical-align: middle;

            &:checked {
                background-color: #e9ecee;
                border: 1px solid #adb8c0;
                box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
                color: #99a1a7;

                &:after {
                    content: '\2714';
                    font-size: 1.75em;
                    position: absolute;
                    top: -3px;
                    left: 3px;
                    color: #99a1a7;
                }
            }
        }

        .checkbox-label {
            padding-top: 8px;
            vertical-align: middle;
            font-weight: 200;
        }

        .itty-bitty {
        }

        #update-information, #cancel {
            display: inline-block;
            vertical-align: middle;
            margin-bottom: 5em;
            margin-top: 2em;
        }

        @include media($mobile) {

            #update-information, #cancel {
                display: block;
                width: 100%;
                float: none;
                margin-bottom: 1em;
            }

            label {
                width: 100%;
            }

            input, select {
                width: 100%;
            }

            .input-mini, .input-ext {
            }

            input[type="checkbox"] {
                width: auto !important;
            }

            .required {
                text-align: center;
            }
        }
    }

    .account-updated {
        p {
            text-align: center;
        }
    }

    .address-book {
        text-align: center;

        .fa-map-marker {
            font-size: 2em;
            color: #fc4f41;
            margin-bottom: 0.5em;
            display: inline-block;
            margin-right: 0.5em;
        }

        ul {
            list-style: none;
            margin: 0 auto;
            text-align: left;

            .address-entry {
                @include media($mobile) {
                    height: 150px;
                }
            }
            //Address Cards
            li {
                vertical-align: text-top;
                width: 100%;
                font-weight: 200;
                position: relative;
                padding: 1em;
                margin: 0.5em;
                transition: all .2s ease-in;
                border: 1px solid #e5e5e4;
                border-radius: 2px;
                background-color: #FFF;
                z-index: 0;

                @include media($mobile) {
                    width: 100%;
                    margin: 2em 0 0 0;
                }

                @include media($tablet) {
                    width: 100%;
                    margin-top: 2em;
                }



                .address-tab {
                    width: 40%;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    height: 20px;
                    position: absolute;
                    right: 0;
                    top: -1.25em;
                    background-color: $brand-color-gamma;
                }

                ul {
                    width: 100%;


                    li {
                        display: inline-block;
                        width: initial;
                        height: initial;
                        padding: initial;
                        margin: initial;
                        text-align: left;
                        border: none;

                        &:hover {
                            box-shadow: none;
                            transform: none;
                        }



                        &:nth-of-type(1) {
                            width: auto;
                            display: inline-block;
                            color: $black;
                            font-weight: 600;
                            padding-bottom: 0.5em;
                            text-align: center;
                            margin-bottom: 0.75em;
                            border-bottom: 1px dashed $grayMedium;
                        }
                    }
                }
            }

            a {
                position: absolute;
                bottom: 1.1em;
                right: 1em;
                color: $grayDark;

                &:hover {
                    color: $brand-color-beta;
                }

                i {
                    font-size: 1em;
                }


                &:nth-of-type(1) {
                    bottom: 1em;
                }

                &:nth-of-type(2) {
                    right: 3em;
                }
            }
        }

        button {
            text-align: center;
            margin-top: 2em;
        }
    }

    .new-address {
        text-align: center;
        max-width: 50em;
        margin: 0 auto;

        form {
            width: 100%;
            margin: 0 auto;
        }

        label {
            text-align: left;
            cursor: default;
            width: 100%;
            font-size: 0.9em;
            display: inline-block;
            width: 15%;
            margin-top: 0.5em;
        }

        #name1 {
            width: 43%;
            display: inline-block;
        }

        #name2 {
            width: 10%;
            display: inline-block;
        }

        #name3 {
            width: 45%;
            display: inline-block;
        }

        #phone1 {
        }

        #phone2 {
            width: 28%;
        }

        #phone3 {
            width: 28%;
        }

        #phone4 {
            width: 10%;
        }

        .controls {
            margin: 0.5em;
            display: inline-block;
            width: 80%;
        }



        input[type="text"], select {
            color: $black;
            background-color: #FFF;
            height: 44px;
            width: 100%;
        }

        input[type="checkbox"] {
            -webkit-appearance: none;
            background-color: #fafafa;
            border: 1px solid #cacece;
            margin-right: 1em;
            padding: 15px;
            border-radius: 3px;
            display: inline-block;
            position: relative;
            vertical-align: middle;

            &:checked {
                background-color: #e9ecee;
                border: 1px solid #adb8c0;
                box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
                color: #99a1a7;

                &:after {
                    content: '\2714';
                    font-size: 1.75em;
                    position: absolute;
                    top: -3px;
                    left: 3px;
                    color: #99a1a7;
                }
            }
        }

        .checkbox-label {
            padding-top: 8px;
            vertical-align: middle;
        }

        .itty-bitty {
        }

        #save-address, #cancel {
            display: inline-block;
            vertical-align: middle;
            margin-bottom: 5em;
            margin-top: 2em;
        }

        @include media($mobile) {

            #update-information, #cancel {
                display: block;
                width: 100%;
                float: none;
                margin-bottom: 1em;
            }

            label {
                width: 100%;
            }

            input, select {
                width: 100%;
            }

            .input-mini, .input-ext {
                width: 43px !important;
            }

            input[type="checkbox"] {
                width: auto !important;
            }

            .required {
                text-align: center;
            }
        }
    }

    .wishlist-container {
        clear: both;
        text-align: center;

        .wishlist-row {
            max-width: 76em;
            margin: 0 auto;
            width: 100%;
            background-color: #FFF;
            padding: 1em;
            margin-bottom: 1em;
            border-bottom: 1px solid $grayLight;
            text-align: left !important;


            ul {
                width: 100%;
                list-style: none;
                display: inline;

                &:after {
                    content: "";
                    display: table;
                    clear: both;
                }

                li {
                    display: inline-block;
                    vertical-align: text-top;
                    padding: 0.25em;
                    font-weight: 200;

                    input {
                        height: auto;
                        width: 50px;
                    }

                    &:after {
                        content: "";
                        display: table;
                        clear: both;
                    }

                    a {
                        color: $black;
                        font-weight: 600;

                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    &:nth-of-type(1) { //Product Image
                        width: 100px;
                    }

                    &:nth-of-type(2) { //Product Description
                        width: 40%;

                        @include media($mobile) {
                            width: 25%;
                        }
                    }

                    &:nth-of-type(3) { //Quantity
                        color: $grayDark;
                        float: right;
                        font-size: 1em;
                        margin-left: 1em;
                        cursor: pointer;
                        transition: all .2s ease-out;
                        margin-top: 0.35em;

                        i {
                            color: $grayDark;
                            cursor: pointer;
                            transition: all .2s ease-out;

                            &:hover {
                                color: $brand-color-beta;
                                transition: all .2s ease-out;
                            }
                        }
                    }

                    &:nth-of-type(4) { //Refresh
                        color: $grayDark;
                        float: right;
                        font-size: 1em;
                        margin-left: 1em;
                        cursor: pointer;
                        transition: all .2s ease-out;
                        margin-top: 0.35em;

                        i {
                            color: $grayDark;
                            cursor: pointer;
                            transition: all .2s ease-out;

                            &:hover {
                                color: $brand-color-beta;
                                transition: all .2s ease-out;
                            }
                        }
                    }

                    &:nth-of-type(5) { //Trash
                        color: $grayDark;
                        float: right;
                        font-size: 1em;
                        margin-left: 1em;
                        cursor: pointer;
                        transition: all .2s ease-out;
                        margin-top: 0.35em;

                        &:hover {
                            color: $brand-color-beta;
                            transition: all .2s ease-out;
                        }
                    }

                    &:nth-of-type(6) { //Add to Cart
                        color: $grayDark;
                        float: right;
                        font-size: 1em;
                        margin-left: 1em;
                        cursor: pointer;
                        transition: all .2s ease-out;

                        &:hover {
                            color: $brand-color-beta;
                            transition: all .2s ease-out;
                        }
                    }
                }

                @include media($tablet) {
                    li {
                        &:nth-of-type(1) {
                            margin-bottom: 1em;
                        }

                        &:nth-of-type(2) {
                            width: 40%;
                            margin-bottom: 1em;
                        }

                        &:nth-of-type(3) {
                        }

                        &:nth-of-type(4) {
                        }

                        &:nth-of-type(5) {
                            &:after {
                                visibility: hidden;
                                display: block;
                                font-size: 0;
                                content: " ";
                                clear: both;
                                height: 0;
                            }
                        }
                    }
                }

                @include media($mobile) {
                    li {

                        &:nth-of-type(1) {
                            float: left;
                            display: inline-block;
                            margin-bottom: 1em;
                        }

                        &:nth-of-type(2) {
                            float: left;
                            display: inline-block;
                            width: 65%;
                            margin-bottom: 1em;
                        }

                        &:nth-of-type(3) {
                            clear: left;
                            margin: 0;
                            width: 23.5%;
                        }

                        &:nth-of-type(4) {
                            margin: 0;
                            width: 23.5%;
                        }

                        &:nth-of-type(5) {
                            margin: 0;
                            margin-left: 0.5em;
                            width: 23.5%;
                        }

                        &:nth-of-type(6) {
                            margin: 0;
                            width: 23.5%;
                        }
                    }
                }
            }
        }

        .wishlist-card {
            width: 20%;
            display: inline-block;
            margin: 1em;
            vertical-align: text-top;
            height: 500px;
            border: 1px solid $grayMedium;
            position: relative;
            text-align: center;
            transition: all .2s ease-in;
            background-color: #FFF;

            @include media($tablet) {
                width: 45%;
                height: 600px;
            }

            @include media($mobile) {
                width: 100%;
                margin: 0 0 1em 0;
                height: 700px;
            }

            @include media($desktop) {
                &:hover {
                    transform: scale(1.2);
                    box-shadow: 0px 0px 20px #CCC;
                    cursor: pointer;
                    transition: all .2s ease-in;
                    z-index: 3;
                }
            }




            ul {
                margin: 0;
                list-style: none;
                text-align: center;
                height: 500px;
                position: relative;

                @include media($tablet) {
                    height: 600px;
                }


                li {
                    padding: 0 1em 0 1em;
                    margin-bottom: 1em;
                    margin-top: 5em;
                    bottom: 0;
                    text-align: center;
                    vertical-align: middle;

                    &:nth-of-type(1) { //Image
                        padding: 0;
                        position: initial;
                        margin-top: 0;
                    }

                    &:nth-of-type(2) { //Product Title / Price & Item #
                        text-align: left;
                        position: initial;
                        margin-top: 0;

                        .price {
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 3em;
                            margin: 0 auto;
                            text-align: center;
                            width: 100%;
                            color: $brand-color-delta;
                            font-size: 2em;
                            margin-top: 0.75em;
                            font-weight: 600;

                            @include media($mobile) {
                                position: static;
                            }
                        }

                        a {
                            color: $brand-color-gamma;
                            font-weight: 600;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }

                    &:nth-of-type(3) { //Quantity & Refresh
                        position: absolute;
                        bottom: 0.5em;
                        left: 12em;
                    }

                    &:nth-of-type(4) { //Trash can
                        position: absolute;
                        bottom: 0.5em;
                        left: 8.5em;
                    }

                    &:nth-of-type(5) { // Add to Cart
                        position: absolute;
                        bottom: 0.5em;
                        left: 5em;
                    }

                    &:nth-of-type(6) { // Add to Cart
                        position: absolute;
                        bottom: 0.5em;
                        left: 0.5em;

                        input {
                            height: auto;
                            width: 50px;
                            margin-bottom: 0;
                        }
                    }

                    i {
                        font-size: 1.5em;
                        vertical-align: text-top;
                        cursor: pointer;
                        color: $grayMedium;
                        padding: 0.25em;
                        transition: all .2s ease-in-out;

                        &:hover {
                            color: $brand-color-gamma;
                            transition: all .2s ease-in-out;
                        }
                    }
                }
            }
        }
    }
}

.account-home {

    .heading {
        margin-bottom: 1em;
    }
    h2 {
        display: inline-block;
    }
    .user-name {
         text-transform: lowercase;
         margin-left: 0.5em;
            &::first-letter {
                text-transform: uppercase;
            }
        }

    .account-home-info, .account-home-history, .account-home-address, .account-home-wishlist {
        display: inline-block;
        width: 49%;
        position: relative;
        padding: 1.5em;
        min-height: 300px;
        max-height: 300px;
        vertical-align: text-top;
        text-align: center;

        @include media($mobile){
            width: 100%;
        }
        ul {
            margin: 0;
            list-style: none;
        }

        

        button {
            width: 300px;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 1em;
            margin: 0 auto;
        }
       }



    .account-home-info {
        border-right: 0.5px solid $grayLight;
        border-bottom: 0.5px solid $grayLight;

        li {
            margin: 0.25em 0;
        }       
    }

    .account-home-history {
        border-left: 0.5px solid $grayLight;
        border-bottom: 0.5px solid $grayLight;

        li {
            
            &:nth-of-type(1){
                margin-bottom: 2em;
            }
            &:nth-of-type(2){
                margin-bottom: 1em;
                font-size: 3em;
                font-weight: 500;
            }
            &:nth-of-type(3){

            }
        }
    }

    .account-home-address {
        border-top: 0.5px solid $grayLight;
        border-right: 0.5px solid $grayLight;

        li {
            
            &:nth-of-type(1){
                margin-bottom: 2em;
            }
            &:nth-of-type(2){
                margin-bottom: 1em;
                font-size: 3em;
                font-weight: 500;
            }
            &:nth-of-type(3){

            }
        }
    }

    .account-home-wishlist {
        border-top: 0.5px solid $grayLight;
        border-left: 1px solid $grayLight;

        li {
            
            &:nth-of-type(1){
                margin-bottom: 2em;
            }
            &:nth-of-type(2){
                margin-bottom: 1em;
                font-size: 3em;
                font-weight: 500;
            }
            &:nth-of-type(3){

            }
        }
    }


}
.my-account-background {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    right: 0;



    background-repeat: no-repeat;
    background-position: bottom;
    background-size:contain;
}

.account-container {
    max-width: 85em;
    margin: 0 auto;
    padding: 2em !important;
    padding-top: 3em !important;
    padding-bottom: 5em !important;
    margin-bottom: 5em;

    @include media($tablet){
        max-width: 45em;
    }

}

.communication {

    h5 {
        border-bottom: 1px solid $grayLight;
        font-weight: 600;
        padding-bottom: 0.5em;
        color: $grayDark;
        text-align: left;
        width: 100%;
        height: 29px;
        margin-bottom: 2em;
    }

    .comm-section {
        padding: 1rem 0;
    }

    .comm-entry {
        display: flex;
        width: 100%;

        input {
            margin-right: 1rem !important;
        }

        input, span {
            align-self: center;
        }
    }

    .reminder-wrapper {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        h6 {
            font-size: 1.125rem;
            width: 100%;
            margin-bottom: 1rem;
            color: $grayDark;
        }

        .reminder-item {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            padding: 1rem;
            margin-bottom: 1rem;
            border-bottom: 1px solid $grayLight;
            font-size: 0.9rem;

            .reminder-image {
                width: 20%;

                img {
                    max-width: 100px;
                    width: 100%;
                }
            }

            .reminder-title {
                width: 70%;
                flex-grow: 2;
                padding-left: 2rem;

                div {
                    margin-bottom: 0.5rem;
                }

                .title {

                    a {
                        font-weight: bold;
                        text-decoration: none;
                    }
                }

                .sku {
                    color: $grayMedium;
                }

                .cancel {

                    span {
                        text-decoration: underline;
                        cursor: pointer;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }

            .reminder-price {
                width: 10%;
                flex-grow: 2;
                text-align: right;
                font-weight: bold;
            }
        }
    }

    hr {
        border-top: 0;
        border: 1px dashed $grayLight;
        margin: 2rem 0;
    }

    .comm-button {
        margin-top: 2rem;
    }
}

.right_content {
  form {
    .controls {
      margin: 10px 0 10px 180px;
      @include media($mobile) {
        @include span-columns(12);
        text-align: left;
        margin-left: 0px;
      }
    }
  }
  .required {
    color: red;
    font-weight: bold;
    font-size: 18px;
  }
  .list {
    @include span-columns(12);
    text-align: center;
    margin-top: 40px;
    ul {
      display: inline-block;
      padding: 0 10% 10% 10%;

      li {
        
        list-style-type: none;
        margin-left: 0;
        display: inline;
        float: left;
        position: relative;
        margin: 0 60px 50px 0;
        text-align: left;

      }
    }
  }
  .level-status {
      margin-bottom: 3em;
  }
  .order-history-total {
      list-style: none;
      float: right;
      margin-bottom: 3em;
      li {
          margin: 0.25em 0;

          &:last-child {
              font-weight: 800;

          }

          span {
              text-align: right;
          }
      }
  }
  .order-history-details {
      ul {
          list-style: none;

          li {

              ul {
                  margin-bottom: 1em;
                  width: 100%;

                  li {
                      display: inline-block;
                      vertical-align: text-top;

                      &:nth-of-type(1){
                          width: 8%;
                      }

                      &:nth-of-type(2){
                          width: 15%;
                      }

                      &:nth-of-type(3){
                          width: 30%;
                      }

                      &:nth-of-type(4){
                          width: 18%;
                      }

                      &:nth-of-type(5){
                          width: 11%;
                      }

                      &:nth-of-type(6){
                          width: 11%;
                      }
                  }
              }


          }
      }
  }
  hr {
      margin: 3em 0 3em 0;
      border-top: 0;
      border-bottom: 1px dashed $grayMedium;
  }
}

/*#wishlist-form {


    tbody {
        border: 1px solid #ddd;
        font-family: 'Oswald';

        td {
            background-color: #eee;
            
            .btn-wishlist {
            margin: 5px;
            &:hover {
                background-color: transparent;
            }

        }
    }
    }
}

#view-wishlist-table {
    
    td {
        padding: 8px;
        border-top: 1px solid #ddd;
    }
}*/

.login {
    margin: 0 auto;

    h1 {
        font-weight: 700;
    }
}

.create-account {
    background-color: $white;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    max-width: 85em;
    margin: 0 auto;
    padding-top: 0 !important;

    #password-blurb {
        strong {
            font-weight: 500;
        }
    }

    .create-account-header {
        background-color: $brand-color-beta;
        color: $white;
        padding: 1em;
        
        margin-bottom: 1em;

        img {
            width: 200px;
        }

        .entry {
            float: none;

            p {
                float: none;
            }
        }

        h1 {
            margin-top: 0;

            font-weight: 700;
        text-transform: uppercase;
        }
    }

    input[type="text"], textarea, input[type="password"] {
        background-color: #FFF !important;
        font-weight: 200;
         border-radius: 5px;
       
        

        &:focus {
            background-color: #FFF;
        }
    }

    ::-webkit-input-placeholder { /* Chrome */
  color: rgba(0,0,0,0.4);
}
:-ms-input-placeholder { /* IE 10+ */
  color: rgba(0,0,0,0.4);
}
::-moz-placeholder { /* Firefox 19+ */
  color: rgba(0,0,0,0.4);
  opacity: 1;
}
:-moz-placeholder { /* Firefox 4 - 18 */
  color: rgba(0,0,0,0.4);
  opacity: 1;
}
    
    .entry {
        float: none;

        p {
            float: none;
        }
    }

    h1 {
        margin-top: 2em;
        font-weight: 300;
    }



    form {
        text-align: center;

        fieldset {
            border: 1px solid #CCC;
            
            padding: 3.5em 1em 1em 1em;
 
            background-color: $white;
            text-align: left;
            position: relative;

            legend {
                border-bottom: 0;
                background-color: $brand-color-alpha;
                width: 100%;
                color: $white;
                position: absolute;
                top: 0;
                left:0;
                right: 0;
                padding: 0.25em;
                
            }

            ul {
                list-style:none;
                margin: 0;

                li {
                    margin: 0.5em;
                }
            }           
        }  

        .account-information {
            ul {
                display: inline-block;
                vertical-align: text-top;
                margin: 0;
                width: 100%;

                input {
                    width: 100%;
                }

                &:nth-of-type(2) {

                }
            }
        }

        .personal-information {
            margin-bottom: 2em;
        }

        .create-account-buttons {
            width: 100%;
            margin: 0 auto;
            display: inline-block;

            input[type="checkbox"] {
                float: none;
            }


        }
    }
}

.account-created {
    padding-top: 3em !important;
    text-align: center;

    > div {
        margin: 0 auto;
        max-width: 50em;
        padding: 5em 2em;
        background-color: #f8f8f8;
        border-radius: 0.5em;

        h1 {
            font-weight: 700;
            margin-bottom: 1em;
        

        }

        h3 {
            font-weight: 300;
            margin-bottom: 1em;
          

        }

        i {
            color: $brand-color-alpha;
            font-size: 10em;
            margin-bottom: 0.2em;
        }
    }
}


//Navigation Highlights

.address-book, .new-address {

    .icon-address-book {
        opacity: 1 !important;
        transition: all .2s ease-in-out !important;
       border-left: 4px solid $brand-color-alpha !important;

        a {
        color: $brand-color-alpha !important;
        }


    }


}

.order-history {

    .icon-order-history {
        opacity: 1 !important;
        transition: all .2s ease-in-out !important;
       border-left: 4px solid $brand-color-alpha !important;

        a {
        color: $brand-color-alpha !important;
        }


    }
    }




.update-account {

    .icon-update-account {
        opacity: 1 !important;
        transition: all .2s ease-in-out !important;
       border-left: 4px solid $brand-color-alpha !important;

  

        a {
        color: $brand-color-alpha !important;
        }


    }
    }


.wishlist {

    .icon-wishlist {
        opacity: 1 !important;
        transition: all .2s ease-in-out !important;
       border-left: 4px solid $brand-color-alpha !important;

        a {
        color: $brand-color-alpha !important;
        }


    }
    }

.communication {

    .icon-communication {
        opacity: 1 !important;
        transition: all .2s ease-in-out !important;
       border-left: 4px solid $brand-color-alpha !important;

        a {
        color: $brand-color-alpha !important;
        }


    }
    }

.faqs {

    .icon-faqs {
        opacity: 1 !important;
        transition: all .2s ease-in-out !important;
       border-left: 4px solid $brand-color-alpha !important;

        a {
        color: $brand-color-alpha !important;
        }


    }

    .my-account-right {
        width: 100%;
    }
    }




