.monolith-partial {
    width: 100%;
}

.feedback-survey {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    position: relative;
    transition: all .2s ease-in-out;
    margin-bottom: 4rem;

    .dismiss-feedback {
        padding: 1rem;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        font-size: 1.25rem;
        transition: all .2s ease-in-out;

        &:hover {
            transform: scale(1.2);
            transition: all .2s ease-in-out;
        }
    }

    form {

        h1 {
            text-align: center;
        }

        .icon-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            div {
                padding: 2rem;
                cursor: pointer;
                transition: all .2s ease-in-out;

                &:hover {

                    img {
                        transform: scale(1.2);
                        transition: all .2s ease-in-out;
                    }

                    h5 {
                        font-weight: 800;
                        transition: all .2s ease-in-out;
                    }
                }

                span {
                    display: block;
                    max-width: 125px;
                    width: 100%;
                    margin: 0 auto;
                    -webkit-transform-origin: 50% 50%;
                    -moz-transform-origin: 50% 50%;
                    -o-transform-origin: 50% 50%;
                    transform-origin: 50% 50%;
                }

                img {
                    max-width: 125px;
                    width: 100%;
                    transition: all .2s ease-in-out;
                    -webkit-transform-origin: 50% 50%;
                    -moz-transform-origin: 50% 50%;
                    -o-transform-origin: 50% 50%;
                    transform-origin: 50% 50%;
                }

                h5 {
                    margin-top: 2rem;
                    font-weight: 600;
                    color: $black;
                    font-size: 1.25rem;
                    text-align: center;
                    transition: all .2s ease-in-out;
                }
            }
        }

        .additional-feedback {
            text-align: center;
            display: none;
            transition: all .2s ease-in-out;

            h1 {
                font-size: 2rem;
            }

            textarea {
                width: 100%;
                height: 150px;
                max-width: 600px;
                margin: 0 auto;
                margin-bottom: 2rem;
            }

            label[for=contact-feedback]{
                display: flex;
                justify-content: center;
                margin-bottom: 1rem;
                
                input {
                    margin-right: 0.5rem;
                    cursor: pointer;
                }

                span {
                    align-self: center;
                }

            }

            button {
                margin: 0 auto;
            }
        }
    }

    .success, .failure {
        display: none;
        text-align: center;

        button {
            margin: 0 auto;
        }
    }
}

.option-selected {
    span {
        display: block;
        animation: surveyspin .5s;
    }

    img {
        animation: grow .5s;
        animation-fill-mode: forwards;
        transition: all .2s ease-in-out;
    }

    h5 {
        font-weight: 800 !important;
        transition: all .2s ease-in-out;
    }
}

.show-additional-feedback {
    display: block !important;
    animation: showfeedback .5s;
    animation-fill-mode: forwards;
    transition: all .2s ease-in-out;
}

.option-remove {
    animation: fadeaway .5s;
    animation-fill-mode: forwards;

    span {
        animation: spinaway .5s;
        animation-fill-mode: forwards;
    }
}

@keyframes surveyspin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes grow {
    0% {
        transform: scale(1.2);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.2);
    }
}

@keyframes showfeedback {
    0% {
        transform: scale(0);
        opacity: 0 !important;
    }


    100% {
        transform: scale(1);
        opacity: 1 !important;
    }
}

@keyframes spinaway {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes fadeaway {
    0% {
        transform: scale(1);
        opacity: 1 !important;
        margin-left: 0;
    }

    100% {
        transform: scale(0);
        opacity: 0 !important;
        margin-left: -100%;
    }
}


