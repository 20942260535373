.filter-buttons {
  margin: 0 auto;
  .size {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    padding: 4px 12px 12px;
    width: 50px;
    height: 32px;
    pointer: cursor;
    padding: 0;
    margin: 8px 0 8px 15px;
    line-height: 17px;
  }
}

.hide-keywords {
    display: none;
}
