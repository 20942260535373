//
// Alerts
// --------------------------------------------------


// Base styles
// -------------------------

.alert {
  padding: 8px 14px 8px 14px;
  margin-top: 1em;
  margin-bottom: 1em;
  text-shadow: 0 1px 0 rgba(255,255,255,.5);
  background-color: $warningBackground;
  border: 1px solid $warningBorder;
  @include border-radius($baseBorderRadius);
}
.alert,
.alert h4 {
  // Specified for the h4 to prevent conflicts of changing $headingsColor
  color: $warningText;

  padding: 1em;

}
.alert h4 {
  margin: 0;
}

// Adjust close link position
.alert .close {
  position: relative;
  top: -2px;
  right: -21px;
  line-height: $baseLineHeight;
}


// Alternate styles
// -------------------------

.alert-success {
  background-color: $successBackground;
  border-color: $successBorder;
  color: $successText;
}
.alert-success h4 {
  color: $successText;
}
.alert-danger,
.alert-error {
  background-color: $errorBackground;
  border-color: $errorBorder;
  color: $errorText;
}
.alert-danger h4,
.alert-error h4 {
  color: $errorText;
}
.alert-info {
  background-color: $infoBackground;
  border-color: $infoBorder;
  color: $infoText;
}
.alert-info h4 {
  color: $infoText;
}
.alert-backorder {
  display: block;
  font-weight: bold;
}

// Block alerts
// -------------------------

.alert-block {
  padding-top: 14px;
  padding-bottom: 14px;
}
.alert-block > p,
.alert-block > ul {
  margin-bottom: 0;
}
.alert-block p + p {
  margin-top: 5px;
}

input.parsley-success,
select.parsley-success,
textarea.parsley-success {
  color: #468847;
  background-color: #DFF0D8;
  border: 1px solid #D6E9C6;
}

input.parsley-error,
select.parsley-error,
textarea.parsley-error {
  color: #B94A48;
  background-color: #F2DEDE;
  border: 1px solid #EED3D7;
}

.parsley-errors-list {
  margin: 2px 0 3px !important;
  padding: 0;
  list-style-type: none;
  font-size: 0.9em;
  line-height: 0.9em;
  opacity: 0;

  transition: all .3s ease-in;
  -o-transition: all .3s ease-in;
  -moz-transition: all .3s ease-in;
  -webkit-transition: all .3s ease-in;
}

.parsley-errors-list.filled {
  opacity: 1;
}

.promo-success {
  float: left;
}