﻿.max-width-container {
    max-width: 1200px;
    margin: 0 auto;

    h1 {
        font-size: 1.1em;
        font-weight: 200;
        letter-spacing: 5px;
        text-transform: uppercase;
        margin-bottom: 1em;
        color: $grayMedium;
        width: 100%;
        text-align: center;
    }

    .bolder {
        font-weight: 500
    }    
}



/*.hero {
    padding: 3em;
    text-align: center;
    background-color: #f8f8f8;

    img {
        border: 10px solid #FFF;
        box-shadow: 6px 6px 12px 0px rgba(0, 0, 0, 0.06);
    }

    h1 {
        font-size: 1.1em;
        font-weight: 200;
        letter-spacing: 5px;
        text-transform: uppercase;
        margin-bottom: 1em;
        color: $grayMedium;
        width: 100%;
        text-align: center;
    }

    .bolder {
        font-weight: 500
    }

}*/

.scale-models {
    width: 100%;
    padding: 1em;

    ul {
        margin: 1em 0 0 0;
        list-style: none;
        width: 100%;
        text-align: center;

        li {
            display: inline-block;
            margin: 0 2px;
            line-height: 45px;

            a {
                color: $grayMedium;
                padding: 6px 12px;
                border: 1px solid rgba(128,128,128,0.0);
                transition: all 1.25s ease;
                line-height: 30px;
                font-size: 1em;
                font-weight: 200;
      

                &:hover {
                    border: 1px solid rgba(128,128,128,1);
                    transition: all 1.25s ease;
                }
            }
        }
    }
}

.new-additions {
    width: 100%;
    background-color: $grayLight;
    text-align: center;
    padding: 1em 1em 2em 1em;
    box-shadow: inset 6px 6px 50px 0px rgba(0,0,0,0.06);

    ul {
        margin: 0;
        list-style: none;
        width: 100%;

        li {
            width: 24%;
            display: inline-block;
            padding: 0 0.75em;

            @include media($mobile){
                width: 49%;
                margin-bottom: 1em;
            }
            

            img {
                border: 6px solid #FFF;
                width: 100%;
                box-shadow: 6px 6px 12px 0px rgba(0,0,0,0.06);
            }
        }
    }

}

.about-jcb {
    background-color: $brand-color-alpha;
    text-align: center;
    padding: 1em 1em 2em 1em;

    .left {
        width: 65%;
        display: inline-block;
        vertical-align: text-top;
        padding: 0 0.75em;

        @include media($tablet){
            width: 100%;

            
        }

        @include media($mobile){
            width: 100%;

            iframe {
                height: initial !important;
            }
        }
    }

    .right {
        width: 33%;
        display: inline-block;
        vertical-align: text-top;

        @include media($tablet){
            width: 100%;
        }

        @include media($mobile){
            width: 100%;
        }

        p {
            font-size: 0.9em;
            color: $grayMedium;
            font-weight: 200;
            text-align: left;
            line-height: 1.9;
            padding: 0 0.75em;
        }
    }
}

.product-videos {
    padding: 1em 1em 2em 1em;
    background-color: $grayLight;
    ul {
        margin: 0;
        list-style: none;
        text-align: center;

        li {
            display: inline-block;
            padding: 0 0.5em;
            width: 49.5%;

            @include media($mobile){
                width: 100%;
            }
        }
    }
}

.photo-slider {

    #photoSlider {
        position: relative;
        text-align: center;

        .item {
            img {
                max-width: initial;
                width: 100%;
            }
        }

      .carousel-indicators {
        position: absolute;
        bottom: 1em;
        top: initial;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;

        li {
            display: inline-block;
            float: none;
            border-radius: 0;
            background-color: #000000;
            width: 15px;
            height: 15px;
        }

        .active {
            background-color: $brand-color-alpha;
        }
    }
    }

}