.special-order {
    
    p {
        text-align:center;
        margin-bottom: 2em;
    }

    .cognito .c-forms-form {
        margin: 0 auto;
    }
    

}

.photo-collage {
    width: 100%;
    border-top: 7px solid #FFF;
    border-bottom: 7px solid #FFF;
}